<template>
    <div class="row p-3">
        <div class="col-4 col-md-2 text-center f80p pl-0">{{ cita.fecha }}</div>
        <div class="col-8 col-md-10 text-left borderLeft">
                <div class="col-12 text-left f14x"><b v-if="cita.tipo !== ''">Consulta de {{ cita.tipo }}</b></div>
                <div class="col-12 text-left f14x">{{ cita.doctor }}</div>
                <div class="col-12 text-right">
                <!-- <div   class="pointer"> -->
                    <button class="btn1" @click="cambiarCita()">Detalles</button>
                <!-- </div> -->

                </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import api from '@/services/api'

export default {
    props: {
        cita: Object
    },

    // mounted () {
    //     console.log(this.cita)
    // },

    methods: {
        ...mapActions({
          setCita: 'setCita'
        }),

        tipoCita (tipo) {
            let retorno = ''
            if (tipo == 'V') {
                retorno = 'videoconferencia'
            } else if (tipo == 'T') {
                retorno = 'telefónica'
            } else if (tipo == 'P') {
                retorno = 'presencial'
            }
            return retorno
        },

        cambiarCita () {
            this.$emit('sacoPop', this.cita) 
            // this.$emit('sacoPop', this.cita)
            // const modal = document.getElementById('cierraModal');
            // modal.click()
            // this.setCita(this.cita.cita)
            // this.$router.push('/appoiments')
        },

        async anulaCita () {
            alert('aaa');
            await api.getAnulaCita2(this.cita.cita.id)
            this.$emit('refresh') 
        }
    }
}
</script>
<style scoped>
.borderLeft{
    border-left:1px solid black;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 0px 0px;
    /* width: 20%; */
    min-width: 100px;
}

.btn2 {
    background: #BB0000;
    border: none;
    color: white;
    padding: 0px 0px;
    width: 100px;
    margin-right: 10px;
    /* float: left; */
}

.btn3 {
    background: rgb(0, 187, 45);
    border: none;
    color: white;
    padding: 0px 0px;
    width: 115px;
    margin-left: 10px;
    /* float: right; */
}

.f80p{
    font-size:80%;
}

@media (max-width: 767px) {
    .f14x{
        font-size: 14px;
    }
}

.W100{
    width: 100%;
}

.linea{
    border-bottom: 3px solid #000;
    margin-bottom: 10px;
    margin-top: 5px;
}

.mxW100x{
    max-width: 100px;
}

.mAuto{
    margin: 0 auto;
}

.W80{
    width: 80%;
}

.bgAdv {
    background: #9dc0ff;
}
</style>