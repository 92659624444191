<template>
    <div class="p-3 align-items-center" :class="asistido ? 'borderCaja' : 'borderCaja2 mt-2'">
        <!--<div class="col-12 text-left"> -->
        <div class="col-4 col-md-2 text-center f80p pl-0">{{ cita.fecha }}</div>
        <div class="col-8 col-md-10 text-left borderLeft">
            <div class="row">
                <div class="col-12 text-left f14x">{{ cita.doctor }}</div>
                <div class="col-6 text-left f14x"><b>{{ cita.tipo }}</b></div>
                <div class="col-6 text-right"><button @click="accion(asistido)" :class="asistido ? 'btn1' : 'btn2'">{{ asistido ? 'Pedir cita' : 'Reagendar' }}</button></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        cita: Object,
        asistido: Boolean
    },

    mounted () {
    },

    methods: {
        ...mapActions({
          setCita: 'setCita',
        }),
        accion (asis) {
            // console.log(asis)
            if (!asis) {
                this.setCita(this.cita.itemListado)
                this.$router.push('/appoiments')
            }
        }
    }
}
</script>
<style scoped>
.borderCaja{
    border:2px solid black;
}

.borderCaja2{
    background: #ff00003b;
    display: flex;
    flex-wrap: wrap;
}

.borderLeft{
    border-left:1px solid black;
}

.btn1{
    background: #00BB2D;
    border: none;
    color: white;
    padding: 0px 0px;
    width: 100px;
}

.btn2{
    background: #BB0000;
    border: none;
    color: white;
    padding: 0px 0px;
    width: 100px;
}
</style>