// const axios = require('axios')
// import * as axios from 'axios';
import axios from 'axios'
var token = localStorage.getItem('q') ? localStorage.getItem('q') : null
var api_url = 'https://api.virtuscomplutum.es/'
//var api_url = 'http://apivirtus.osared.local/'
var api = {
    // ZONA DE GET
    
    // async verificaHistorial () {
    //     // localStorage.getItem('q')
    //     const params = {
    //         method: 'is_session_active',
    //         q: localStorage.getItem('q')
    //     }
    //     var data = JSON.stringify(params)
    //     // let respuesta = ''
    //     var config = {
    //         method: 'is_session_active',
    //         url: 'https://api.grupovirtus.es/login.php',
    // 'https://api.grupovirtus.es/requests.php
    //         data : data
    //       };
    //       const respuesta = await axios(config)
    //       return respuesta.data
    // },
    async verificaHistorial (params) {
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            // https://api.grupovirtus.es/requests.php
            url:api_url+'login.php',
            data : data
          };
          const respuesta = await axios(config)
          if (respuesta.data.q) {
            token = respuesta.data.q
            localStorage.setItem('q', respuesta.data.q)
          }
          return respuesta.data
    },

    async login (params) {
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            // url:api_url+'login.php',
            url:api_url+'login.php',
            data : data
          };
          const respuesta = await axios(config)
          if (respuesta.data.q) {
            token = respuesta.data.q
            localStorage.setItem('q', respuesta.data.q)
          }
          return respuesta.data
    },

    async cambioContra (params) {
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            // url:api_url+'login.php',
            url:api_url+'login.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async cambioMail (params) {
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            // url:api_url+'login.php',
            url:api_url+'login.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async (params) {
        var data = JSON.stringify(params)
        var config = {
            method: 'post',
            url:api_url+'login.php',
            data : data
          };
          axios(config)
          .then(function (response) {
            return JSON.stringify(response.data)
          })
          .catch(function (error) {
            return error
          });
    },
    
    
    // HISTORIAS PACIENTES 
    // Haremos un get a la Historias (Pacientes) para tener acceso a la ficha del paciente
    async getHistorialPacientes2 (nif) {
        const params = {
            method: 'his_dni',
            cen_tra: 1,
            nif: nif,
            q: token
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getHistorialPacientes (nif) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/his?filter%5Bnif%5D=' + nif + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },
    // Este era lo que no encontraba..
    
    // ESPECIALIDADES CENTRO DE TRABAJO esp_ser
    // Esta query nos devolverá la lista de especialidades activas y visibles web de un Centro de trabajo.

    async getEspecialidades2 () {
        const params = {
            method: 'esp_ser',
            cen_tra: 1,
            q: token
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            // url:api_url+'requests.php',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getEspecialidades (idCentro = 1) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_query/esp_ser?param%5Bcen_tra%5D='+ idCentro + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l', {})
        return data
    },

    // ESPECIALIDADES CONCEPTO cit_con_web
    // Este proceso nos devolverá los conceptos de cita de una especialidad y si no se la paso, los conceptos que estén visibles web.
    async getEspecialidadConcepto2 (concepto = null) {
        const params = {
            method: 'cit_con_web',
            'ser_prd': concepto,
            q: token
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getEspecialidadConcepto (concepto = null) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/cit_con_web?api_key=87L08lI6665_K%2075687e800M234a4_234L3l&param%5Bser_prd%5D=' + concepto)
        return data
    },
    
    // ASEGURADORAS POR CENTRO DE TRABAJO ase_cen_tra
    // Este proceso nos devolverá la lista de Aseguradoras disponibles en el Centro de Trabajo.
    async getAseguradora2 (idCentro = 1) {
        const params = {
            method: 'ase_cen_tra',
            cen_tra: idCentro,
            q: token
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },
    
    
    async getAseguradora (idCentro = 1) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/ase_cen_tra?param%5Bcen_tra%5D=' + idCentro + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    // Lista profesionales Centro, especialidad aseguradora (prf_ase_ser_prd)
    // Este proceso devolverá la lista de profesionales disponibles para un Centro,especialidad y/o Aseguradora.
    async getListaProfesionales2 (ase = null, idCentro = 1, especialidad = null) {
        const params = {
            method: 'prf_age_ser_prd',
            cen_tra: idCentro,
            ase: ase,
            q: token,
            'ser_prd': especialidad

        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },
    
    async getListaProfesionales (ase = null, idCentro = 1, especialidad = null) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/prf_age_ser_prd?param%5Base%5D=' + ase + '&param%5Bcen_tra%5D=' + idCentro + '&param%5Bser_prd%5D=' + especialidad + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },
    // async getListaProfesionales (base = 3, idCentro = 1) {
    //     const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/prf_age_ser_prd?param%5Base%5D=' + base + '&param%5Bcen_tra%5D=' + idCentro + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
    //     return data
    // },

    // Fecha hueco libre con rango fch_con_hue_lib
    // Este proceso nos va a devolver los días con algún hueco libre por profesional en un periodo de tiempo
    async getCitaRango2 (fechaDesde, fechaHasta, profesional) {
        const params = {
            method: 'fch_con_hue_lib',
            cen_tra: 1,
            fch_dsd: fechaDesde,
            fch_has: fechaHasta,
            q: token,
            prf: profesional

        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getCitaRango (fechaDesde, fechaHasta, profesional) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/fch_con_hue_lib?param%5Bcen_tra%5D=1&param%5Bfch_dsd%5D=' + fechaDesde + '&param%5Bfch_has%5D=' + fechaHasta + '&param%5Bprf%5D=' + profesional + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async getCitaHora2 (fecha, profesional, centro = 1) {
        const params = {
            method: 'cit_web_tmp_alt_ver2',
            cen_tra: centro,
            prf:profesional,
            q: token,
            fch:fecha
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getCitaHora (fecha, profesional, centro = 1) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/cit_web_tmp_alt_ver2?param%5Bcen_tra%5D=' + centro + '&param%5Bfch%5D=' + fecha + '&param%5Bprf%5D=' + profesional + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    // citas
    // Devolvera un array con las citas 
    async getcitasFuturas2 () {
        const dateDesde = new Date()
        const dia = dateDesde.getDate() < 10 ? '0' + dateDesde.getDate() : dateDesde.getDate()
        const fechaDesde = dia + '%2F' + (dateDesde.getMonth() + 1 < 10 ? '0' + (dateDesde.getMonth() + 1) : dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const fechaHasta = dia + '%2F' + (dateDesde.getMonth() + 1 < 10 ? '0' + (dateDesde.getMonth() + 1) : dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() + 2)
        const params = {
            method: "lista_citas",
            // num_his:117634,
            cen_tra:1,
            q: token,
            //est:"C",
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        var config = {
            method: 'post',
            // url:api_url+'requests.php',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config) 
          return respuesta.data
        // try {
        //     const dateDesde = new Date();
        //     const fechaDesde =
        //       dateDesde.getDate() +
        //       "%2F" +
        //       (dateDesde.getMonth() + 1) +
        //       "%2F" +
        //       (dateDesde.getFullYear() - 2);
        //     const fechaHasta =
        //       dateDesde.getDate() +
        //       "%2F" +
        //       (dateDesde.getMonth() + 1) +
        //       "%2F" +
        //       dateDesde.getFullYear();
        //     const params = {
        //       method: "lista_citas",
        //       cen_tra: 1,
        //       fch_dsd: fechaDesde,
        //       fch_has: fechaHasta,
        //     };
        //     const data = JSON.stringify(params);
        //     const config = {
        //       method: "post",
        //       url: "https://api.grupovirtus.es/requests.php",
        //       data: data,
        //     };
        //     const respuesta = await axios(config);
        //     console.log("Llegooooooo", respuesta.data);
        //     return respuesta.data;
        //   } catch (error) {
        //     // Aquí puedes manejar el error de la API
        //     console.error("Error al llamar a la API:", error);
        //     // Puedes lanzar una excepción o devolver un valor específico para indicar el error
        //     throw error;
        //   }
    },

    async getcitasFuturas (his) {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() + 2)
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/cit_web?param%5Bcen_tra%5D=' + 1 +'&param%5Bfch_dsd%5D=' + fechaDesde + '&param%5Bfch_has%5D=' + fechaHasta  + '&param%5Bhis%5D=' + his + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async getcitasPasadas2 () {
        const dateDesde = new Date()
        const dia = dateDesde.getDate() < 10 ? '0' + (dateDesde.getDate() - 1) : (dateDesde.getDate() - 1)
        const fechaDesde = dia + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dia + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const params = {
            method: "lista_citas",
            // num_his:117634,
            cen_tra:1,
            q: token,
            //est:"C",
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        var config = {
            method: 'post',
            // url:api_url+'requests.php',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getcitasAnuladas2 () {
        const dateDesde = new Date()
        const dia = dateDesde.getDate() < 10 ? '0' + (dateDesde.getDate() - 1) : (dateDesde.getDate() - 1)
        const fechaDesde = dia + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() + 2)
        const params = {
            method: "lista_citas",
            // num_his:117634,
            cen_tra:1,
            q: token,
            //est:"C",
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        var config = {
            method: 'post',
            // url:api_url+'requests.php',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getcitasPasadas (his) {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/cit_web?param%5Bcen_tra%5D=' + 1 +'&param%5Bfch_dsd%5D=' + fechaDesde + '&param%5Bfch_has%5D=' + fechaHasta  + '&param%5Bhis%5D=' + his + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async getIntroducecitas2 (fch, hora, prf, ase, cit_tip, cit_con, cit_prg) {
        console.log(cit_prg)
        const params = {
            method: "alta_cita",
            cen_tra:1,
            q: token,
            prf:prf,
            cit_prg_tmp: cit_prg,
            cit_con: cit_con,
            fch: fch,
            hor: hora,
            ase: ase,
            // "col":3,//opcional
            // "his":545,//opcional
            // "pac_ape":"apellido",
            // "pac_tlf":"2343243",
            // "pac_eml":"em@ail.com",
            pais:66,
            cit_tip: cit_tip
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getIntroducecitas (fch, hora, prf, ase, cit_tip, cit_con, his) {
        const { data } = await axios.post('https://ns1.portalns.es:9001/goya/NSH_dat_dat/CIT_PRG_TMP_CMP.PRO?CEN_TRA=1&FCH=' + fch + '&TIP=3&HOR=' + hora + '&PRF=' + prf + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l&ASE=' + ase + '&CIT_TIP=' + cit_tip + '&CIT_CON=' + cit_con + '&HIS=' + his, {
            mode: 'no-cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
            })
        })
        return data
    },

    async getAnulaCita2 (id) {
        const params = {
            method: "baja_cita",
            q: token,
            // cit_prg_tmp: id,
            cit: id
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getAnulaCita (id) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/CIT_PRG_TMP_CMP.PRO?CIT=' + id + '&TIP=4')
        return data
    },

    async getInformes2 () {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const params = {
            method: "doc_pac",
            // num_his:117634,
            tip_doc:"0",
            q: token,
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getInformesPorTipo (tipo) {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const params = {
            method: "doc_pac",
            // num_his:117634,
            tip_doc:tipo,
            q: token,
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getPruebasRadiologia (tipo) {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const params = {
            method: "query_epi_pru_com",
            // num_his:117634,
            est:tipo,
            q: token,
            fch_dsd: fechaDesde,
            fch_has: fechaHasta
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },


    async getInformes (his) {
        const dateDesde = new Date()
        const fechaDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + (dateDesde.getFullYear() - 2)
        const fechaHasta = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_query/epi_doc_web?param%5Bfch_dsd%5D=' + fechaDesde + '&param%5Bfch_has%5D=' + fechaHasta + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l&param%5Bnum_his%5D=' + his)
        // const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/cit_web?param%5Bcen_tra%5D=' + 1 +'&param%5Bfch_dsd%5D=' + fechaDesde + '&param%5Bfch_has%5D=' + fechaHasta  + '&param%5Bhis%5D=' + his + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async getCentrosTrabajo (idCentro = 1) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_query/esp_ser?param%5Bcen_tra%5D='+ idCentro + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l', {})
        return data
    },

    async getProvincias2 () {
        const params = {
            method: 'pro',
            q: token
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async getProvincias () {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_query/pro?param%5Bpai%5D=66&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async getPoblaciones2 (id) {
        const params = {
            method: 'pob',
            q: token,
            cp: id
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },
    
    async getPoblaciones (id) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_query/pob?param%5Bcp%5D=' + id +'&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async generarHist2 (form) {
        // param%5Bape%5D=' + form.lastName +'&param%5Base%5D=' + form.sociedades[0].id + '&param%5Bcen_tra%5D=1&param%5Bdir%5D=' + form.address + '&param%5Beml%5D=' + form.email + '&param%5Bfch_nac%5D=' + form.birthday + '&param%5Bname%5D=' + form.name + '&param%5Bnif%5D=' + form.dni + '&param%5Bpai%5D=66&param%5Bpob%5D=10151&param%5Btlf1%5D=' + form.mobile + '&param%5Bpob%5D=' + form.town.id
        const params = {
            method: 'alt_his_app',
            ase:form.sociedades[0].id,
            dir: form.address,
            pai:"66",
            pob: form.city.id,
            q: token,
            pro:18,
            name: form.name,
            ape: form.lastName,
            rel_per:1,
            tlf2:"999999"
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async generarHist (form) {
        const { data } = await axios.get(' https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/alt_his_app?param%5Bape%5D=' + form.lastName +'&param%5Base%5D=' + form.sociedades[0].id + '&param%5Bcen_tra%5D=1&param%5Bdir%5D=' + form.address + '&param%5Beml%5D=' + form.email + '&param%5Bfch_nac%5D=' + form.birthday + '&param%5Bname%5D=' + form.name + '&param%5Bnif%5D=' + form.dni + '&param%5Bpai%5D=66&param%5Bpob%5D=10151&param%5Btlf1%5D=' + form.mobile + '&param%5Bpob%5D=' + form.town.id + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')
        return data
    },

    async envioDatosAnalsisis2 (dni, pid) {
        const params = {
            PID: dni,
            PASS: pid
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'jsondata.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async descargaAnalsisis2 (id) {
        const params = {
            XID: id
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'jsondata.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async envioDatosAnalsisis (dni, pid) {
        const { data } = await axios.get('http://217.126.227.85/JSONData.exe?PID=' + dni + '&PASS=' + pid)
        return data
    },

    async descargaAnalsisis (id) {
        const { data } = await axios.get('http://217.126.227.85/JSONData.exe?XID=' + id)
        return data
    },

    async modificarDatosSoft2 (form) {
        // '&dir=' +form.address + '&eml=' +form.email + '&fch_nac=' +form.birthday + '&his_pri=' +form.his + '&pob=' +form.town.id + '&pro=' + form.town['pro.id'] + '&tlf1=' +form.phone + '&tlf2=' +form.mobile
        const params = {
            method: 'mod_his_app',
            ase:form.ase_princi,
            dir: form.address,
            q: token,
            pai:"66",
            pob:form.town.id,
            pro: form.town['pro.id'],
            rel_per:1,
            eml: form.email,
            tlf1: form.phone,
            tlf2: form.mobile
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async modificarDatosSoft (form) {
        const { data } = await axios.get(' https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/mod_his_app?api_key=87L08lI6665_K%2075687e800M234a4_234L3l' + '&dir=' +form.address + '&eml=' +form.email + '&fch_nac=' +form.birthday + '&his_pri=' +form.his + '&pob=' +form.town.id + '&pro=' + form.town['pro.id'] + '&tlf1=' +form.phone + '&tlf2=' +form.mobile )
        return data
    },

    async borraAseguradora2 (ase) {
        const params = {
            method: 'aso_his_ase',
            ase: ase,
            eli: 1,
            q: token,
            pri: 0
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async borraAseguradora (his, ase) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/aso_his_ase?param%5Base%5D=' + ase + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l&param%5Bhis%5D=' + his + '&param%5Beli%5D=true')
        
        return data
    },

    async agregaAseguradora2 (ase) {
        const params = {
            method: 'aso_his_ase',
            ase: ase,
            eli: 0,
            q: token,
            pri: 0
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async agregaAseguradora (his, ase) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/aso_his_ase?param%5Base%5D=' + ase + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l&param%5Bhis%5D=' + his + '&param%5Beli%5D=false')
        
        return data
    },

    async aseguradorasCliente2 (nif) {
        const params = {
            q: token,
            nif: nif,
            method: 'dev_ase_his'
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async envioMail (email, body, phone, attachment, subject) {
        const params = {
            email: email,
            body: body,
            phone: phone,
            subject: subject,
            attachment: attachment,
            method: 'send_email'
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'login.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async aseguradorasCliente (nif) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/v1/_process/dev_ase_his?param%5Bnif%5D=' + nif + '&api_key=87L08lI6665_K%2075687e800M234a4_234L3l')

        return data
    },

    async descargarInforme2 (id) {
        const params = {
            method: 'dsc_doc_web',
            q: token,
            epi_doc: id
        }
        var data = JSON.stringify(params)
        // let respuesta = ''
        var config = {
            method: 'post',
            url:api_url+'requests.php',
            data : data
          };
          const respuesta = await axios(config)
          return respuesta.data
    },

    async descargarInforme (id) {
        const { data } = await axios.get('https://ns1.portalns.es:9001/goya/NSH_dat_dat/dsc_doc_web.pro?api_key=87L08lI6665_K%2075687e800M234a4_234L3l&epi_doc=' + id)

        return data
    },

    borraToken () {
      token = null
      localStorage.removeItem('q')  
    }
}
export default api
