<template>
    <div class="container W90 ptPc-5 pbPc-5">
      <div class="col-12 mt-4 mb-5">
        <secciones selectOpcion="Analisis" />
      </div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
      <div v-if="nombre === ''" class="row">
          <div class="col-12 text-center">
            <span class="d-block" for="">DNI</span>
            <input v-model="DNI" type="text">
          </div>
          <div class="col-12 mt-2 text-center">
            <span class="d-block" for="">Código WEB</span>
            <input v-model="PID" type="password">
          </div>
          <div class="col-12 mt-3 text-center">
            <button class="btn1" style="width: 210px;" @click="enviar()">Confirmar</button>
        </div>
      </div>
      <div v-if="nombre !== ''" class="row" style="border-bottom: 4px solid black;">
        <div class="col-12 mt-4 mb-5 text-center">
          <h4>{{ nombre }}</h4>  
        </div>
        <div class="col-4 mt-4 mb-5 text-center">
          <span>Estado</span>
        </div>
        <div class="col-4 mt-4 mb-5 text-center">
          <span>Fecha</span>
        </div>
        <div class="col-4 mt-4 mb-5 text-center" />
      </div>
      <div v-if="nombre !== ''">
        <div v-for="(fila, index) in listado" :key="index" class="row">
          <div class="col-4 mt-4 mb-5 text-center">
            <span>{{ fila.estado }}</span>
          </div>
          <div class="col-4 mt-4 mb-5 text-center">
            <span>{{ devuelveFecha(fila.fecha) }}</span>
          </div>
          <div class="col-4 mt-4 mb-5 text-center">
            <div class="btnListado pointer" data-target="#exampleModal" data-v-113fb7a7="" @click="descarga(fila.xid)">Descargar</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import api from '@/services/api'
import Secciones from '@/components/general/Secciones.vue'
  
  export default {
    components: {
        Secciones
    },
  
    data: () => ({
      filterInforme: '',
      DNI: '',
      listado: '',
      nombre: '',
      error: '',
      PID: ''
    }),
  
    computed: {
      ...mapGetters({
        user: 'user'
      })
    },
  
    watch: {
    },
  
    async mounted () {
    },
  
    methods: {
      ...mapActions({
        setMsgError: 'setMsgError',
        setAuth: 'setAuth',
        setModalError: 'setModalError',
      }),

      async enviar () {
        const res = await api.envioDatosAnalsisis2(this.DNI, this.PID)
        if (res.status) {
            if (res.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
        }
        if (res.error) {
          this.setMsgError(res.error)
          this.setModalError(true)
          return
        }
        this.nombre = res.nombre
        this.listado = res.analisis
      },

      async descarga(id) {
        const res = await api.descargaAnalsisis2(id)
        if (res.status) {
            if (res.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
            }
        }
        // console.log(res)
        this.downloadPDF(res.pdf)
      },

      downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`
        const downloadLink = document.createElement("a")
        const fileName = "informe.pdf"
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click();
      },

      devuelveFecha (fecha) {
        // const retorno = fecha[6] + fecha[7] + '/' + fecha[4] + fecha[5] + '/' + fecha[0] + fecha[1] + fecha[2] + fecha[3]
        return fecha
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btnListado{
  width: 90%;
  max-width: 150px;
  margin: 0 auto;
  padding:2px 10px;
  background: #2a4a5e;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 31px; */
  height: 54px;
}

.pointer {
  cursor: pointer;
}
</style>
  
