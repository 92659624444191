<template>
    <div class="mainControl">
      <HistorialAnalisis />
    </div>
  </template>
  
  <script>
  import HistorialAnalisis from '../components/usuario/HistorialAnalisis.vue'
  
  export default {
    components: {
      HistorialAnalisis
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .mainControl {
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    align-items: center;
  }
  </style>
  