<template>
  <div class="mainControl">
    <Registro />
  </div>
</template>

<script>
import Registro from '../components/sesion/Registro.vue'

export default {
  components: {
    Registro
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}
</style>
