<template>
  <div class="mainControl">
    <Inicio />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Inicio from './components/Inicio.vue'
import Inicio from '../components/sesion/Inicio.vue'

export default {
  components: {
    Inicio
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}
</style>
