<template>
  <div>
    <div class="row">
        <div v-if="!asociaPerfil" class="col-12">
            <div class="col-12 noPc mb-4">
                <div @click="$router.go(-1)" class="bgBtnHead mxW150x text-left pr-3 pointer">
                    <img class="btnHead mxW40x" alt="Email" src="../../assets/atras.png">
                    <span class="f17x">ATRÁS</span>
              </div>
            </div>
            <div class="col-12">
                <div class="tituloLogin f16x"><b>Datos personales</b></div>
            </div>
            <div class="col-12 mt-2">
                <input v-if="proviene === 'registro'" v-model="form.name" type="text" name="" id="" placeholder="Nombre" class="inputsLogin" >
                <input v-else v-model="form.name" type="text" name="" id="" placeholder="Nombre" class="inputsLogin" readonly>
            </div>
            <div class="col-12 mt-2">
                <input v-if="proviene === 'registro'" v-model="form.lastName" type="text" name="" id="" placeholder="Apellidos" class="inputsLogin">
                <input v-else v-model="form.lastName" type="text" name="" id="" placeholder="Apellidos" class="inputsLogin" readonly>
            </div>
            <div class="col-12 mt-2">
                <input v-if="proviene === 'registro'" v-model="cumple" type="date" name="" id="" placeholder="Fecha de nacimiento" class="inputsLogin">
                <input v-else v-model="cumple" type="date" name="" id="" placeholder="Fecha de nacimiento" class="inputsLogin" readonly>
            </div>
            <div class="col-12">
                <div class="row mxW80 mAuto">
                    <div class="col-4 mt-2 pl-0 pr-0">
                        <select v-if="proviene === 'registro'" name="" id="" class="W100 H100">
                            <option value="dni">DNI</option>
                            <option value="nie">NIE</option>
                        </select>
                        <input v-else type="text" name="" id="" value="dni" placeholder="Nº de tarjeta" class="inputsLogin W100" readonly>
                    </div>
                    <div class="col-8 mt-2 pr-0">
                        <input v-if="proviene === 'registro'" v-model="form.dni" type="text" name="" id="" placeholder="Nº de tarjeta" class="inputsLogin W100">
                        <input v-else  v-model="form.dni" type="text" name="" id="" placeholder="Nº de tarjeta" class="inputsLogin W100" readonly>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="tituloLogin f16x"><b>Datos de contacto</b></div>
            </div>
            <div class="col-12 mt-2">
                <input v-if="proviene === 'registro'" v-model="form.email" type="text" name="" id="" placeholder="Email" class="inputsLogin" >
                <input v-else v-model="form.email" type="text" name="" id="" placeholder="Email" class="inputsLogin" readonly>
            </div>
            <div class="col-12 mt-2">
                <input v-model="postal" type="text" name="" id="" placeholder="Código postal" class="inputsLogin" >
            </div>
            <div class="col-12 mt-2">
                <input v-model="form.town.name" type="text" name="" id="" placeholder="Población (Se rellena con el código postal)" class="inputsLogin">
            </div>
            <div class="col-12 mt-2">
                <select v-model="form.town['pro.id']" name="" id="" class="W80" disabled>
                    <option value="">Selecciona una provincia</option>
                    <option v-for="(provincia, index) in provincias" :key="index" :value="provincia.id">{{ provincia.name }}</option>
                </select>
            </div>
            <div class="col-12 mt-2">
                <input v-model="form.address" type="text" name="" id="" placeholder="Dirección" class="inputsLogin" >
            </div>
            <div class="col-12 mt-2">
                <input v-model="form.phone" type="text" name="" id="" placeholder="Nº de teléfono" class="inputsLogin">
            </div>
            <div class="col-12 mt-2">
                <input v-model="form.mobile" type="text" name="" id="" placeholder="Nº de teléfono móvil" class="inputsLogin">
            </div>
            <div v-if="form.ase_princi !== undefined" class="col-12 mt-2">
                <label for="" class="W80 text-left">Aseguradora principal</label>
                <select class="selectLogin" disabled v-model="form.ase_princi">
                    <option v-for="(aseguradora, index) in aseguradoras" :value="aseguradora.id" :key="index">
                        {{ aseguradora.name }}
                    </option>
                </select>
            </div>

            <div v-if="aseguradoras.length !== 0" class="col-12 mt-2">
                <label for="" class="W80 text-left">Selecciona la aseguradora/as</label>
                <select v-model="form.sociedades" class="selectLogin" multiple>
                    <option v-for="(aseguradora, index) in aseguradoras" :value="aseguradora" :key="index">
                        {{ aseguradora.name }}
                    </option>
                </select>
            </div>

            <div v-if="proviene !== 'registro'" class="col-12 mt-3">
                <div class="tituloLogin f16x"><b>Notificaciones</b></div>
            </div>

            <div v-if="proviene !== 'registro'" class="row W80 mAuto">
                <div class="col-7 col-md-8 text-left">
                    <span>¿Quieres recibir notificaciones por email?</span>
                </div>
                <div class="col-2 pl-0 pr-0">
                    <input type="radio" name="mail" class="mr-2">
                    <label for="">Si</label>
                </div>
                <div class="col-3 col-md-2 pl-0 pr-0">
                    <input type="radio" name="mail" class="mr-2">
                    <label for="">No</label>
                </div>
            </div>
            <div v-if="proviene !== 'registro'" class="row W80 mAuto">
                <div class="col-7 col-md-8 text-left">
                    <span>¿Quieres recibir notificaciones por SMS?</span>
                </div>
                <div class="col-2 pl-0 pr-0">
                    <input type="radio" name="sms" class="mr-2">
                    <label for="">Si</label>
                </div>
                <div class="col-3 col-md-2 pl-0 pr-0">
                    <input type="radio" name="sms" class="mr-2">
                    <label for="">No</label>
                </div>
            </div>
            <div v-if="proviene === 'registro'" class="col-12 mt-3">
                <div class="tituloLogin f16x"><b>Contraseña</b></div>
            </div>
            <div v-if="proviene === 'registro'" class="col-12 mt-2">
                <input v-model="password" type="password" name="" id="" placeholder="Contraseña" class="inputsLogin">
            </div>

            <div v-if="proviene !== 'registro'" class="col-12 mt-3 pointer">
                <div class="tituloLogin f16x" @click="cambiarPass ? cambiarPass = false : cambiarPass = true"><b>Cambiar contraseña <span v-if="cambiarPass" class="pointer">▾</span><span v-if="!cambiarPass" @click="showFuturas = true" class="pointer">◂</span></b></div>
            </div>

            <div v-if="proviene !== 'registro' && cambiarPass" class="col-12 mt-2">
                <input v-model="newPassword" type="password" name="" id="" placeholder="Contraseña" class="inputsLogin">
            </div>

            <div v-if="proviene !== 'registro' && cambiarPass" class="col-12 mt-3">
                <button class="btn1" @click="cambioPass">Confirmar contraseña</button>
            </div>

            <div v-if="proviene !== 'registro'" class="col-12 mt-3 pointer">
                <div class="tituloLogin f16x" @click="cambiarMail ? cambiarMail = false : cambiarMail = true"><b>Cambiar correo <span v-if="cambiarMail" class="pointer">▾</span><span v-if="!cambiarMail" @click="showFuturas = true" class="pointer">◂</span></b></div>
            </div>

            <div v-if="proviene !== 'registro' && cambiarMail" class="col-12 mt-2">
                <input v-model="newMail" type="text" name="" id="" placeholder="Correo" class="inputsLogin">
            </div>

            <div v-if="proviene !== 'registro' && cambiarMail" class="col-12 mt-3">
                <button class="btn1" @click="cambioCorreo">Confirmar email</button>
            </div>

            <div v-if="proviene !== 'registro'" class="col-12 mt-4">
                <div class="tituloLogin f16x"> <input type="checkbox"> <span class="ml-2">Acepta tratamiento de datos para envío de publicidad</span></div>
            </div>
        </div>

        <!-- <div v-if="proviene !== 'registro'" class="col-12 mt-4">
            <div class="tituloLogin f16x">
                <span class="colorLetraVirtus pointer" @click="asociaPerfil ? asociaPerfil = false : asociaPerfil = true">{{ asociaPerfil ? 'Volver al perfil principal' : '¿Desea asociar un perfil?' }}</span>
            </div>
        </div> -->

        <div v-if="asociaPerfil" class="W100">
             <div class="col-12 mt-2">
                <input v-model="perfilAsociado.tarjeta" type="text" name="" id="" placeholder="Nº de tarjeta sanitaria" class="inputsLogin" >
            </div>
            <div class="col-12 mt-2">
                <input v-model="perfilAsociado.name" type="text" name="" id="" placeholder="Nombre" class="inputsLogin" >
            </div>
            <div class="col-12 mt-2">
                <input v-model="perfilAsociado.lastName" type="text" name="" id="" placeholder="Apellidos" class="inputsLogin" >
            </div>
            <div class="col-12 mt-2">
                <input v-model="perfilAsociado.cumple" type="date" name="" id="" placeholder="Fecha de nacimiento" class="inputsLogin">
            </div>
            <div class="col-12">
                <div class="row mxW80 mAuto">
                    <div class="col-4 mt-2 pl-0 pr-0">
                        <select name="" id="" class="W100 H100">
                            <option value="dni">DNI</option>
                            <option value="nie">NIE</option>
                        </select>
                    </div>
                    <div class="col-8 mt-2 pr-0">
                        <input v-model="perfilAsociado.dni" type="text" name="" id="" placeholder="Nº de tarjeta" class="inputsLogin W100">
                    </div>
                </div>
            </div>
            <div class="col-12"></div>
            <div class="col-12"></div>
        </div>
        <!--         
        <div v-if="proviene !== 'registro'" class="col-12 mt-3">
            <button class="btn1" @click="cambiarPass ? cambiarPass = false : cambiarPass = true">{{ cambiarPass ? 'Ocultar cambio contraseña' : 'Cambiar contraseña'}}</button>
        </div> -->
        <!-- <div v-if="proviene !== 'registro' && cambiarPass" class="col-12 mt-3">
            <div class="tituloLogin f16x"><b>Cambiar contraseña</b></div>
        </div> -->
        
        <!-- <div v-if="proviene !== 'registro' && cambiarPass" class="col-12 mt-2">
            <input v-model="newPassword" type="password" name="" id="" placeholder="Contraseña" class="inputsLogin">
        </div>

        <div v-if="proviene !== 'registro' && cambiarPass" class="col-12 mt-3">
            <button class="btn1">Confirmar contraseña</button>
        </div> -->
        
        <div v-if="proviene === 'registro'" class="col-12 mt-3">
            <div class="mxW80 mAuto text-left f14xMvl"><input v-model="condiciones" type="checkbox"> <b>Acepto las condiciones legales*</b></div>
        </div>
        <div class="col-12 mt-3">
            <button class="btn1" @click="btnPrincipal">{{ proviene === 'registro' ? 'Registro' : 'Guardar' }}</button>
        </div>
    </div>
  </div>
</template>

<script>
// import { firestore } from '../../utils/firebase'
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
// import { doc, setDoc } from "firebase/firestore";

// import { doc, setDoc } from "firebase/firestore";

export default {
  components: {
  },

  data: () => ({
    // mail: '',
    password: '',
    msg: '',
    condiciones: false,
    cambiarPass: false,
    cambiarMail: false,
    newMail: '',
    aseguradorasPerfil: [],
    asociaPerfil: false,
    perfilAsociado: {
        tarjeta: '',
        name: '',
        lastName: '',
        dni: '',
        cumple: ''
    },
    form: {
        name: '',
        lastName: '',
        phone: '',
        address: '',
        birthday: '',
        city: '',
        dni: '',
        mobile: '',
        postalCode: '',
        sociedades: [],
        town: {},
        comercialNotificacion: false,
        email: '',
        his: null
    },
    showError: false,
    showSuccess: false,
    aseguradoras: [],
    provincias: [],
    // provincia: '',
    poblaciones: [],
    postal: '',
    cumple: '',
    naturalSoft: false
  }),

  computed: {
    ...mapGetters({
      uid: 'uid',
      user: 'user'
    })
  },

  props: {
    proviene: String
  },

  watch: {
    async user () {
        if (this.proviene === 'perfil') {
            // const datos = await firestore.collection('users').doc(this.uid).get()
            const resul = await api.getHistorialPacientes2(this.user.dni)
            console.log(resul);
            if (resul.status !== undefined) {
                if (resul.status == 'ERROR') {
                    this.setAuth(false)
                    this.$router.push('/')
                    return
                }
            }
            if (resul.count === 0) {
                // this.form = datos.data()
                this.form = this.user
            } else {
                this.naturalSoft = true
                // this.form = this.user
                this.rellenaFormulario(resul.his[0],'natural')
            }
        }
        this.traeAseguradora()
        this.traeProvincias()
        this.setRuta('Registro')
        if (this.naturalSoft) {
            const res = await api.aseguradorasCliente2(this.user.dni)
            if (res.status) {
                if (res.status == 'ERROR') {
                    this.setAuth(false)
                    this.$router.push('/')
                    return
                }
            }
            this.form.sociedades = res.ase
            this.aseguradorasPerfil = res.ase
        }
    },


    async postal () {
        if (this.postal !== '') {
            this.form.postalCode = this.postal
            const resultados = await api.getPoblaciones2(this.postal)
            if (resultados.status) {
                if (resultados.status == 'ERROR') {
                    this.setAuth(false)
                    this.$router.push('/')
                    return
                }
            }
            this.form.town = resultados.pob.length !== 0 ? resultados.pob[0] : ''
        }
    },

    form () {
        let cumpleCadena = this.form.birthday.split('/')
        cumpleCadena = cumpleCadena[2] + '-' + cumpleCadena[1] + '-' + cumpleCadena[0]
        this.cumple = cumpleCadena
        // if (!this.naturalSoft) {
        //     this.postal = this.form.town.cp
        // }
    }

  },

  async mounted () {
    if (this.proviene === 'perfil') {
        // const datos = await firestore.collection('users').doc(this.uid).get()
        const resul = await api.getHistorialPacientes2(this.user.dni)
        if (resul.status !== undefined) {
            if (resul.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
        }
        if (resul.count === 0) {
            // this.form = datos.data()
            this.rellenaFormulario(this.user, 'firebase')
            // this.form = this.user
        } else {
            this.naturalSoft = true
            // this.form = this.user
            this.rellenaFormulario(resul.his[0], 'natural')
        }
    }
    this.traeAseguradora()
    this.traeProvincias()
    this.setRuta('Registro')
    if (this.user.have_his === 1) {
        const res = await api.aseguradorasCliente2(this.user.dni)
        if (res.status) {
            if (res.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
        }
        this.form.sociedades = res.ase
        this.aseguradorasPerfil = res.ase
        console.log('ccc', this.aseguradorasPerfil);
    } else {
        this.sociedades = this.user.sociedades
    }
  },

  methods: {
    ...mapActions({
      setMsgError: 'setMsgError',
      setModalError: 'setModalError',
      setMsgSuccess: 'setMsgSuccess',
      setModalSuccess: 'setModalSuccess',
      setRuta: 'setRuta',
      setAuth: 'setAuth',
      setUser: 'setUser'
    }),

    async cambioPass () {
        const data = {
            method: 'change_pass',
            q: localStorage.getItem('q'),
            new_pass: this.newPassword
        }
        const res = await api.cambioContra(data)
        
        if (res.status) {
            if (res.status == 'ERROR') {
                this.setMsgError('La contraseña debe tener mas de 8 dígitos')
                this.setModalError(true)
                return
                // this.setAuth(false)
                // this.$router.push('/')
                // return
            } else {
                this.setMsgSuccess('Cambio realizado correctamente')
                this.setModalSuccess(true)
            }
        }
    },

    async cambioCorreo () {
        const data = {
            method: 'change_email',
            q: localStorage.getItem('q'),
            new_email: this.newMail
        }
        const res = await api.cambioMail(data)
        if (res.status) {
            if (res.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
        }
        if (res.data) {
            this.form.email = this.newMail
            // this.setUser(res.data)
            // if (this.proviene === 'perfil') {
            //     // const datos = await firestore.collection('users').doc(this.uid).get()
            //     const resul = await api.getHistorialPacientes2(this.user.dni)
            //     if (resul.status !== undefined) {
            //         if (resul.status == 'ERROR') {
            //             this.setAuth(false)
            //             this.$router.push('/')
            //             return
            //         }
            //     }
            //     if (resul.count === 0) {
            //         // this.form = datos.data()
            //         this.rellenaFormulario(this.user, 'firebase')
            //         // this.form = this.user
            //     } else {
            //         this.naturalSoft = true
            //         // this.form = this.user
            //         this.rellenaFormulario(resul.his[0], 'natural')
            //     }
            // }
            // this.traeAseguradora()
            // this.traeProvincias()
            // this.setRuta('Registro')
            // if (this.user.have_his === 1) {
            //     const res = await api.aseguradorasCliente2(this.user.dni)
            //     if (res.status) {
            //         if (res.status == 'ERROR') {
            //             this.setAuth(false)
            //             this.$router.push('/')
            //             return
            //         }
            //     }
            //     this.form.sociedades = res.ase
            //     this.aseguradorasPerfil = res.ase
            // } else {
            //     this.sociedades = this.user.sociedades
            // }
        }
    },

    async register () {

        if (this.password.length < 8) {
                this.setMsgError('La contraseña debe tener mas de 8 dígitos')
                this.setModalError(true)
                return
            }
            // eslint-disable-next-line
            // const regMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            // if (regMail.test(this.form.email)) {
            //     this.setMsgError('Debes introducir un correo válido')
            //     this.setModalError(true)
            //     return
            // }
        if (this.condiciones) {
            const provincia = this.provincias.filter(it => it.id === this.form.town['pro.id'])
            const city = {
                id: this.form.town['pro.id'],
                'pai.id': 66,
                name: provincia[0].name

            }
            this.cumple = this.cumple.split('-')
            this.cumple = this.cumple[2] + '/' + this.cumple[1] + '/' + this.cumple[0]
            const data = {
                name: this.form.name,
                lastName: this.form.lastName,
                phone: this.form.phone,
                address: this.form.address,
                birthday: this.cumple,
                city: city,
                dni: this.form.dni,
                mobile: this.form.mobile,
                postalCode: this.form.postalCode,
                sociedades: this.form.sociedades,
                town: this.form.town,
                comercialNotificacion: this.form.comercialNotificacion,
                email: this.form.email
            }
            this.$emit('envioInfo', data, this.password) 
        } else {
            const data = {
                error: 'error',
                msg: 'Debes aceptar los terminos y condiciones'
            }
            this.$emit('envioInfo', data) 
        }
    },

    rellenaFormulario (datos, proviene) {
        console.log('rellenaFormulario',datos)
        if (proviene === 'natural') {
            console.log('natural')
            const nombre = datos.name.split(',')
            // let cumple = datos.fch_nac.split('T')
            // cumple = cumple[0].split('-')
            // cumple = cumple[2] + '/' + cumple[1] + '/' + cumple[0]
            // let cumple = this.user.birthday
            let cumple = datos.fch_nac.split('T')
            cumple = cumple[0].split('-')
            cumple = cumple[2] + '/' + cumple[1] + '/' + cumple[0]
            this.form = {
                have_his: datos.have_his,
                name: nombre[1],
                lastName: nombre[0],
                postalCode: datos.cp,
                email: datos.email,
                birthday: cumple,
                phone: datos.tlf2,
                mobile: datos.tlf1,
                ase_princi: datos.ase,
                address: datos.dir,
                city: {
                    name: datos['pob_enl.name'],
                    id: datos['pob_enl.id']
                },
                town: datos['pro.name'],
                dni: datos.nif
            }
            this.postal = datos.cp
        } else {
            console.log('firebase')
            this.form = {
                his: datos.id,
                name: datos.name,
                lastName: datos.lastName,
                postalCode: datos.postalCode,
                email: datos.email,
                birthday: datos.birthday,
                phone: datos.phone,
                mobile: datos.mobile,
                address: datos.address,
                city: {
                    name: datos.city['name'],
                    id: datos.city['pai.id']
                },
                town: datos.city['name'],
                dni: datos.dni
            }
            this.form.sociedades = datos.sociedades
            this.postal = datos.postalCode
        }
    },

    async guardo () {
        if (this.user.have_his === 1) {
            const aseguraList = this.organizaAseguradoras(this.form.sociedades, this.aseguradorasPerfil)
            this.agregaAseguradoras(aseguraList.agrega)
            this.borraAseguradoras(aseguraList.borra)
            let cumpleInput = this.cumple
            if(this.cumple.includes('/')) {
                this.form.birthday = this.cumple
            } else {
                this.cumple = this.cumple.split('-')
                this.form.birthday = this.cumple[2] + '/' + this.cumple[1] + '/' + this.cumple[0] 
            }
            // await firestore.collection('users').doc(this.uid).update(this.form)
            console.log('form',this.form);
            this.setUser(this.form)
            if (this.user.have_his !== 1) {
               await api.modificarDatosSoft2(this.form)
            }
            this.setMsgSuccess('Cambio realizado correctamente')
            this.setModalSuccess(true)
            this.cumple = cumpleInput
        } else {
            this.setMsgError('No tienes historial de paciente, debes previamente pedir una cita para modificar sus datos')
            this.setModalError(true)
            return
        }
    },

    agregaAseguradoras (listado) {
        listado.forEach(async item => {
            console.log('hhh',item);
            console.log('ggg',this.user);
          // await api.agregaAseguradora2(this.user.his,item)
           await api.agregaAseguradora2(item)
        })
    },

    borraAseguradoras (listado) {
        listado.forEach(async item => {
            await api.borraAseguradora2(item)
        })
    },

    organizaAseguradoras (array1, array2) {
        const agrega = []
        const borra = []
        // Recorremos el primer array
        for (let i = 0; i < array1.length; i++) {
            const currentId = array1[i].id;
            
            // Si el ID no está en el segundo array, lo añadimos a uniqueIds
            if (!array2.some(item => item.id === currentId)) {
                agrega.push(currentId);
            }
        }
        
        // Recorremos el segundo array
        for (let i = 0; i < array2.length; i++) {
            const currentId = array2[i].id;
    
            // Si el ID no está en el primer array, lo añadimos a uniqueIds
            if (!array1.some(item => item.id === currentId)) {
                borra.push(currentId);
            }
        }
        
        return {
            agrega: agrega,
            borra: borra
        };
    },

    async traeAseguradora () {
      const resultados = await api.getAseguradora2()
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      this.aseguradoras = resultados.ase
    },

    btnPrincipal () {
        this.proviene === 'registro' ? this.register() : this.guardo()
    },

    async traeProvincias () {
      const resultados = await api.getProvincias2()
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      this.provincias = resultados.pro
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.W80{
    width:80%;
}
.selectLogin{
    width: 80%;
}
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    max-width: 100%;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 80%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.f16x{
    font-size:16px;
}

.W100{
    width:100%;
}

.H100{
    height: 100%;
}

.W80{
    width: 80%;
}

@media (max-width: 768px) {
    .noMvlRespon{
        display: none;
    }

    .f14xMvl{
        font-size: 14px;
    }
}

.colorLetraVirtus {
    color: #2a4a5e;
}

@media (min-width: 768px) {
  .noPc{
    display: none;
  }
}

.mxW40x{
    max-width: 40px;
}
</style>
