<template>
  <div class="container mxW100">
    <div class="row">
        <div class="col-12 col-md-6 text-center">
            <formulario-usuario proviene="registro" @envioInfo="register" />
        </div>
        <div class="col-0 col-md-6">
            <img class="btnHead noMvlRespon" alt="Email" src="../../assets/imagenregistro.png">
        </div>
    </div>
  </div>
</template>

<script>
// import api from '@/services/api'
// import Test from './Test.vue'

// import { firestore, auth } from '../../utils/firebase'
import api from '@/services/api'
import { mapActions } from 'vuex'
import FormularioUsuario from '../general/FormularioUsuario.vue'

export default {
  components: { FormularioUsuario,
    // Test
  },

  data: () => ({
    showError: false,
    showSuccess: false,
    inputList: [{
      tag: 'input',
      type: 'text',
      value: ''
    },
    {
      tag: 'input',
      type: 'text',
      value: ''
    },
    {
      tag: 'input',
      type: 'number',
      value: ''
    },
    {
      tag: 'input',
      type: 'date',
      value: ''
    },
    {
      tag: 'input',
      type: 'time',
      value: ''
    },
    {
      tag: 'input',
      type: 'checkbox',
      value: 'false',
      id: 'test40'
    },
    {
      tag: 'select',
      type: '',
      value: [],
      options: [{ value: '1', name:'Hola' }, { value: '2', name:'Adios' }]
    },
    {
      tag: 'select',
      type: '',
      multiple: 'multiple',
      id: 'test2',
      value: [],
      options: [{ value: '1', name:'Hola' }, { value: '2', name:'Adios' }]
    }]
  }),

  // async mounted () {
  //   console.log('Entrooo??')
  // },

  methods: {
    ...mapActions({
      setMsgError: 'setMsgError',
      setModalError: 'setModalError',
      setMsgSuccess: 'setMsgSuccess',
      setModalSuccess: 'setModalSuccess'
    }),

    inputVal (val, input) {
    //   console.log(val)
    //   console.log(input.value)
    //   if (input.multiple?.toString()) {
    //     input.value = val
    //   } else {
    //     input.value = val
    //   }
      input.value = val

    },

    async register (form, pass = '') {
        if (pass === '') {
            this.setMsgError('Rellena una constraseña válida')
            this.setModalError(true)
        } else if (form.email === '') {
            this.setMsgError('Debes rellenar el correo electrónico')
            this.setModalError(true)
        } else {
          const data = {
            method: 'create_account',
            user_info: form,
            password: pass
          }
          const res = await api.login(data)
          if (res.status == 'OK') {
            this.setMsgSuccess('Usuario enviado correctamente')
            this.setModalSuccess(true)
          }
          
            // auth.createUserWithEmailAndPassword(form.email, pass)
            // .then(async (userCredential) => {
            //     const user = userCredential.user;
            //     await firestore.collection('users').doc(user.uid).set(form)
            //     this.setMsgSuccess('Usuario registrado de forma correcta')
            //     this.setModalSuccess(true)
            //     this.$router.push('/')
            // })
            // .catch(async (error) => {
            //     if (error.code === 'auth/weak-password') {
            //         this.setMsgError('La contraseña debe tener al menos 6 caracteres')
            //         this.setModalError(true)
            //     } else if (error.code === 'auth/email-already-in-use') {
            //         this.setMsgError('El email ya se encuentra registrado')
            //         this.setModalError(true)
            //     }
            // });
        }
    }
  }
}
</script>
<style scoped>
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    max-width: 100%;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 80%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.f16x{
    font-size:16px;
}

.W100{
    width:100%;
}

.H100{
    height: 100%;
}

@media (max-width: 768px) {
    .noMvlRespon{
        display: none;
    }

    .f14xMvl{
        font-size: 14px;
    }
}
</style>
