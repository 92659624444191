<template>
<div>
  <div class="grid-gestor-citas noMvl" :class="pruebas === '1' ? 'pruebasComplementarias' : ''">
    <div v-for="(item, index) in listado" :key="index" :class="bold !== undefined ? 'font-weight-bold' : ''" :style="index === 'id' || index === 'cita' || index === 'itemListado'  || index === 'docs' ? 'display:none' : ''"> 
      <span v-if="muestraId(index, item) && index !== 'toggledoc'">{{ item }}</span>
      <button class="toggleclass pointer" @click="toggleDoc()" v-if="index === 'toggledoc' && item === false"> <i class="arrow up"></i> </button>
      <button class="toggleclass pointer" @click="toggleDoc()" v-if="index === 'toggledoc' && item === true"> <i class="arrow down"></i> </button>
      </div>
    <div v-if="boton !== undefined " :style="'background:' + bgBtn + '!important;'" class="btnListado pointer a1" @click="botonC1(listado)" data-target="#exampleModal">
      {{ boton }}
    </div>
   <!-- <div v-if="boton !== undefined && link_images !== undefined && !isImage(listado)" :style="'background:' + bgBtn + '!important;'" class="btnListado pointer a3" @click="botonC1(listado)" data-target="#exampleModal">
      {{ boton }}
    </div>-->
    <div v-if="boton2 !== undefined" :style="'background:' + bgBtn2 + '!important;'" class="btnListado2 pointer" @click="botonC2">
        {{ boton2 }}
    </div>
  </div>
  <transition name="slide-fade">
  <div v-if="pruebas ==='1' && listado.toggledoc === true" class="borderPruebas">
    <div  class="grid-gestor-pruebas noMvl" v-for="(item, index) in listado.docs" :key="index">
      <div></div> <!-- celda vacia -->
      <div><span>{{ item.name }}</span></div>
      <div></div> <!-- celda vacia -->
      <div v-if="item.type === 'doc'" :style="'background:' + bgBtn + '!important;'" class="btnListado pointer" @click="botonPruebasCompl(item)" data-target="#exampleModal">
        Descargar
      </div>
      <div v-if="item.type === 'link'" :style="'background:' + bgBtn + '!important;'" class="btnListado pointer" @click="viewImage(item)" data-target="#exampleModal">
        Ver
      </div>
    </div>
  </div>
  </transition>
  <div :class="proviene !== 'Historial' ? 'grid-gestor-citas2 noPc pb-2 pt-2 border' : 'grid-gestor-citas3 noPc'">
    <div v-if="proviene !== 'Historial'"  class="flexCenter">
      <div v-for="(item, index) in listado" :key="index" :class="bold !== undefined ? 'font-weight-bold f13px' : 'f13px'" :style="index !== 'fecha' ? 'display:none' : ''">{{ item }}</div>
    </div>
    <div v-if="proviene === 'Historial'"  class="flexColumn">
      <div v-for="(item, index) in listado" :key="index" :class="bold !== undefined ? 'font-weight-bold f13px text-left' : 'f13px text-left'" :style="index === 0 || index === 1 ? '' : 'display:none'">{{ item }}</div>
      </div>
    <div>
      <div v-if="proviene !== 'Historial'">
        <div v-for="(item, index) in listado" :key="index" :class="bold !== undefined ? 'font-weight-bold f13px' : 'f13px'" :style="index === 'tipoCita' || index === 'id' || index === 'cita' || index === 'itemListado' || index === 'fecha' ? 'display:none' : ''">{{ item }}</div>
      </div>
      <div :class="proviene !== 'Historial' ? 'grid-gestor-citas3 mt-2' : ''">
        <div v-if="boton !== undefined && boton !== 'Descargar'" :style="'background:' + bgBtn + '!important;'" class="btnListado f11px" @click="botonC1">
          {{ boton }}
        </div>
        <i v-if="boton === 'Descargar'" class="fa-solid fa-download" id="eye" @click="muestraPass"></i>
        <div v-if="boton2 !== undefined" :style="'background:' + bgBtn2 + '!important;'" class="btnListado2 f11px" @click="botonC2">
            {{ boton2 }}
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<button @click="show = !show">Toggle</button>
<Transition name="slide-fade">
  <p v-if="show">hello</p>
</Transition>
-->
</template>
<script>
import { mapActions } from 'vuex'
import api from '@/services/api'

export default {
  components: {
  },

  props: {
    listado: Array,
    columnas: String,
    idDescarga: String,
    boton: String,
    boton2: String,
    bold: String,
    bgBtn: String,
    bgBtn2: String,
    proviene: String,
    pruebas: String,
    indice: Number,
    tipo: String
  },

  methods: {
    ...mapActions({
      setCita: 'setCita',
      setAuth: 'setAuth',
      setJsonModal: 'setJsonModal'
    }),

    toggleDoc() {
        this.$emit('change-toggle', this.indice, this.tipo) 
    },

    async botonC1 () {
      if (this.boton === 'Anular cita') {
        const data = {
          type: 'anulaCita',
          title: 'Anular cita',
          body: '¿Está seguro que quiere anular la cita?',
          cerrar: 'Cancelar',
          id: this.listado.id,
          aceptar: 'Aceptar'
        }
        this.setJsonModal(data)
        document.getElementById('botonlaunch').click()
        this.$emit('refreshPasadas') 
      } else if (this.boton === 'Repetir cita') {
        this.setCita(this.listado.itemListado)
        this.$router.push('/appoiments')
      } else if (this.boton === 'Descargar') {
        const id = this.listado.length - 1
        
        const resultado = await api.descargarInforme2(this.listado[id])
        if (resultado.status) {
          if (resultado.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
          }
        }

        const nombre = this.listado[id - 1].split('.')
        if (nombre[nombre.length - 1] === 'pdf') {
          this.downloadPDF(resultado, this.listado[id - 1])
        } else {
          this.downloadImage(resultado, nombre[nombre.length - 1], this.listado[id - 1])
        }
      }
    },

    isImage(item) {
       
       const id = item.length - 1
       if (!isNaN(item[id]))
       {
         return false
       }
       if (item[id].indexOf('url_img:') === 0)
       {
         return true
       }
       return false
    },

    viewImage(item) {
      const link = item.link

      window.open(link, "_blank");
    },

    async botonPruebasCompl (item) {
       const resultado = await api.descargarInforme2(item.id)
        if (resultado.status) {
          if (resultado.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
          }
        }

        const nombre = item.name.split('.')
        if (nombre[nombre.length - 1] === 'pdf') {
          this.downloadPDF(resultado, item.name)
        } else {
          this.downloadImage(resultado, nombre[nombre.length - 1], item.name)
        }
    },

    downloadImage (base64, ext, name) {
      var a = document.createElement("a")
      a.href = "data:image/" + ext + ";base64," + base64
      a.download = name
      a.click()
    },

    downloadPDF(pdf, name) {
      const linkSource = `data:application/pdf;base64,${pdf}`
      const downloadLink = document.createElement("a")
      const fileName = name
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click();
    },

    async botonC2 () {
      if (this.boton2 === 'Cambiar cita') {
        this.setCita(this.listado.itemListado)
        this.$router.push('/appoiments')
      }
    },

    muestraId (index) {
      if (this.boton === 'Descargar' && (this.listado.length - 1) === index) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
.flexCenter{
  display: flex;
  align-items:center;
  justify-content: center;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .noMvl {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .noPc {
    display: none !important;
  }
}
.grid-gestor-citas{
  display: grid;
  grid-template-columns: 20% 20% 20% 15% 13% 12%;
}

.grid-gestor-pruebas{
  display: grid;
  grid-template-columns: 20% 40% 15% 13% 12%;
  margin: 10px 0px;
}

.grid-gestor-citas2{
  display: grid;
  grid-template-columns: 30% 70%;
}

.grid-gestor-citas3{
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: end;
}

.f13px{
  font-size: 13px;
}

.f11px{
  font-size: 11px;
}

@media (min-width: 923ox) {
}
.btnListado{
  width: 90%;
  margin: 0 auto;
  padding:2px 10px;
  background: #2a4a5e;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 31px; */
  height: 54px;
}

.btnListado2{
  width: 90%;
  margin: 0 auto;
  padding:2px 10px;
  background: #2a4a5e;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
}

.border, .borderPruebas {
  border: 1px solid black;
}

.borderPruebas {
  transition: height 200ms;
}

.pruebasComplementarias {
  margin-bottom: 15px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.toggleclass {
  margin: 0 auto;
  padding: 2px 10px;
  background: #2a4a5e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
