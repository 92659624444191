<template>
  <div class="container W90 p-5">
    <div class="row">
        <div class="col-12 mt-4 mb-5">
          <secciones selectOpcion="Historial" />
        </div>
        <div class="col-12 mt-2 text-center mt-1 mb-5">
          <h4>Recetas</h4>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-6 text-center">
                    <div class="btn1 fRight" :class="recetaNueva ? 'colorSelect' : 'colorNoSelect'" @click="recetaNueva=true">Nuevas recetas</div>
                </div>
                <div class="col-6 text-center">
                    <div class="btn1" :class="!recetaNueva ? 'colorSelect' : 'colorNoSelect'" @click="recetaNueva=false">Mi necetario recurrente</div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2 text-center mt-5" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>
        <div v-if="recetaNueva" style="width:100%;">
            <div v-for="(item, index) in lista" :key="index" class="col-12 mt-2 text-center mt-5 mb-5">
                <listado :listado="item" :columnas="100/4" boton="Descarga" />
            </div>
        </div>
         <div v-if="!recetaNueva" style="width:100%;">
            <div v-for="(item, index) in lista2" :key="index" class="col-12 mt-2 text-center mt-5 mb-5">
                <listado :listado="item" :columnas="100/4" boton="Descarga" />
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Listado from '../components/general/Listado.vue'
import Secciones from '../components/general/Secciones.vue'

export default {
  components: {
    Listado,
    Secciones
  },

  data: () => ({
    recetaNueva: true,
    citas: [
      {tipo: 'Cardiología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'LUNES 6 NOV 18:30h'},
      {tipo: 'Psicología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'JUEVES 9 NOV 18:30h'}
    ],
    titulos: ['Mes', 'Tipo de receta', 'Especialidad', 'Archivo adjunto'],
    lista: [['Noviembre', 'Receta farmacia', 'Ginecologo', 'AJDS384322920'],['Enero', 'Receta farmacia', 'Ginecologo', 'AJDS384322920']],
    lista2: [['Marzo', 'Recenta recurrente', 'Cardiología', 'AJDS384322920'],['Febrero', 'Receta recurrente', 'Cardiología', 'AJDS384322920']]
  })
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}

.W90 {
  max-width: 90%;
}

.btn1{
    border: none;
    color: white;
    padding: 5px 0px;
    width: 60%;
}

.colorSelect{
    background: #2a4a5e;
    color: #fff;
}

.colorNoSelect{
    background: #fff;
    border: 1px solid #2a4a5e;
    color: #2a4a5e;
}

.fRight{
    float:right;
}
</style>
