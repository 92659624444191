<template>
  <div class="mainControl">
    <BajaUsuario />
  </div>
</template>

<script>
import BajaUsuario from '../components/sesion/BajaUsuario.vue'

export default {
  components: {
    BajaUsuario
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}
</style>
