<template>
  <!-- <div class="mainControl">
    
  </div> -->
  <div class="container W90 p-5 p0x">
    <div class="row">
        <div v-if="auth" class="col-12 mb-4 nvMvl p-0">
          <secciones selectOpcion="Contacta" @recojoRuta="cambiaRuta" />
        </div>
        <div class="col-12 text-center mt-5 p-0">
          <!-- <select v-model="test" name="" id="" multiple>
            <option value="1">Hola</option>
            <option value="2">Adios</option>
          </select> -->
            <!-- <test v-model="test" /> -->
            <FormularioContacto />
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormularioContacto from '../components/contacto/Formulario.vue'
import Secciones from '../components/general/Secciones.vue'

export default {
  components: {
    FormularioContacto,
    Secciones
  },

  computed: {
    ...mapGetters({
      auth: 'auth',
    })
  },

  data: () => ({
    test: '',
    citas: [
      {tipo: 'Cardiología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'LUNES 6 NOV 18:30h'},
      {tipo: 'Psicología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'JUEVES 9 NOV 18:30h'}
    ]
  }),

  // methods: {
  //   cambiaRuta (ruta) {
  //     console.log(ruta)
  //   }
  // }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
}

@media (max-width: 767px) {
  .nvMvl{
    display: none;
  }

  .p0x{
    padding:0px !important;
  }
}
</style>
