<template>
  <div class="container mt-3 mb-3 mb25x">
    <div class="row m-0">
      <div class="col-12 text-center">
        <b>Descárgate la APP de Grupo Virtus</b>
      </div>
      <div class="col-6 text-right">
        <img class="btnHead" alt="Email" src="../../assets/googlestore.png">
      </div>
      <div class="col-6 text-left">
        <img class="btnHead" alt="Email" src="../../assets/appstore.png">
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Inicio from './components/Inicio.vue'

export default ({
})
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media (max-width: 768px) {
  .mb25x{
    margin-bottom: 25px;
  }
}
</style>