import Vuex from 'vuex'

export default new Vuex.Store({
  state: {
    auth: false,
    uid: '',
    nombre: '',
    modalSuccess: false,
    modalError: false,
    jsonModal: {},
    citaItem: {},
    user: {},
    msgError: '',
    ruta: '/',
    msgSuccess: ''
  },
  getters: {
    auth: state => state.auth,
    uid: state => state.uid,
    nombre: state => state.nombre,
    user: state => state.user,
    modalSuccess: state => state.modalSuccess,
    modalError: state => state.modalError,
    jsonModal: state => state.jsonModal,
    msgError: state => state.msgError,
    citaItem: state => state.citaItem,
    ruta: state => state.ruta,
    msgSuccess: state => state.msgSuccess
  },
  mutations: {
    setAuth (state, auth) {
      state.auth = auth
    },

    setUser (state, user) {
      state.user = user
    },

    setUid (state, uid) {
      state.uid = uid
    },

    setModalSuccess (state, modalSuccess) {
      state.modalSuccess = modalSuccess
    },

    setModalError (state, modalError) {
      state.modalError = modalError
    },

    setJsonModal (state, jsonModal) {
      state.jsonModal = jsonModal
    },

    setMsgError (state, msgError) {
      state.msgError = msgError
    },

    setMsgSuccess (state, msgSuccess) {
      state.msgSuccess = msgSuccess
    },

    setNombre (state, nombre) {
      state.nombre = nombre
    },

    setRuta (state, ruta) {
      state.ruta = ruta
    },

    setCita (state, citaItem) {
      state.citaItem = citaItem
    }
  },
  actions: {

    setAuth ({ commit }, auth) {
      commit('setAuth', auth)
    },

    setUser ({ commit }, user) {
      commit('setUser', user)
    },

    setUid ({ commit }, uid) {
      commit('setUid', uid)
    },

    setModalSuccess ({ commit }, modalSuccess) {
      commit('setModalSuccess', modalSuccess)
    },

    setModalError ({ commit }, modalError) {
      commit('setModalError', modalError)
    },

    setJsonModal ({ commit }, jsonModal) {
      commit('setJsonModal', jsonModal)
    },

    setMsgError ({ commit }, msgError) {
      commit('setMsgError', msgError)
    },

    setMsgSuccess ({ commit }, msgSuccess) {
      commit('setMsgSuccess', msgSuccess)
    },

    setNombre ({ commit }, nombre) {
      commit('setNombre', nombre)
    },

    setRuta ({ commit }, ruta) {
      commit('setRuta', ruta)
    },

    setCita ({ commit }, citaItem) {
      commit('setCita', citaItem)
    }

  },
  strict: true
})