<template>
  <div class="container W90 ptPc-5 pbPc-5">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="row">
        <div class="col-12 mt-4 mb-5 noMvl">
          <secciones selectOpcion="Historial" />
        </div>
        <div class="col-12 col-md-10 mt-2 text-center mt-1 pbPc-5 mvlTop">
          <h4><i class="fa-solid fa-clipboard" id="eye"></i> Informes</h4>
        </div>
        <div class="col-0 col-md-2" />
        <div class="col-0 col-md-2 noPc" >
        </div>
        <div v-if="false && lista.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div v-if="filterInforme == 'Pruebas de laboratorio' && listaTipo1.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div v-if="filterInforme == 'Pruebas de radiología' && listaTipo2.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div v-if="filterInforme == 'Informes de consulta' && listaTipo3.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div v-if="filterInforme == 'Consentimientos informados' && listaTipo4.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div v-if="filterInforme == 'Otros documentos no asistenciales' && listaTipo5.length !== 0" class="col-10 mt-2 text-center mt-5 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>

        <div class="col-12 col-md-2 noPc">
          <span @click="filtroMbl ? filtroMbl = false : filtroMbl = true"><i class="fa-solid fa-filter colorVirtus" id="eye"></i> Filtro</span>
          <!-- <buscador-listados @buscador="filtroBuscador" /> -->
          <div v-if="filtroMbl" class="row">
            <div class="col-6 text-left pl-0 pr-0">
              <label for="">Desde</label>
              <input v-model="desde" type="date" class="w95" placeholder="Desde">
            </div>
            <div class="col-6 text-left pl-0 pr-0">
              <label for="">Hasta</label>
              <input v-model="hasta" type="date" class="w95" placeholder="Hasta">
            </div>
            <div class="password-container mt-2">
              <input v-model="filterInforme" placeholder="Tipo de informe" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer w98">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <!-- <div class="password-container mt-2">
              <input v-model="filterEspecialidad.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'espe'" class="pointer w98">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div> -->
            <!-- <div class="flex w100">
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="logIn" type="submit">Limpiar</button>
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="logIn" type="submit">Buscar</button>
            </div> -->
            <div class="flex w100">
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="limpiarBsq" type="submit">Limpiar</button>
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsq" type="submit">Buscar</button>
            </div>
          </div>
        </div>
        <div v-if="false && lista.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div v-if="!loadingData && filterInforme == ''" class="col-12 col-md-10 mt-2 text-center">
          <span>Debe seleccionar primero un  tipo de informe</span>
        </div>

        <div v-if="loadingData && filterInforme == ''" class="col-12 col-md-10 mt-2 text-center">
          <span>Cargando información. Espere, por favor</span>
        </div>


        <div v-if="filterInforme == 'Pruebas de laboratorio' && listaTipo1.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div v-if="filterInforme == 'Pruebas de radiología' && listaTipo2.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div v-if="filterInforme == 'Informes de consulta' && listaTipo3.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div v-if="filterInforme == 'Consentimientos informados' && listaTipo4.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div v-if="filterInforme == 'Otros documentos no asistenciales' && listaTipo5.length === 0" class="col-12 col-md-10 mt-2 text-center">
          <span>No hay informes asociados</span>
        </div>

        <div class="col-12 col-md-2 noMvl" v-if="!loadingData">
          <!-- <buscador-listados @buscador="filtroBuscador" /> -->
          <div class="row">
            <div class="col-6 text-left pl-0 pr-0">
              <label for="">Desde</label>
              <input v-model="desde" type="date" class="w95 f75p" placeholder="Desde">
            </div>
            <div class="col-6 text-left pl-0 pr-0">
              <label for="">Hasta</label>
              <input v-model="hasta" type="date" class="w95 f75p" placeholder="Hasta">
            </div>
            <div class="password-container mt-2">
              <input v-model="filterInforme" placeholder="Tipo de informe" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer w98">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <div class="flex w100">
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="limpiarBsq" type="submit">Limpiar</button>
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsq" type="submit">Buscar</button>
          </div>
          </div>
        </div>
        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3" v-if="false">
          <div v-for="(item, index) in lista" :key="index" class="pb-3 pt-3">
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" boton="Descargar" />
          </div>
        </div>

        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3"  v-if="filterInforme == 'Pruebas de laboratorio'">
        <div v-for="(item, index) in listaTipo1" :key="index" class="pb-3 pt-3">
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" pruebas="1" :indice="index" tipo="1"  @change-toggle="changeToggle"/>
          </div>
        </div>

        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3"  v-if="filterInforme == 'Pruebas de radiología'">
          <div v-for="(item, index) in listaTipo2" :key="index" class="pb-3 pt-3">
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" pruebas="1" :indice="index" tipo="2"  @change-toggle="changeToggle"/>
          </div>
        </div>

        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3"  v-if="filterInforme == 'Informes de consulta'">
          <div v-for="(item, index) in listaTipo3" :key="index" class="pb-3 pt-3" >
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" boton="Descargar" />
          </div>
        </div>

        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3"  v-if="filterInforme =='Consentimientos informados'">
          <div v-for="(item, index) in listaTipo4" :key="index" class="pb-3 pt-3">
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" boton="Descargar" />
          </div>
        </div>

        <div class="col-12 col-md-10 mt-2 text-center pt-3 pb-3"  v-if="filterInforme ==='Otros documentos no asistenciales'">
          <div v-for="(item, index) in listaTipo5" :key="index" class="pb-3 pt-3">
            <listado :listado="item" proviene="Historial" :columnas="100/4" :idDescarga="idDescarga" boton="Descargar" />
          </div>
        </div>

    </div>
    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog filterAbsoluteInfo" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="filter === 'espe'" class="modal-title" id="exampleModalLabel">Selecciona especialidad</h5>
            <!-- <h5 class="modal-title" id="exampleModalLabel">Filtro de fecha</h5> -->
          </div>
          <input v-if="filter === 'doctor' || filter === 'espe'" v-model="bsqEspecialidad" type="text" class="mAuto d-block" :placeholder="tipo !== 'espe' ? 'Busca la especialidad' : 'Busca por doctor'">
          <div class="modal-body">
            <div class="row">
              <div class="col-10 text-center">
                <input v-if="filter === 'anio'" v-model="searchAno" type="number" min="0" placeholder="Otro año">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x" :class="filter === 'espe' || filter === 'tipo' ? 'overflowAuto' : ''">
                <!-- <div v-if="filter === 'espe'" class="row">
                  <div v-for="(espe, index) in especialidades" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterEspecialidad = espe">{{ espe.name }}</span>
                  </div>
                </div> -->
                <div v-if="filter === 'tipo'" class="row">
                  <div v-for="(infor, index) in tipoInforme" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterInforme = infor.name">{{ infor.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import api from '@/services/api'
// import BuscadorListados from '../general/BuscadorListados.vue'
import Listado from '../general/Listado.vue'
import Secciones from '../general/Secciones.vue'

export default {
  components: {
    Listado,
    // BuscadorListados,
    Secciones
  },

  data: () => ({
    filterInforme: '',
    citas: [
      {tipo: 'Cardiología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'LUNES 6 NOV 18:30h'},
      {tipo: 'Psicología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'JUEVES 9 NOV 18:30h'}
    ],
    titulos: ['Fecha y horario', 'Tipo de informe', 'Archivo adjunto'],
    lista: [],
    listaTipo1: [],
    listaTipo2: [],
    listaTipo3: [],
    listaTipo4: [],
    listaTipo5: [],
    filter: '',
    desde: '',
    hasta: '',
    filterEspecialidad: '',
    especialidades: [],
    idDescarga: '',
    generico: [],
    genericoTipo1: [],
    genericoTipo2: [],
    genericoTipo3: [],
    genericoTipo4: [],
    genericoTipo5: [],
    especialidadesMain: [],
    bsqEspecialidad: '',
    loadingData: false,
    tipoInforme: [
    {
      id: 1,
      name: 'Pruebas de laboratorio'
    },
    {
      id: 2,
      name: 'Pruebas de radiología'
    },
    {
      id: 3,
      name: 'Informes de consulta'
    },
    {
      id: 4,
      name: 'Consentimientos informados'
    },
    {
      id: 5,
      name: 'Otros documentos no asistenciales'
    }
  ],
    filtroMbl: false
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {

    // filterEspecialidad () {
    //   if (this.filterEspecialidad !== '') {
    //     this.traeProfesionales()
    //   }
    // },

    bsqEspecialidad () {
      if (this.filter === 'espe') {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.especialidadesMain
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        } else {
          this.especialidades = []
          this.especialidadesMain.forEach((item) => {
            if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
          })
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        }
      } else {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.profesionalMain
          this.especialidades.forEach((profesional) => {
            this.listadoProfesionales.push(profesional.id)
          })
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        } else {
          this.listadoProfesionales = []
          this.especialidades = this.profesionalMain.map((item) => {
            if (item.includes(this.bsqEspecialidad)) { 
              this.listadoProfesionales.push(item.id)
              return item
            }
          })
          this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
      }
    },
  },

  async mounted () {
    this.traeEspecialides()
    if (this.user.have_his === 1) {
      const resultados = await api.getInformes2()
      this.listaTipo1 = []
      this.listaTipo2 = []
      this.listaTipo3 = []
      this.listaTipo4 = []
      this.listaTipo5 = []
      this.loadingData = true
      var i = 0;
      let aux2 = {epi_doc: []}
      let aux1 = {epi_doc: []}
    /*  let aux1 = await api.getInformesPorTipo(1);
     
     
      if (aux1.epi_doc != undefined && aux1.epi_doc != null && aux1.epi_doc.length > 0)
      {
          for ( i = 0; i < aux1.epi_doc.length; i++)
          {
            aux1.epi_doc[i].cla_int_doc = '1';
                   
          }
      }
          
      let aux2 = await api.getInformesPorTipo(2);
      if (aux2.epi_doc != undefined && aux2.epi_doc != null && aux2.epi_doc.length > 0)
      {
        for ( i = 0; i < aux2.epi_doc.length; i++)
        {
          aux2.epi_doc[i].cla_int_doc = '2';
                       
        }
      }
      */
      let aux3 = await api.getInformesPorTipo(3);
      if (aux3.epi_doc != undefined && aux3.epi_doc != null && aux3.epi_doc.length > 0)
      {
          for ( i = 0; i < aux3.epi_doc.length; i++)
          {
              aux3.epi_doc[i].cla_int_doc ='3';
                    
          }
      }
      
      let aux4 = await api.getInformesPorTipo(4);
      if (aux4.epi_doc != undefined && aux4.epi_doc != null && aux4.epi_doc.length > 0)
      {
          for ( i = 0; i < aux4.epi_doc.length; i++)
          {
              aux4.epi_doc[i].cla_int_doc = '4';
                       
          }
      }
      
      let aux5 = await api.getInformesPorTipo(5);
      if (aux5.epi_doc != undefined && aux5.epi_doc != null && aux5.epi_doc.length > 0)
      {
          for ( i = 0; i < aux5.epi_doc.length; i++)
          {
              aux5.epi_doc[i].cla_int_doc = '5';
          }
      }

      console.log('resultados',resultados);

      let aux6 = await api.getPruebasRadiologia(9);

      if (aux6.epi_pru_com != undefined && aux6.epi_pru_com != null && aux6.epi_pru_com.length > 0)
      {
          for ( i = 0; i < aux6.epi_pru_com.length; i++)
          {
            if (aux6.epi_pru_com[i].epi_pru_com == '1') {
              aux6.epi_pru_com[i].cla_int_doc = '1';
            } else if  (aux6.epi_pru_com[i].epi_pru_com == '2') {
              aux6.epi_pru_com[i].cla_int_doc = '2';
            }
          }
          
      }

      if (resultados.status) {
          if (resultados.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }
      
   /*   if (aux1.status) {
          if (aux1.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }
      if (aux2.status) {
          if (aux2.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }*/
      if (aux3.status) {
          if (aux3.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }
      if (aux4.status) {
          if (aux4.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }
      if (aux5.status) {
          if (aux5.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
      }
      if ( resultados.epi_doc != undefined && resultados.epi_doc != null && resultados.epi_doc.length > 0)
      {
        resultados.epi_doc = resultados.epi_doc.reverse()
      }
     /* if ( aux1.epi_doc != undefined && aux1.epi_doc != null && aux1.epi_doc.length > 0)
      {
        aux1.epi_doc = aux1.epi_doc.reverse()
      }
      if ( aux2.epi_doc != undefined && aux2.epi_doc != null && aux2.epi_doc.length > 0)
      {
        aux2.epi_doc = aux2.epi_doc.reverse()
      }*/
      if ( aux3.epi_doc != undefined && aux3.epi_doc != null && aux3.epi_doc.length > 0)
      {
        aux3.epi_doc = aux3.epi_doc.reverse()
      }
      if ( aux4.epi_doc != undefined && aux4.epi_doc != null && aux4.epi_doc.length > 0)
      {
        aux4.epi_doc = aux4.epi_doc.reverse()
      }
      if ( aux5.epi_doc != undefined && aux5.epi_doc != null && aux5.epi_doc.length > 0)
      {
        aux5.epi_doc = aux5.epi_doc.reverse()
      }
      
      if ( aux6.epi_pru_com != undefined && aux6.epi_pru_com != null && aux6.epi_pru_com.length > 0)
      {
        aux6.epi_pru_com = aux6.epi_pru_com.reverse()

        aux6.epi_pru_com.forEach(element => {
           if (element.epi_pru_com == '1') {
              aux1.epi_doc.push(element)
            } else if  (element.epi_pru_com == '2') {
               aux2.epi_doc.push(element)
            }
        });

        aux2.epi_doc.sort( function( a, b ) {
          return a.fch > b.fch ? -1 : a.fch < b.fch ? 1 : (a.hor > b.hor ? -1 : a.hor < b.hor ? 1 : 0);
        })

        aux1.epi_doc.sort( function( a, b ) {
          return a.fch > b.fch ? -1 : a.fch < b.fch ? 1 : (a.hor > b.hor ? -1 : a.hor < b.hor ? 1 : 0);
        })
	
      }

      this.generico = resultados
      this.genericoTipo1 = aux1
      this.genericoTipo2 = aux2
      this.genericoTipo3 = aux3
      this.genericoTipo4 = aux4
      this.genericoTipo5 = aux5
      console.log('hhh', aux1);
      console.log('hhh2', aux2);
      this.rellenaLista(resultados)
      this.rellenaListaPrueba(aux1,'1')
      this.rellenaListaPrueba(aux2,'2')
      this.rellenaListaTipo(aux3,'3')
      this.rellenaListaTipo(aux4,'4')
      this.rellenaListaTipo(aux5,'5')
      this.loadingData = false
    }
  },

  methods: {
    ...mapActions({
      setAuth: 'setAuth'
    }),
    async traeEspecialides () {
      const resultados = await api.getEspecialidades2()
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      this.especialidadesMain = resultados.esp_ser
      this.especialidadesMain.sort( function( a, b ) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
      this.especialidades = this.especialidadesMain
    },

    rellenaLista (res) {
      this.lista = []
      res.epi_doc.forEach(element => {
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.push(fechaHora)
        if (element.cla_int_doc === '1') {
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          listadoElementos.push('Informes de consulta')
        } else if (element.cla_int_doc === '4') {
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }
        listadoElementos.push(element.nom_fic)
        listadoElementos.push(element.id)
        this.idDescarga = element.id
        this.lista.push(listadoElementos)
      })
    },

    rellenaListaTipo (res, tipo) {
      switch (tipo) {
          case '1':
            this.listaTipo1 = []
            break;
          case '2':
            this.listaTipo2 = []
            break;
          case '3':
            this.listaTipo3 = []
            break;
          case '4':
            this.listaTipo4 = []
            break;
          case '5':
            this.listaTipo5 = []
            break;
          default:
            break;
        }
     
      res.epi_doc.forEach(element => {
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.push(fechaHora)
       
        if (element.cla_int_doc === '1') {
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          listadoElementos.push('Informes de consulta')
        } else if (element.cla_int_doc === '4') {
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }

        listadoElementos.push(element.nom_fic)
        if (element.link !== undefined && element.link !== null)
        {
          listadoElementos.push('url_img:'+element.link)
        }
        else
        {
          listadoElementos.push(element.id)
        }
        this.idDescarga = element.id

        let criba =false;

        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }

        if (!criba)
        {
          switch (tipo) {
            case '1':
              this.listaTipo1.push(listadoElementos)
              break;
            case '2':
              this.listaTipo2.push(listadoElementos)
              break;
            case '3':
              this.listaTipo3.push(listadoElementos)
              break;
            case '4':
              this.listaTipo4.push(listadoElementos)
              break;
            case '5':
              this.listaTipo5.push(listadoElementos)
              break;
            default:
              break;
          }
        }
       
      })
    },
    
    changeToggle (indice, tipo)
    {
      const index = parseInt(indice)
       if (tipo === "1") {
        this.listaTipo1[index].toggledoc = !this.listaTipo1[index].toggledoc
       }
       if (tipo === "2") {
        this.listaTipo2[index].toggledoc = !this.listaTipo2[index].toggledoc
       }
    },

     rellenaListaPrueba (res, tipo) {
      switch (tipo) {
          case '1':
            this.listaTipo1 = []
            break;
          case '2':
            this.listaTipo2 = []
            break;
          default:
            break;
        }
     
      res.epi_doc.forEach(element => {
        let listadoElementos = {}
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.horadoc = fechaHora
       
        if (element.cla_int_doc === '1') {
          listadoElementos.tipodoc = 'Pruebas de laboratorio'
        } else if (element.cla_int_doc === '2') {
          listadoElementos.tipodoc = 'Pruebas de radiología'
        }
        else
        {
          listadoElementos.tipodoc = ''
        }

        listadoElementos.nomdoc = element.nom_fic
        listadoElementos.celdavacia = ''
        listadoElementos.toggledoc = false
        listadoElementos.docs = element.docs;
        
        this.idDescarga = element.id

        let criba =false;

        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }

        if (!criba)
        {
          switch (tipo) {
            case '1':
              this.listaTipo1.push(listadoElementos)
              break;
            case '2':
              this.listaTipo2.push(listadoElementos)
              break;
            default:
              break;
          }
        }
       
      })
    },
  
    parseaFechaHora (fch, hr) {
      let fecha = fch.split('T')
      fecha = fecha[0].split('-')
      let hora = hr.split(':')
      hora = hora[0] + ':' + hora[1]
      fecha = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
      const composicionRetorno = fecha + ' ' + hora
      return composicionRetorno
    },

    async limpiarBsq() {
      this.desde = ''
      this.hasta = ''
      this.filterInforme = ''
      this.traeEspecialides()
      if (this.user.have_his === 1) {
        const resultados = await api.getInformes2()
        if (resultados.status) {
          if (resultados.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
        }
        this.generico = resultados
        this.rellenaLista(resultados)
        this.limpia()
      }
    },

    bsq () {
      this.lista = []
      this.listaTipo1 = []
      this.listaTipo2 = []
      this.listaTipo3 = []
      this.listaTipo4 = []
      this.listaTipo5 = []

      this.generico.epi_doc.forEach((element) => {
        let criba = false
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.push(fechaHora)
        // listadoElementos.push('Cardiologia')
        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (element.cla_int_doc === '1') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de laboratorio') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de radiología') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Informe de consulta') {
              criba = true
            }
          }
          listadoElementos.push('Informe de consulta')
        } else if (element.cla_int_doc === '4') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Consentimientos informados') {
              criba = true
            }
          }
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Otros documentos no asistenciales') {
              criba = true
            }
          }
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }
        listadoElementos.push(element.nom_fic)
        listadoElementos.push(element.id)
        this.idDescarga = element.id
        
        if (!criba) {
          this.lista.push(listadoElementos)
        }
      })

      this.genericoTipo1.epi_doc.forEach((element) => {

        let criba = false

        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        // listadoElementos.push('Cardiologia')
        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (this.filterInforme !== '') {
          if (this.filterInforme !== 'Pruebas de laboratorio') {
            criba = true
          }

        }
        
        if (!criba) {

          let listadoElementos = {}
          listadoElementos.horadoc = fechaHora
          listadoElementos.tipodoc = 'Pruebas de laboratorio'
          listadoElementos.nomdoc = element.nom_fic
          listadoElementos.celdavacia = ''
          listadoElementos.toggledoc = false
          listadoElementos.docs = element.docs;
          this.idDescarga = element.id


          this.listaTipo1.push(listadoElementos)
        }
      })


      this.genericoTipo2.epi_doc.forEach((element) => {
        let criba = false
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        // listadoElementos.push('Cardiologia')
        if (element["epi_inf.anu"]  == 1)
        {
          criba = true;
        }
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (this.filterInforme !== '') {
          if (this.filterInforme !== 'Pruebas de radiología') {
            criba = true
          }
        }
        
        if (!criba) {
          let listadoElementos = {}
          listadoElementos.horadoc = fechaHora
          listadoElementos.tipodoc = 'Pruebas de radiología'
          listadoElementos.nomdoc = element.nom_fic
          listadoElementos.celdavacia = ''
          listadoElementos.toggledoc = false
          listadoElementos.docs = element.docs;
        
          this.idDescarga = element.id
          this.listaTipo2.push(listadoElementos)
        }
      })

      this.genericoTipo3.epi_doc.forEach((element) => {
        let criba = false
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.push(fechaHora)
        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }
        // listadoElementos.push('Cardiologia')
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (element.cla_int_doc === '1') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de laboratorio') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de radiología') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Informes de consulta') {
              criba = true
            }
          }
          listadoElementos.push('Informes de consulta')
        } else if (element.cla_int_doc === '4') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Consentimientos informados') {
              criba = true
            }
          }
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Otros documentos no asistenciales') {
              criba = true
            }
          }
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }
        listadoElementos.push(element.nom_fic)
        listadoElementos.push(element.id)
        this.idDescarga = element.id
        
        if (!criba) {
          this.listaTipo3.push(listadoElementos)
        }
      })

      this.genericoTipo4.epi_doc.forEach((element) => {
        let criba = false
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }
        listadoElementos.push(fechaHora)
        // listadoElementos.push('Cardiologia')
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (element.cla_int_doc === '1') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de laboratorio') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de radiología') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Informes de consulta') {
              criba = true
            }
          }
          listadoElementos.push('Informes de consulta')
        } else if (element.cla_int_doc === '4') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Consentimientos informados') {
              criba = true
            }
          }
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Otros documentos no asistenciales') {
              criba = true
            }
          }
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }
        listadoElementos.push(element.nom_fic)
        listadoElementos.push(element.id)
        this.idDescarga = element.id
        
        if (!criba) {
          this.listaTipo4.push(listadoElementos)
        }
      })

      this.genericoTipo5.epi_doc.forEach((element) => {
        let criba = false
        let listadoElementos = []
        const fechaHora = this.parseaFechaHora(element.fch, element.hor)
        listadoElementos.push(fechaHora)
        if (element["epi_inf.anu"]  == "1")
        {
          criba = true;
        }
        // listadoElementos.push('Cardiologia')
        const fechaCita = new Date(element.fch).getTime()
        if (this.desde !== '') {
          const fechaDesde = new Date(this.desde).getTime()
          if (fechaDesde > fechaCita) {
            criba = true
          }
        }
        if (this.hasta !== '') {
          const fechaHasta = new Date(this.hasta).getTime()
          if (fechaHasta < fechaCita) {
            criba = true
          }
        }
        if (element.cla_int_doc === '1') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de laboratorio') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de laboratorio')
        } else if (element.cla_int_doc === '2') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Pruebas de radiología') {
              criba = true
            }
          }
          listadoElementos.push('Pruebas de radiología')
        } else if (element.cla_int_doc === '3') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Informes de consulta') {
              criba = true
            }
          }
          listadoElementos.push('Informes de consulta')
        } else if (element.cla_int_doc === '4') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Consentimientos informados') {
              criba = true
            }
          }
          listadoElementos.push('Consentimientos informados')
        } else if (element.cla_int_doc === '5') {
          if (this.filterInforme !== '') {
            if (this.filterInforme !== 'Otros documentos no asistenciales') {
              criba = true
            }
          }
          listadoElementos.push('Otros documentos no asistenciales')
        }
        else
        {
          listadoElementos.push('')
        }
        listadoElementos.push(element.nom_fic)
        listadoElementos.push(element.id)
        this.idDescarga = element.id
        
        if (!criba) {
          this.listaTipo5.push(listadoElementos)
        }
      })

    },

    limpia () {
      this.filterInforme = ''
      console.log(this.genericoTipo2)
      this.rellenaLista(this.generico)
      this.rellenaListaPrueba(this.genericoTipo1,'1')
      this.rellenaListaPrueba(this.genericoTipo2,'2')
      this.rellenaListaTipo(this.genericoTipo3,'3')
      this.rellenaListaTipo(this.genericoTipo4,'4')
      this.rellenaListaTipo(this.genericoTipo5,'5')
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
}

.W90 {
  max-width: 90%;
}

@media (max-width: 768px) {
  .noMvl {
    display: none;
  }

  .mvlTop{
    margin-top: 50px !important;
  }
}

@media (min-width: 769px) {
  .noPc {
    display: none !important;
  }
}

.w95{
  width: 95%;
}

.password-container{
  max-width: 200px;
    width: 100%;
    margin: 0 auto;
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"]{
  width: 96%;
  /* padding: 12px 36px 12px 12px; */
  box-sizing: border-box;
}
.fa-caret-down{
  position: absolute;
  top: 28%;
  right: 7%;
  cursor: pointer;
  color: lightgray;
}

.w98{
  width: 98%;
}

.w100 {
  width: 100%;
}

.filterAbsoluteInfo {
    position: absolute;
    top: 50%;
    right: 5% !important;
    max-width: 270px;
}

.overflowAuto {
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

.f75p{
  font-size: 75%;
}

input {
  max-width: 100%;
}
/* @media (min-width: 769px) {
  .ptPc-5 {
    padding-top:3rem !important
  }
  
  .pbPc-5 {
    padding-bottom:3rem !important;
  }
} */
</style>
