<template>
  <div class="container mxW100">
    <div class="row">
        <div class="col-12 col-md-6 pl-0 text-center mt100x">
            <CuadroPerfiles />
        </div>
        <div class="col-12 col-md-6 mt-4">
            <!-- <formulario-usuario /> -->
            <formulario-usuario2 proviene="perfil" />
        </div>
    </div>
  </div>
</template>

<script>
// import FormularioUsuario from './FormularioUsuario.vue'
import FormularioUsuario2 from '../general/FormularioUsuario.vue'
import CuadroPerfiles from './CuadroPerfiles.vue'

export default {
  components: {
    // FormularioUsuario,
    CuadroPerfiles,
    FormularioUsuario2
  },

  methods: {
  }
 }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    width: 100% !important;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 60%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.pr28x{
    padding-right: 28px;
}

.p5x{
    padding:5px;
}

.mxW100{
    max-width: 100%;
}

.h38x{
    height: 38px;
}

.mxW38{
    width: 38%;
}

.mxW51{
    width: 51%;
}

@media (min-width: 768px) {
    .mt100x{
        margin-top:100px;
    }
}
</style>
