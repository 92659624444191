<template>
  <div class="row pb-5 noSectionMvl">
    <div class="col-12">
      <h4>Hola, {{ nombre }} ¿Qué tal estás?</h4>
    </div>
    <div class="col-12 mt-4">
      <div class="text-left d-flex">
        <div v-for="(item, index) in opcionesUser" :key="index" style="display:inline-block;">
          <h2 class="d-inline mr-5 p-2 pointer" :class="selectOpcion === item.name ? 'borderB' : ''" @click="redirect(item)">{{ item.name }}</h2>
          <div v-if="item.showLinks === 1" class="d-flex mt-4" style="position:absolute;">
            <div v-for="subLink, index in item.sublinks" :key="index" :class="index !== 0 ? 'bgColor p-2 ml-2 blanco hoverBtn' : 'bgColor p-2 blanco hoverBtn'" @click="redirect(subLink)">
              <div class="text-center"><img class="mxW25x" alt="Email" src="../../assets/calendario.png"></div>
              <span>{{ subLink.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },

  props: {
    selectOpcion: String
  },

  data: () => ({
    opcionesUser: [
      { name: 'Inicio', route:'/home', showLinks: 0},
      { name: 'Citas', route:'/', showLinks: 0, sublinks: [{name: 'Pedir cita', route:'/appoiments'}, {name: 'Gestión cita', route: '/manage-appoiments'}]},
      { name: 'Historial', route:'/', showLinks: 0, sublinks: [{name: 'Informes', route:'/inform-history'}]},
      { name: 'Analisis', route:'/analysis', showLinks: 0},
      { name: 'Perfil', route:'/profile', showLinks: 0},
      { name: 'Contacta', route:'/contact', showLinks: 0}
    ],
    ruta: '/home'
  }),

  computed: {
    ...mapGetters({
      nombre: 'nombre',
    })
  },

  methods: {
    ...mapActions({
      setRuta: 'setRuta'
    }),
    redirect (item) {
      this.ruta = item.route
      if (item.route !== '/') {
        this.$router.push(item.route)
        this.opcionesUser.forEach((item) => {
          item.showLinks = 0
        })
      } else {
        this.opcionesUser.forEach((item) => {
          item.showLinks = 0
        })
        item.showLinks = 1
      }
      this.setRuta(item.name)
    }
  }
}
</script>
<style scoped>
.borderB{
  border-bottom: 3px solid #2a4a5e;
}

.colorSeccion{
  color:#2a4a5e;
}

.noDecoration{
  text-decoration: none;
}

.bgColor{
  background: #2a4a5e;
}

.blanco{
  color:white;
}

.radiusBtn {
  border-radius: 5px;;
}

.hoverBtn:hover  {
  opacity: 0.7;
  cursor: pointer;
}

@media (max-width: 767px) {
  .noSectionMvl{
    display: none;
  }
}

.pointer {
  cursor: pointer;
}
</style>
