<template>
  <div class="container W90 pt-2 pb-5">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="row">
        <div class="col-12 mt-4 mb-5">
          <secciones selectOpcion="citas" />
        </div>
        <div class="col-12 mt-2 text-center mt-1 mb-2">
          <h4><i class="fa-solid fa-clipboard" id="eye"></i> Gestor de citas</h4>
        </div>
        <!-- <div class="col-12 text-center mb-4">
          <buscador-listados @buscador="filtroBuscador" />
        </div> -->
        <div class="col-12">
            <div class="row">
                <div class="col-6 text-center">
                    <div class="btn1 fRight pointer" :class="recetaNueva ? 'colorSelect' : 'colorNoSelect'" @click="recetaNueva=true">Mis próximas citas</div>
                </div>
                <div class="col-6 text-center">
                    <div class="btn1 pointer" :class="!recetaNueva ? 'colorSelect' : 'colorNoSelect'" @click="recetaNueva=false">Mis citas pasadas</div>
                </div>
            </div>
        </div>
        <div class="col-0 col-md-2" />

        <div class="col-12 col-md-10 mt-2 text-center mt-5 mb-1 noMvl" style="border-bottom:4px solid black;">
          <listado :listado="titulos" :columnas="100/4" bold="bold" />
        </div>
        <div class="col-12 col-md-2 noMvl">
          <div class="password-container mt-2">
            <input v-model="filterAnio" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'anio'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterEspecialidad.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'espe'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterDoctor.name" placeholder="Doctor" :disabled="filterEspecialidad == ''"  readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'doctor'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterCita" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="flex">
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="resetFilter" type="submit">Limpiar</button>
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqFilter" type="submit">Buscar</button>
          </div>
        </div>
        <div class="col-12 col-md-2 noPcFilter">
          <span @click="filtroMbl ? filtroMbl = false : filtroMbl = true"><i class="fa-solid fa-filter colorVirtus" id="eye"></i> Filtro</span>
          <div v-if="filtroMbl" class="password-container mt-2">
            <input v-model="filterAnio" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'anio'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div v-if="filtroMbl" class="password-container mt-2">
            <input v-model="filterEspecialidad.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'espe'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div v-if="filtroMbl" class="password-container mt-2">
            <input v-model="filterDoctor.name" placeholder="Doctor" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'doctor'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div v-if="filtroMbl" class="password-container mt-2">
            <input v-model="filterCita" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div v-if="filtroMbl" class="flex">
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="resetFilter" type="submit">Limpiar</button>
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqFilter" type="submit">Buscar</button>
          </div>
        </div>
        <div v-if="recetaNueva" class="col-12 col-md-10">
          <div v-for="(item, index) in citasFuturas" :key="index" class="col-12 mt-2 text-center mt-3 mb-3 p-0 mtPc-5 mbPc-5">
            <listado :listado="item" :columnas="100/4" :boton="esAnulada(item) ? undefined : 'Anular cita'" bgBtn="#BB0000" bgBtn2="#00BB2D" boton2="Cambiar cita" @refreshPasadas="obtienecitas" />
          </div>
        </div>
         <div v-if="!recetaNueva" class="col-12 col-md-10">
            <div v-for="(item, index) in citasAsistidas" :key="index" class="col-12 mt-2 text-center mt-3 mb-3 p-0 mtPc-5 mbPc-5">
              <listado :listado="item" :columnas="100/4" boton="Repetir cita" bgBtn="#00BB2D" />
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog filterAbsolute2" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="filter === 'anio'" class="modal-title" id="exampleModalLabel">Filtro de fecha</h5>
            <h5 v-if="filter === 'tipo'" class="modal-title" id="exampleModalLabel">Tipo de cita</h5>
            <h5 v-if="filter === 'espe'" class="modal-title" id="exampleModalLabel">Selecciona especialidad</h5>
            <h5 v-if="filter === 'doctor'" class="modal-title" id="exampleModalLabel">Selecciona doctor</h5>
            <!-- <h5 class="modal-title" id="exampleModalLabel">Filtro de fecha</h5> -->
          </div>
            <input v-if="filter === 'doctor' || filter === 'espe'" v-model="bsqEspecialidad" type="text" class="mAuto d-block" :placeholder="filter === 'espe' ? 'Busca la especialidad' : 'Busca por doctor'">
          <div class="modal-body">
            <div id="cierraModal" data-dismiss="modal"></div>
            <div class="row">
              <div class="col-10 text-center">
                <input v-if="filter === 'anio'" v-model="searchAno" type="number" min="0" placeholder="Otro año">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x" :class="filter === 'espe' || filter === 'doctor' ? 'overflowAuto' : ''">
                <div v-if="filter === 'anio'" class="row">
                  <div v-for="(ano, index) in aniosList" :key="index" class="col-4 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterAnio = ano">{{ ano }}</span>
                  </div>
                </div>
                <div v-if="filter === 'tipo'" class="row">
                  <div v-for="(cita, index) in citasList" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterCita = cita">{{ cita }}</span>
                  </div>
                </div>
                <div v-if="filter === 'espe'" class="row">
                  <div v-for="(espe, index) in especialidades" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterEspecialidad = espe">{{ espe.name }}</span>
                  </div>
                </div>
                <div v-if="filter === 'doctor'" class="row">
                  <div v-for="(profe, index) in listadoProfesionales" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterDoctor = profe">{{ profe.name }}</span>
                  </div>
                  <div v-if="listadoProfesionales.length === 0">
                    <span>No se han encontrado doctores asociados</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
// import BuscadorListados from '../components/general/BuscadorListados.vue'
import Listado from '../components/general/Listado.vue'
import Secciones from '../components/general/Secciones.vue'

export default {
  components: {
    Listado,
    // BuscadorListados,
    Secciones
  },

  data: () => ({
    recetaNueva: true,
    citas: [
      {tipo: 'Cardiología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'LUNES 6 NOV 18:30h'},
      {tipo: 'Psicología', doctor: 'Dra. Liliana Martinez Cancio', fecha: 'JUEVES 9 NOV 18:30h'}
    ],
    citasFuturasMain: [], 
    citasFuturas: [],
    citasAsistidasMain: [],
    citasAsistidas: [],
    titulos: ['Fecha y horario', 'Especialidad', 'Profesional sanitario', 'Tipo de cita'],
    anios: [],
    aniosList: [],
    citasList: ['Online', 'Videollamada', 'Télefono', 'Presencial'],
    filterAnio: '',
    filter: '',
    searchAno: '',
    filterEspecialidad: '',
    filterDoctor: '',
    especialidades: [],
    especialidadesMain: [],
    profesionalMain: [],
    listadoProfesionales: [],
    filterCita: '',
    bsqEspecialidad: '',
    filtroMbl: false
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      jsonModal: 'jsonModal'
    })
  },

  async mounted () {

    const ambitoThis = this

    this.keyPressHandler = function (event) {
      if (event.key === "Enter") {
          event.preventDefault()
          if (ambitoThis.tipoPide !== 'Profesional') {
            if (ambitoThis.especialidades.length === 1) {
              // ambitoThis.filterEspecialidad(ambitoThis.especialidades[0])
              ambitoThis.filterEspecialidad = ambitoThis.especialidades[0]
              const modal = document.getElementById('cierraModal');
              
              modal.click()
            }
          } else if (ambitoThis.tipoPide === 'Profesional') {
              ambitoThis.filterDoctor = ambitoThis.especialidades[0]
              // ambitoThis.selectEspe(ambitoThis.especialidades[0])
              const modal = document.getElementById('cierraModal');
              modal.click()
            }
      }
    };

    document.getElementsByTagName('body')[0].addEventListener("keypress", this.keyPressHandler);
    if (this.user.have_his === 1) {
      await this.obtienecitas()
      this.montacitasFuturas()
      this.montacitasPasadas()
    }

    const data = new Date()
    for (let i = data.getFullYear() -11; i < data.getFullYear() +1; i++) { this.anios.push(i) }
    this.aniosList = this.anios
    // this.traeProfesionales()
    this.traeEspecialides()
  },

  unmounted () {
    // var body = document.getElementsByTagName('body')[0];
    document.getElementsByTagName('body')[0].removeEventListener("keypress", this.keyPressHandler);
    // body.removeEventListener("keypress", keyPressHandler);
    // document.getElementsByTagName('body')[0].addEventListener("keypress", false)
  },

  watch: {

    async jsonModal () {
      if (this.jsonModal.type == undefined) {
        await this.obtienecitas()
        this.montacitasFuturas()
      }
    },

    searchAno () {
      if (this.searchAno.toString().length === 4) {
        this.aniosList = []
        this.aniosList.push(this.searchAno)
      } else {
        this.aniosList = this.anios
      }
    },

    filterEspecialidad () {
      if (this.filterEspecialidad !== '') {
        this.traeProfesionales()
      }
    },

    bsqEspecialidad () {
      this.bsqEspecialidad = this.bsqEspecialidad.toLowerCase()
      if (this.filter === 'espe') {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.especialidadesMain
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        } else {
          this.especialidades = []
          this.especialidadesMain.forEach((item) => {
            if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
          })
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        }
      } else {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.profesionalMain
          this.especialidades.forEach((profesional) => {
            this.listadoProfesionales.push(profesional.id)
          })
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        } else {
          this.listadoProfesionales = []
          this.profesionalMain.forEach((item) => {
          //   if (item.includes(this.bsqEspecialidad)) { 
          //     this.listadoProfesionales.push(item.id)
          //     return item
          //   }
            if (item !== undefined) {
              if (item.name !== undefined) {
                if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.listadoProfesionales.push(item) }
              }
            }
          })
          this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
      }
    },

    filter () {
      this.bsqEspecialidad = ''
      this.especialidades = this.especialidadesMain
      this.listadoProfesionales = this.profesionalMain
      // this.bsqEspecialidad()
    }
  },

  methods: {
    ...mapActions({
      setAuth: 'setAuth'
    }),
    async traeEspecialides () {
      // const resultados = await api.getEspecialidades()
      const resultados = await api.getEspecialidades2()

      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }

      this.especialidadesMain = resultados.esp_ser
      this.especialidadesMain.sort( function( a, b ) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
      this.especialidades = this.especialidadesMain
    },

    async traeProfesionales () {
      const resultados = await api.getListaProfesionales2(null, 1, this.filterEspecialidad['ser_prd.id'])

      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      resultados.count !== 0 ? this.profesionalMain = resultados.prf : this.profesionalMain = []
      this.listadoProfesionales = []
      resultados.prf.forEach((profesionales) => {
        this.listadoProfesionales.push({ id: profesionales.id, name: profesionales.name })
      })
    },

    async obtienecitas () {
      const resultado = await api.getcitasFuturas2(this.user.his)

      if (resultado.status) {
        if (resultado.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      resultado.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citasFuturasMain = resultado.cit
      const result2 = await api.getcitasPasadas2(this.user.his)

      if (result2.status) {
        if (result2.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      result2.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citasAsistidasMain = result2.cit
    },

    montacitasPasadas (filtro = null) {
      this.citasAsistidas = []
      //const listado = this.citasAsistidasMain.filter(item => item.est === 'T' || item.est === 'A' || item.est === 'C')
      const listado = this.citasAsistidasMain.filter(item => item.est === 'T')
      listado <= 4 ? this.citasAsistidasMain = listado.slice(1).slice(-10) : this.citasAsistidasMain = listado
      this.citasAsistidasMain?.forEach((cita) => {
        if (!filtro?.toString()) {
          this.citasAsistidas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'],  cita: this.estadoCita(cita['est']), tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
        }

        if (filtro?.toString()) {
          if (this.filtroTipo(cita, filtro) && this.filtroEspe(cita, filtro) && this.filtroFecha(cita, filtro)) {
            this.citasAsistidas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'],  cita: this.estadoCita(cita['est']), tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
          }
        }
      })
    },

    montacitasFuturas (filtro = null) {
      this.citasFuturas = []
      this.citasFuturasMain.forEach((cita) => {
        if (cita.est === 'C' && !filtro?.toString()) {
          this.citasFuturas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'], id: cita.id,  tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
        }

        if (cita.est === 'C' && filtro?.toString()) {
          if (this.filtroTipo(cita, filtro) && this.filtroEspe(cita, filtro) && this.filtroFecha(cita, filtro)) {
            this.citasFuturas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'], id: cita.id,  tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
          }
        }
      })
    },

    filtroTipo (cita, filtro) {
      let retorno = false
      if (filtro?.tipo === cita.cit_tip) {
        retorno = true
      } else if (!filtro?.tipo) {
        retorno = true
      }
      return retorno
    },

    filtroEspe (cita, filtro) {
      let retorno = false
      if (filtro?.especialidad === cita.cit_con) {
        retorno = true
      } else if (!filtro?.especialidad) {
        retorno = true
      }
      return retorno
    },

    filtroFecha (cita,filtro) {
      let retorno = false
      const dateCheck = cita.fch.split('T')
      const check = Date.parse(dateCheck[0])
      let from, to
      const dateFrom = filtro?.desde
      if (dateFrom?.toString()) { from = Date.parse(dateFrom) }      
      const dateTo = filtro?.hasta
      if (dateTo?.toString()) { to = Date.parse(dateTo) }

      if (to?.toString() && from?.toString()) {
        if(check <= to && check >= from) {
          retorno = true
        }
      } else if (to?.toString() && !from?.toString()) {
        if (check <= to) {
          retorno = true
        }
      } else if (!to?.toString() && from?.toString()) {
        if (check >= from) {
          retorno = true
        }
      } else if (!to?.toString() && !from?.toString()) {
        retorno = true        
      }
      return retorno
    },

    tipoCita (tipo) {
      let retorno
      if (tipo === 'P') {
        retorno = 'Presencial'        
      } else if (tipo === 'V') {
        retorno = 'Videoconferencia'
      } else if (tipo === 'T') {
        retorno = 'Telefónica'
      }
      return retorno
    },

    estadoCita (tipo) {
      let retorno = ''
      if (tipo === 'C'){
        retorno = 'Citado'
      } else if (tipo === 'S') {
        retorno = 'Sala de espera'
      } else if (tipo === 'E') {
        retorno = 'En consulta'
      } else if (tipo === 'T') {
        retorno = 'Terminada'
      } else if (tipo === 'N') {
        retorno = 'No asiste'
      } else if (tipo === 'I') {
        retorno = 'Información'
      } else if (tipo === 'L') {
        retorno = 'Libre'
      } else if (tipo === 'A') {
        retorno = 'Anulada'
      } else if (tipo === 'F') {
        retorno = 'Confirmada'
      } else if (tipo === 'R') {
        retorno = 'Reasignada'
      } else if (tipo === 'U') {
        retorno = 'Citada urgente'
      }
      return retorno
    },

    nombreSemana (fecha) {
      const weekday = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sábado"];
      const d = new Date(fecha);
      let day = weekday[d.getDay()];
      return day
    },

    nombreMes (mes) {
      const mesesNombre = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      return mesesNombre[parseInt(mes - 1)]
    },

    estructuraFecha (fecha, hora) {
      let compoHora = hora.split(':')
      const formatoHora = compoHora[0] + ':' + compoHora[1]
      let compoFecha = fecha.split('T')
      compoFecha = compoFecha[0]
      let formatoFecha = compoFecha.split('-')
      return  formatoFecha[2] + ' ' + this.nombreMes(formatoFecha[1]).toUpperCase() + ' ' +  this.nombreSemana(compoFecha).toUpperCase() + ' ' + formatoHora
    },

    esAnulada (item) {
      let retorno
      item.cita === 'Anulada' ? retorno = true : retorno = false
      return retorno
    },

    filtroBuscador (value) {
      if (this.recetaNueva) {
        this.montacitasFuturas(value)
      } else {
        this.montacitasPasadas(value)
      }
    },

    resetFilter () {
      this.filterAnio = ''
      this.filterEspecialidad = ''
      this.filterDoctor = ''
      this.filterCita = ''
      this.bsqFilter()
    },

    bsqFilter () {
      if (this.recetaNueva) {
        this.filterFuturas()
        this.montacitasPasadas()
      } else {
        this.filterPasadas()
        this.montacitasFuturas()
      }
    },

    filterFuturas () {
      this.citasFuturas = []
      this.citasFuturasMain.forEach((cita) => {
        let criba = false
        if (this.filterAnio !== '') {
          if (!cita.fch.includes(this.filterAnio)) { criba = true }
        }
        if (this.filterEspecialidad !== '') {
          if (cita['prf.ser_prd.name'] !== this.filterEspecialidad.name) { criba = true }
        }
        if (this.filterDoctor !== '') {
          if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
        }
        if (this.filterCita !== '') {
          let tipo = ''
          if (this.filterCita === 'Télefono') { tipo = 'T' }
          if (this.filterCita === 'Videollamada') { tipo = 'V' }
          if (this.filterCita === 'Online') { tipo = 'O' }
          if (this.filterCita === 'Presencial') { tipo = 'P' }
          if (cita['cit_tip'] !== tipo) { criba = true }
        }

        if (!criba) {
          if (cita.est === 'C') {
            this.citasFuturas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'], id: cita.id,  tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
          }
        }
      })
    },

    filterPasadas () {
      this.citasAsistidas = []
      this.citasAsistidasMain.forEach((cita) => {
        let criba = false
        if (this.filterAnio !== '') {
          if (!cita.fch.includes(this.filterAnio)) { criba = true }
        }
        if (this.filterEspecialidad !== '') {
          if (cita['prf.ser_prd.name'] !== this.filterEspecialidad.name) { criba = true }
        }
        if (this.filterDoctor !== '') {
          if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
        }
        if (this.filterCita !== '') {
          let tipo = ''
          if (this.filterCita === 'Télefono') { tipo = 'T' }
          if (this.filterCita === 'Videollamada') { tipo = 'V' }
          if (this.filterCita === 'Online') { tipo = 'O' }
          if (this.filterCita === 'Presencial') { tipo = 'P' }
          if (cita['cit_tip'] !== tipo) { criba = true }
        }

        if (!criba) {
          this.citasAsistidas.push({ fecha: this.estructuraFecha(cita.fch, cita.hora), tipo: cita['cit_con.name'], doctor: cita['prf.name'],  cita: this.estadoCita(cita['est']), tipoCita: this.tipoCita(cita.cit_tip), itemListado: cita })
        }
      })
    },

    // filterFuturas () {
    //   this. = []
    //   this.citasFuturasMain.forEach((cita) => {
    //     let criba = false
    //     if (this.filterAnio !== '') {
    //       if (!cita.fch.includes(this.filterAnio)) { criba = true }
    //     }
    //     if (this.filterDoctor !== '') {
    //       if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
    //     }
    //     if (this.filterCita !== '') {
    //       let tipo = ''
    //       if (this.filterCita === 'Télefono') { tipo = 'T' }
    //       if (this.filterCita === 'Videollamada') { tipo = 'V' }
    //       if (this.filterCita === 'Online') { tipo = 'O' }
    //       if (this.filterCita === 'Presencial') { tipo = 'P' }
    //       if (cita['cit_tip'] !== tipo) { criba = true }
    //     }

    //     if (!criba) {
    //       this.citasFuturas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
    //     }
    //   })
    // }
  }
}
</script>
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}

.W90 {
  max-width: 90%;
}

input {
  max-width: 100%;
}

.btn1{
    border: none;
    color: white;
    padding: 5px 0px;
    width: 60%;
    /* min-width: 120px; */
}

.colorSelect{
    background: #2a4a5e !important;
    color: #fff !important;
}

.colorNoSelect{
    background: #fff !important;
    border: 1px solid #2a4a5e !important;
    color: #2a4a5e !important;
}

.fRight{
    float:right;
}

@media (max-width: 768px) {
  .noMvl {
    display: none;
  }

  .btn1{
    border: none;
    color: white;
    padding: 5px 0px;
    /* width: 60%; */
    min-width: 120px;
  }

  .fa-caret-down{
    right: 20% !important;
  }
}

@media (min-width: 769px) {
  .mtPc-5 {
    margin-top:3rem !important
  }
  
  .mbPc-5 {
    margin-bottom:3rem !important;
  }

  .noPcFilter {
    display: none;
  }
}

.password-container{
    width: 100%;
  max-width: 200px;
    margin: 0 auto;
  position: relative;
  text-align: center;
}
.password-container input[type="password"],
.password-container input[type="text"]{
  width: 96%;
  /* padding: 12px 36px 12px 12px; */
  box-sizing: border-box;
  /* text-align: center; */
}
.fa-caret-down{
  position: absolute;
  top: 28%;
  right: 10%;
  cursor: pointer;
  color: lightgray;
}

.flex {
  display: flex;
}

.filterAbsolute2{
  position: absolute;
  top: 50%;
  left:5% !important;
  max-width: 270px;
}

.w40 {
  width: 40%;
}

.overflowAuto {
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}
</style>
