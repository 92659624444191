<template>
  <div class="foot">
    <div class="separa">
        <div class="container h100">
            <div class="row align-items-center">
                <div class="col-sm-12 text-center">Política de privacidad - Consejos de seguridad</div>
                <div class="col-sm-12 text-center">{{ new Date().getFullYear() }} Virtus</div>
                <div class="col-sm-12 text-center">Todos los derechos reservados</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.foot {
    /* height: 10vh; */
    width: 100%;
    background: #fff;
    /* position: absolute;
    bottom:5vh */
}

.row{
  height:100%;
  /* padding:2%; */
}


.separa{
    height: 100%;
    width: 100%;
    background: #2a4a5e;
    color:white;
    /* font-size:13px; */
}

.h100{
  height:100%;
}
</style>
