<template>
  <div class="container W90">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="row m-0">
        <div class="col-12 mt-4 mb-5 nvMvl">
          <secciones selectOpcion="Inicio" />
        </div>
        <!-- <div v-if="citasFuturas.length !== 0" class="col-12 mt-4 text-left mt-5 pl-0">
          <h4>Próximas citas</h4>
        </div>
        <div v-if="citasFuturas.length !== 0" :class="citasFuturas.length === 1 ? 'col-12' : 'col-12 col-md-6'" :class="index !== 1 ? '' : 'mt5rem'" v-for="(cita, index) in citasFuturas" :key="index">
          <cuadro-citas :cita="cita" :class="index%2 !== 0 ? 'mrlPc' : ''"/>
        </div> -->

        <!-- <div v-if="citasNoAsistidas.length !== 0" :class="citasFuturas.length === 1 ? 'col-12' : 'col-12 col-md-10'" class="col-12 col-md-6 mt-5 text-left pl-0"> -->
        <div class="col-12 col-md-10 mt-5 text-left pl-0">
          <h4>Próximas citas <span v-if="showFuturas" @click="showFuturas = false" class="pointer">▾</span><span v-if="!showFuturas" @click="showFuturas = true" class="pointer">◂</span></h4>
          <div v-if="showFuturas" class="noPcFilter">
            <span @click="filtroProxcitas ? filtroProxcitas = false : filtroProxcitas = true"><i class="fa-solid fa-filter colorVirtus" id="eye" @click="muestraPass"></i> Filtro</span>
            <div v-if="filtroProxcitas" class="password-container mt-2 text-center">
              <input v-model="filterAnioPro" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal1" @click="filter = 'anio'" class="pointer">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <div v-if="filtroProxcitas" class="password-container mt-2 text-center">
              <input v-model="filterEspecialidadPro.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal1" @click="filter = 'espe'" class="pointer">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <div v-if="filtroProxcitas" class="password-container mt-2 text-center">
              <input v-model="filterDoctorPro.name" placeholder="Doctor" readonly  data-toggle="modal" data-target="#exampleModal1" @click="filter = 'doctor'" class="pointer">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <div v-if="filtroProxcitas" class="password-container mt-2 text-center">
              <input v-model="filterCitaPro" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal1" @click="filter = 'tipo'" class="pointer">
              <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
            </div>
            <div v-if="filtroProxcitas" class="flex">
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqProx" type="submit">Buscar</button>
              <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="resetProx" type="submit">Limpiar</button>
            </div>
          </div>
          <div v-if="showFuturas">
            <div class="col-12 col-md-5 mt-2 pr-0 borderCaja ml-1 dInlineBlock" v-for="(cita, index) in citasFuturas" :key="index">
              <cuadro-citas :cita="cita" @refresh="refresh" @sacoPop="sacoPop" />
            </div>
            <div v-if="citasFuturas.length === 0" class="col-12 mt-4">
                <span>No hay citas</span>
              </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-6 mt-5 text-left pl-0">
          <h4>Favoritos</h4>
            <div class="col-12 pr-0 mt-2" v-for="(cita, index) in citas" :key="index">
              <cuadro-citas-op2 :cita="cita" asistido="true" />
            </div>
        </div> -->
        <!-- <div v-if="citasNoAsistidas.length !== 0" :class="citasNoAsistidas.length === 1 ? 'col-12 col-md-10' : 'col-12 col-md-10'" class="col-12 mt-5 text-left pl-0"> -->
        <div class="col-0 col-md-2 mt-5 text-center noMvl">
          <div class="password-container mt-2">
            <input v-model="filterAnio" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal3" @click="filter = 'anio'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterEspecialidad.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal3" @click="filter = 'espe'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterDoctor.name" placeholder="Doctor" readonly  :disabled="filterEspecialidad == ''"  data-toggle="modal" data-target="#exampleModal3" @click="filter = 'doctor'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="password-container mt-2">
            <input v-model="filterCita" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal3" @click="filter = 'tipo'" class="pointer">
            <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
          </div>
          <div class="flex">
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqFiltro" type="submit">Buscar</button>
            <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="limpFiltro" type="submit">Limpiar</button>
          </div>
        </div>
          <div class="col-12 col-md-10 mt-5 text-left pl-0">
          <h4>Citas a las que no he asistido <span v-if="showPasadas" @click="showPasadas = false" class="pointer">▾</span><span v-if="!showPasadas" @click="showPasadas = true" class="pointer">◂</span></h4>
            <div v-if="showPasadas" class="noPcFilter">
              <span @click="filtroNoAsis ? filtroNoAsis = false : filtroNoAsis = true"><i class="fa-solid fa-filter colorVirtus" id="eye" @click="muestraPass"></i> Filtro</span>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterAnioPasa" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'anio'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterEspecialidadPasa.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'espe'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterDoctorPasa.name" placeholder="Doctor" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'doctor'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterCitaPasa" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="flex">
                <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqProxPa" type="submit">Buscar</button>
                <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="resetPa" type="submit">Limpiar</button>
              </div>
            </div>
          <!-- <span>◂</span> -->
          <!-- <div class="row"> -->
            <div v-if="showPasadas">
              <div class="col-12 col-md-5 mt-2 pr-0 ml-1 dInlineBlock pl-0" v-for="(cita, index) in citasNoAsistidas" :key="index">
                <cuadro-citas-op2 :cita="cita" />
              </div>
              <div v-if="citasNoAsistidas.length === 0" class="col-12 mt-4">
                <span>No hay citas</span>
              </div>
            </div>
          <!-- </div> -->
        </div>

        <div class="col-12 col-md-10 mt-5 text-left pl-0">
          <h4>Citas anuladas <span v-if="showAnuladas" @click="showAnuladas = false" class="pointer">▾</span><span v-if="!showAnuladas" @click="showAnuladas = true" class="pointer">◂</span></h4>
            <div v-if="showAnuladas" class="noPcFilter">
              <span @click="filtroNoAsis ? filtroNoAsis = false : filtroNoAsis = true"><i class="fa-solid fa-filter colorVirtus" id="eye" @click="muestraPass"></i> Filtro</span>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterAnioPasa" placeholder="Año" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'anio'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterEspecialidadPasa.name" placeholder="Especialidad" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'espe'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterDoctorPasa.name" placeholder="Doctor" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'doctor'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="password-container mt-2 text-center">
                <input v-model="filterCitaPasa" placeholder="Tipo cita" readonly  data-toggle="modal" data-target="#exampleModal2" @click="filter = 'tipo'" class="pointer">
                <i class="fa-solid fa-caret-down" id="eye" @click="muestraPass"></i>
              </div>
              <div v-if="filtroNoAsis" class="flex">
                <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="bsqProxPa" type="submit">Buscar</button>
                <button class="btn1 w40 mAuto mt-2" id="inicioSesion" @click="resetPa" type="submit">Limpiar</button>
              </div>
            </div>
          <!-- <span>◂</span> -->
          <!-- <div class="row"> -->
            <div v-if="showAnuladas">
              <div class="col-12 col-md-5 mt-2 pr-0 ml-1 dInlineBlock pl-0" v-for="(cita, index) in citasAnuladas" :key="index">
                <cuadro-citas-anuladas :cita="cita" />
              </div>
              <div v-if="citasAnuladas.length === 0" class="col-12 mt-4">
                <span>No hay citas</span>
              </div>
            </div>
          <!-- </div> -->
        </div>
    </div>
    <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog filterAbsolute" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="filter === 'anio'" class="modal-title" id="exampleModalLabel">Filtro de fecha</h5>
            <h5 v-if="filter === 'tipo'" class="modal-title" id="exampleModalLabel">Tipo de cita</h5>
            <h5 v-if="filter === 'espe'" class="modal-title" id="exampleModalLabel">Selecciona especialidad</h5>
            <h5 v-if="filter === 'doctor'" class="modal-title" id="exampleModalLabel">Selecciona doctor</h5>
          </div>
            <input v-if="filter === 'doctor' || filter === 'espe'" v-model="bsqEspecialidad" type="text" class="mAuto d-block" :placeholder="filter === 'espe' ? 'Busca la especialidad' : 'Busca por doctor'">
          <div class="modal-body">
            <div class="row">
              <div class="col-10 text-center">
                <input v-if="filter === 'anio'" v-model="searchAno" type="number" min="0" placeholder="Otro año">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x" :class="filter === 'espe' || filter === 'doctor' ? 'overflowAuto' : ''">
                <div v-if="filter === 'anio'" class="row">
                  <div v-for="(ano, index) in aniosList" :key="index" class="col-4 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterAnio = ano">{{ ano }}</span>
                  </div>
                </div>
                <div v-if="filter === 'tipo'" class="row">
                  <div v-for="(cita, index) in citasList" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterCita = cita">{{ cita }}</span>
                  </div>
                </div>
                <div v-if="filter === 'espe'" class="row">
                  <div v-for="(espe, index) in especialidades" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterEspecialidad = espe">{{ espe.name }}</span>
                  </div>
                </div>
                <div v-if="filter === 'doctor'" class="row">
                  <div v-for="(profe, index) in listadoProfesionales" :key="index" class="col-12 mb-3">
                    <span v-if="profe !== undefined" class="pointer" data-dismiss="modal" @click="filterDoctor = profe">{{ profe.name }}</span>
                  </div>
                  <div v-if="listadoProfesionales.length === 0">
                    <span>No se han encontrado doctores asociados</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog filterAbsolute" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="filter === 'anio'" class="modal-title" id="exampleModalLabel">Filtro de fecha</h5>
            <h5 v-if="filter === 'tipo'" class="modal-title" id="exampleModalLabel">Tipo de cita</h5>
            <h5 v-if="filter === 'espe'" class="modal-title" id="exampleModalLabel">Selecciona especialidad</h5>
            <h5 v-if="filter === 'doctor'" class="modal-title" id="exampleModalLabel">Selecciona doctor</h5>
          </div>
            <input v-if="filter === 'doctor' || filter === 'espe'" v-model="bsqEspecialidad" type="text" class="mAuto d-block" :placeholder="filter === 'espe' ? 'Busca la especialidad' : 'Busca por doctor'">
          <div class="modal-body">
            <div class="row">
              <div class="col-10 text-center">
                <input v-if="filter === 'anio'" v-model="searchAno" type="number" min="0" placeholder="Otro año">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x" :class="filter === 'espe' || filter === 'doctor' ? 'overflowAuto' : ''">
                <div v-if="filter === 'anio'" class="row">
                  <div v-for="(ano, index) in aniosList" :key="index" class="col-4 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterAnioPro = ano">{{ ano }}</span>
                  </div>
                </div>
                <div v-if="filter === 'tipo'" class="row">
                  <div v-for="(cita, index) in citasList" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterCitaPro = cita">{{ cita }}</span>
                  </div>
                </div>
                <div v-if="filter === 'espe'" class="row">
                  <div v-for="(espe, index) in especialidades" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterEspecialidadPro = espe">{{ espe.name }}</span>
                  </div>
                </div>
                <div v-if="filter === 'doctor'" class="row">
                  <div v-for="(profe, index) in listadoProfesionales" :key="index" class="col-12 mb-3">
                    <span v-if="profe !== undefined" class="pointer" data-dismiss="modal" @click="filterDoctorPro = profe">{{ profe.name }}</span>
                  </div>
                  <div v-if="listadoProfesionales.length === 0">
                    <span>No se han encontrado doctores asociados</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="muestroPop" data-toggle="modal" data-target="#exampleModal5"></div>
    <div v-if="citaPop !== ''" class="modal fade" id="exampleModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <div id="muestroPopClose" data-dismiss="modal" class="pointer">X</div>
            </div>
            <div class="modal-body">
              <div id="cierraModal" data-dismiss="modal"></div>
                <div class="W100 text-center">
                    <b class="d-block">Consulta {{ tipoCita(citaPop.cita['cit_tip']) }} de {{ citaPop.cita['prf.ser_prd.name'] }}</b>
                </div>
                <div class="W100 text-center">
                    <span>{{ citaPop.cita['prf.name'] }}</span>
                </div>
                <div class="linea"></div>
                <div class="W100 text-center mt-4">
                    <div class="mxW100x mAuto">
                        {{ citaPop.fecha }}
                    </div>
                </div>
                <div class="W80 mAuto mt-3">
                    <div class="p-1 bgAdv">
                        <!--Es importante que llegue puntual a su cita, recuerde traer mascarilla e informar en caso de no poder asistir-->
                        Es importante que llegue puntual a su cita, por favor recuerde informar en caso de no poder asistir
                    </div>
                </div>
                <div class="W80 mAuto mt-3 text-center">
                    <button class="btn2" data-toggle="modal" data-target="#exampleModal4" @click="anulaCita()">Anular cita</button>
                    <button class="btn5" data-toggle="modal" data-target="#exampleModal4" @click="cambiarCita()">Cambiar cita</button>
                </div>
                <div id="cierraModal2" data-dismiss="modal"></div>
            </div>
        </div>
    </div>
    </div>
    <div id="anulacionCorrectaMsg" data-toggle="modal" data-target="#anulacionCorrecta"></div>
    <div class="modal fade" id="anulacionCorrecta" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background: #99e4ab;font-weight: bold;">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Selecciona la especialidad</h5> -->
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-10 mAuto mt-3 mxH200x text-center">
                ✅
                <br>
                Tu cita ha sido anulada con éxito
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog filterAbsolute" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="filter === 'anio'" class="modal-title" id="exampleModalLabel">Filtro de fecha</h5>
            <h5 v-if="filter === 'tipo'" class="modal-title" id="exampleModalLabel">Tipo de cita</h5>
            <h5 v-if="filter === 'espe'" class="modal-title" id="exampleModalLabel">Selecciona especialidad</h5>
            <h5 v-if="filter === 'doctor'" class="modal-title" id="exampleModalLabel">Selecciona doctor</h5>
          </div>
            <input v-if="filter === 'doctor' || filter === 'espe'" v-model="bsqEspecialidad" type="text" class="mAuto d-block" :placeholder="filter === 'espe' ? 'Busca la especialidad' : 'Busca por doctor'">
          <div class="modal-body">
            <div class="row">
              <div class="col-10 text-center">
                <input v-if="filter === 'anio'" v-model="searchAno" type="number" min="0" placeholder="Otro año">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x" :class="filter === 'espe' || filter === 'doctor' ? 'overflowAuto' : ''">
                <div v-if="filter === 'anio'" class="row">
                  <div v-for="(ano, index) in aniosList" :key="index" class="col-4 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterAnioPasa = ano">{{ ano }}</span>
                  </div>
                </div>
                <div v-if="filter === 'tipo'" class="row">
                  <div v-for="(cita, index) in citasList" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterCitaPasa = cita">{{ cita }}</span>
                  </div>
                </div>
                <div v-if="filter === 'espe'" class="row">
                  <div v-for="(espe, index) in especialidades" :key="index" class="col-12 mb-3">
                    <span class="pointer" data-dismiss="modal" @click="filterEspecialidadPa = espe">{{ espe.name }}</span>
                  </div>
                </div>
                <div v-if="filter === 'doctor'" class="row">
                  <div v-for="(profe, index) in listadoProfesionales" :key="index" class="col-12 mb-3">
                    <span v-if="profe !== undefined" class="pointer" data-dismiss="modal" @click="filterDoctorPasa = profe">{{ profe.name }}</span>
                  </div>
                  <div v-if="listadoProfesionales.length === 0">
                    <span>No se han encontrado doctores asociados</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
// import api from '@/services/api'
import Secciones from '../general/Secciones.vue'
import CuadroCitas from './CuadroCitas.vue'
import CuadroCitasOp2 from './CuadroCitasOp2.vue'
import CuadroCitasAnuladas from './CuadroCitasAnuladas.vue'

export default {
  components: {
    Secciones,
    CuadroCitas,
    CuadroCitasOp2,
    CuadroCitasAnuladas
  },

  data: () => ({
    citas: [],
    citasFuturas: [],
    citasNoAsistidasMain: [],
    citasAnuladasMain: [],
    citasNoAsistidas: [],
    citasAnuladas: [],
    showPasadas: true,
    showFuturas: true,
    showAnuladas: true,
    filtroProxcitas: false,
    filtroNoAsis: false,
    filtroAnuladas: false,
    anios: [],
    aniosList: [],
    citasList: ['Online', 'Videollamada', 'Télefono', 'Presencial'],
    filter: '',
    searchAno: '',
    filterAnio: '',
    filterEspecialidad: '',
    filterDoctor: '',
    filterCita: '',
    filterAnioPro: '',
    filterEspecialidadPro: '',
    filterDoctorPro: '',
    filterCitaPro: '',
    filterAnioPasa: '',
    filterEspecialidadPasa: '',
    filterDoctorPasa: '',
    filterCitaPasa: '',
    citaPop: '',
    especialidades: [],
    especialidadesMain: [],
    profesionalMain: [],
    listadoProfesionales: [],
    bsqEspecialidad: '',
    limite : 8
  }),

  async mounted () {
    console.log('aaa mounted',this.user);
    if (this.user.have_his === 1) {
      await this.obtienecitas()
      this.proximascitas()
      this.citasPasadas()
      this.getCitasAnuladas();
    }
    const data = new Date()
    for (let i = data.getFullYear() -11; i < data.getFullYear() +1; i++) { this.anios.push(i) }
    // console.log(this.user)
    this.aniosList = this.anios
    // this.traeProfesionales()
    this.traeEspecialides()
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    async user () {
      if (this.user.have_his === 1) {
        await this.obtienecitas()
        this.proximascitas()
        this.citasPasadas()
      }
      const data = new Date()
      for (let i = data.getFullYear() -11; i < data.getFullYear() +1; i++) { this.anios.push(i) }
      // console.log(this.user)
      this.aniosList = this.anios
      // this.traeProfesionales()
      this.traeEspecialides()
    },

    searchAno () {
      if (this.searchAno.toString().length === 4) {
        this.aniosList = []
        this.aniosList.push(this.searchAno)
      } else {
        this.aniosList = this.anios
      }
    },

    filterEspecialidad () {
      if (this.filterEspecialidad !== '') {
        this.traeProfesionales()
      }
    },

    bsqEspecialidad () {
      // if (this.bsqEspecialidad !== '') {
        this.bsqEspecialidad = this.bsqEspecialidad.toLowerCase()
        // console.log('Llegooo??')
        // console.log(this.bsqEspecialidad)
        // console.log(this.filterEspecialidad)
        // console.log(this.bsqEspecialidad)
        if (this.filter === 'espe') {
          if (this.bsqEspecialidad === '') {
            this.especialidades = this.especialidadesMain
            this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
          } else {
            this.especialidades = []
            this.especialidadesMain.forEach((item) => {
              if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
            })
            this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
          }
        } else {
          if (this.bsqEspecialidad === '') {
            this.listadoProfesionales = this.profesionalMain
            // this.listadoProfesionales.forEach((profesional) => {
            //   this.listadoProfesionales.push(profesional)
            // })
            this.listadoProfesionales.sort( function( a, b ) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            })
          } else {
            this.listadoProfesionales = []
            this.profesionalMain.forEach((item) => {
              if (item !== undefined) {
                if (item.name !== undefined) {
                  if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.listadoProfesionales.push(item) }
                }
              }
              // if (item.includes(this.bsqEspecialidad)) { 
              //   this.listadoProfesionales.push(item.id)
              //   return item
              // }
            })
            this.listadoProfesionales.sort( function( a, b ) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            })
          }
        }
      // }
    },

    filter () {
      this.bsqEspecialidad = ''
      this.especialidades = this.especialidadesMain
      this.listadoProfesionales = this.profesionalMain
      // this.bsqEspecialidad()
    }
  },

  methods: {
    ...mapActions({
      setAuth: 'setAuth',
      setCita: 'setCita'
    }),

    tipoCita (tipo) {
        let retorno = ''
        if (tipo == 'V') {
            retorno = 'videoconferencia'
        } else if (tipo == 'T') {
            retorno = 'telefónica'
        } else if (tipo == 'P') {
            retorno = 'presencial'
        }
        return retorno
    },

    cambiarCita () {
        // console.log(this.cita)
        // this.$emit('sacoPop', this.cita) 
        const modal = document.getElementById('cierraModal2');
        modal.click()
        this.setCita(this.citaPop.cita)
        this.$router.push('/appoiments')
    },

    async anulaCita () {

    
        await api.getAnulaCita2(this.citaPop.cita.id)

        this.citaPop = '';
        document.getElementById('muestroPopClose').click()

        setTimeout(() => {
          document.getElementById('anulacionCorrectaMsg').click()
        }, "1000")

        if (this.user.have_his === 1) {
          await this.obtienecitas()
          this.proximascitas()
          this.citasPasadas()
        }
    },

    // async refresh () {
    //   if (this.user.have_his === 1) {
    //     await this.obtienecitas()
    //     this.proximascitas()
    //     this.citasPasadas()
    //   }
    // },

    sacoPop (value) {
      this.citaPop = value
      setTimeout(() => {
        document.getElementById('muestroPop').click()
      }, "1000")
    },

    async obtienecitas () {
      const resultado = await api.getcitasFuturas2(this.user.his)
      if (resultado.status) {
        if (resultado.status == 'ERROR') {
          this.setAuth(false)
          this.$router.push('/')
          return
        }
      }
      resultado.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citas = resultado.cit
      const result2 = await api.getcitasPasadas2(this.user.his)
      if (result2.status) {
        if (result2.status == 'ERROR') {
          this.setAuth(false)
          this.$router.push('/')
          return
        }
      }
      result2.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citasNoAsistidasMain = result2
      /*
      console.log('fff',  this.citasNoAsistidasMain)
      const result3 = await api.getcitasAnuladas2(this.user.his);


      if (result3.status) {
        if (result3.status == 'ERROR') {
          this.setAuth(false)
          this.$router.push('/')
          return
        }
      }
      result3.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citasAnuladasMain = result3
      console.log('fff', this.citasAnuladasMain)
      */

      this.citasAnuladasMain = this.citas
    },

    async traeEspecialides () {
      // const resultados = await api.getEspecialidades()
      const resultados = await api.getEspecialidades2()
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
          this.setAuth(false)
          this.$router.push('/')
          return
        }
      }

      this.especialidadesMain = resultados.esp_ser
      this.especialidadesMain.sort( function( a, b ) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
      this.especialidades = this.especialidadesMain
    },

    async traeProfesionales () {
      const resultados = await api.getListaProfesionales2(null, 1, this.filterEspecialidad['ser_prd.id'])
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
          this.setAuth(false)
          this.$router.push('/')
          return
        }
      }
      resultados.count !== 0 ? this.profesionalMain = resultados.prf : this.profesionalMain = []
      this.listadoProfesionales = []
      resultados.prf.forEach((profesionales) => {
        this.listadoProfesionales.push({ id: profesionales.id, name: profesionales.name })
      })
    },

    proximascitas () {
      this.citasFuturas = []
      this.citas.forEach((cita) => {
        if (cita.est === 'C') {
          this.citasFuturas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora), cita: cita })
        }
	if (cita.est === 'F') {
          this.citasFuturas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora), cita: cita })
        }
      })
    },

    citasPasadas () {
      this.citasNoAsistidas = []
      if (this.citasNoAsistidasMain != undefined && this.citasNoAsistidasMain != null && this.citasNoAsistidasMain.cit != undefined &&  this.citasNoAsistidasMain.cit != null)
      {
        let listado = this.citasNoAsistidasMain.cit.filter(item => item.est && item.est === 'N')
        listado = listado.reverse()
        listado <= 4 ? this.citasNoAsistidasMain = listado.slice(1).slice(-3) : this.citasNoAsistidasMain = listado
        this.citasNoAsistidasMain.forEach((cita) => {
          if (this.limite == 0 || this.limite > this.citasNoAsistidas.length)
          {
            this.citasNoAsistidas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora), itemListado: cita })
          }
        })
      }
    },

    getCitasAnuladas () {
      this.citasAnuladas = []
      
     /* const listado = this.citasAnuladasMain.cit.filter(item => item.est && item.est === 'A')
      listado <= 4 ? this.citasAnuladasMain = listado.slice(1).slice(-3) : this.citasAnuladasMain = listado

      console.log('hhh',this.citasAnuladasMain)*/
      this.citas.forEach((cita) => {
        if (cita.est === 'A' && (this.limite == 0 || this.limite > this.citasAnuladas.length)) {
          this.citasAnuladas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora), cita: cita })
        }
      })

      /*result2.cit.sort(function(a, b) {
        return new Date(a.fch) - new Date(b.fch);
      })
      this.citasNoAsistidasMain = result2*/
    },

    nombreSemana (fecha) {
      const weekday = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sábado"];
      const d = new Date(fecha);
      let day = weekday[d.getDay()]
      return day
    },

    nombreMes (mes) {
      const mesesNombre = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      return mesesNombre[parseInt(mes) - 1]
    },

    estructuraFecha (fecha, hora) {
      let compoHora = hora.split(':')
      const formatoHora = compoHora[0] + ':' + compoHora[1]
      let compoFecha = fecha.split('T')
      compoFecha = compoFecha[0]
      let formatoFecha = compoFecha.split('-')
      return  formatoFecha[2] + ' ' + this.nombreMes(formatoFecha[1]).toUpperCase() + ' ' + formatoFecha[0].substring(2)+ ' '+  this.nombreSemana(formatoFecha).toUpperCase() + ' ' + formatoHora
    },

    resetProx () {
      this.filterAnioPro = ''
      this.filterEspecialidadPro = ''
      this.filterDoctorPro = ''
      this.filterCitaPro = ''
      this.bsqProx()
    },

    resetPa () {
      this.filterAnioPasa = ''
      this.filterEspecialidadPasa = ''
      this.filterDoctorPasa = ''
      this.filterCitaPasa = ''
      this.bsqProxPa()
    },

    limpFiltro () {
      this.filterAnio = ''
      this.filterEspecialidad = ''
      this.filterDoctor = ''
      this.filterCita = ''
      this.listadoProfesionales = []
      this.bsqFiltro()
    },

    bsqFiltro () {
      this.filterFuturas()
      this.filterPasadas()
      this.filterAnuladas()
    },

    bsqProx () {
      this.citasFuturas = []
      this.citas.forEach((cita) => {
        if (cita.est === 'C') {
          let criba = false
          if (this.filterAnioPro !== '') {
            if (!cita.fch.includes(this.filterAnioPro)) { criba = true }
          }
          if (this.filterEspecialidadPro !== '') {
            if (cita['prf.ser_prd.id'] !== this.filterEspecialidadPro.ref) { criba = true }
          }
          if (this.filterDoctorPro !== '') {
            if (cita['prf.name'] !== this.filterDoctorPro.name) { criba = true }
          }
          if (this.filterCitaPro !== '') {
            let tipo = ''
            if (this.filterCitaPro === 'Télefono') { tipo = 'T' }
            if (this.filterCitaPro === 'Videollamada') { tipo = 'V' }
            if (this.filterCitaPro === 'Online') { tipo = 'O' }
            if (this.filterCitaPro === 'Presencial') { tipo = 'P' }
            if (cita['cit_tip'] !== tipo) { criba = true }
          }

          if (!criba) {
            this.citasFuturas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
          }
        }
      })
    },

    bsqProxPa () {
      this.citasNoAsistidas = []
      this.citasNoAsistidasMain.forEach((cita) => {
        let criba = false
        if (this.filterAnioPasa !== '') {
          if (!cita.fch.includes(this.filterAnioPasa)) { criba = true }
        }
        if (this.filterEspecialidadPasa !== '') {
            if (cita['prf.ser_prd.id'] !== this.filterEspecialidadPasa.ref) { criba = true }
          }
        if (this.filterDoctorPasa !== '') {
          if (cita['prf.name'] !== this.filterDoctorPasa.name) { criba = true }
        }
        if (this.filterCitaPasa !== '') {
          let tipo = ''
          if (this.filterCitaPasa === 'Télefono') { tipo = 'T' }
          if (this.filterCitaPasa === 'Videollamada') { tipo = 'V' }
          if (this.filterCitaPasa === 'Online') { tipo = 'O' }
          if (this.filterCitaPasa === 'Presencial') { tipo = 'P' }
          if (cita['cit_tip'] !== tipo) { criba = true }
        }

        if (!criba) {
          this.citasNoAsistidas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
        }
      })
    },

    filterFuturas () {
      this.citasFuturas = []
      this.citas.forEach((cita) => {
        if (cita.est === 'C') {
          let criba = false
          if (this.filterAnio !== '') {
            if (!cita.fch.includes(this.filterAnio)) { criba = true }
          }
          if (this.filterEspecialidad !== '') {
            if (cita['prf.ser_prd.id'] !== this.filterEspecialidad.ref) { criba = true }
          }
          if (this.filterDoctor !== '') {
            if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
          }
          if (this.filterCita !== '') {
            let tipo = ''
            if (this.filterCita === 'Télefono') { tipo = 'T' }
            if (this.filterCita === 'Videollamada') { tipo = 'V' }
            if (this.filterCita === 'Online') { tipo = 'O' }
            if (this.filterCita === 'Presencial') { tipo = 'P' }
            if (cita['cit_tip'] !== tipo) { criba = true }
          }

          if (!criba) {
            this.citasFuturas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
          }
        }
      })
    },

    filterAnuladas () {
      this.citasAnuladas = []
      this.citas.forEach((cita) => {
        if (cita.est === 'A') {
          let criba = false
          if (this.filterAnio !== '') {
            if (!cita.fch.includes(this.filterAnio)) { criba = true }
          }
          if (this.filterEspecialidad !== '') {
            if (cita['prf.ser_prd.id'] !== this.filterEspecialidad.ref) { criba = true }
          }
          if (this.filterDoctor !== '') {
            if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
          }
          if (this.filterCita !== '') {
            let tipo = ''
            if (this.filterCita === 'Télefono') { tipo = 'T' }
            if (this.filterCita === 'Videollamada') { tipo = 'V' }
            if (this.filterCita === 'Online') { tipo = 'O' }
            if (this.filterCita === 'Presencial') { tipo = 'P' }
            if (cita['cit_tip'] !== tipo) { criba = true }
          }

          if (!criba) {
            console.log('fff');
            this.citasAnuladas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
          }
        }
      })
    },

    filterPasadas () {
      this.citasNoAsistidas = []
      this.citasNoAsistidasMain.forEach((cita) => {
        let criba = false
        if (this.filterAnio !== '') {
          if (!cita.fch.includes(this.filterAnio)) { criba = true }
        }
        if (this.filterDoctor !== '') {
          if (cita['prf.name'] !== this.filterDoctor.name) { criba = true }
        }
       
        if (this.filterEspecialidad !== '') {
            if (cita['prf.ser_prd.id'] !== this.filterEspecialidad.ref) { criba = true }
          }

        if (this.filterCita !== '') {
          let tipo = ''
          if (this.filterCita === 'Télefono') { tipo = 'T' }
          if (this.filterCita === 'Videollamada') { tipo = 'V' }
          if (this.filterCita === 'Online') { tipo = 'O' }
          if (this.filterCita === 'Presencial') { tipo = 'P' }
          if (cita['cit_tip'] !== tipo) { criba = true }
        }

        if (!criba) {
          this.citasNoAsistidas.push({ tipo: cita['cit_con.name'], doctor: cita['prf.name'] , fecha: this.estructuraFecha(cita.fch, cita.hora) })
        }
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
}

.mainControl2{
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
  flex-direction: column;
}

.colorVirtus{
  color: #2a4a5e;
}

.W90 {
  max-width: 90%;
}

.w40 {
  width: 40%;
}

@media (max-width: 767px) {
  .nvMvl{
    display: none;
  }

  .mt5rem{
    margin-top: 0.5rem!important;
  }

  .pl0x{
    padding-left:0px !important
  }

  .fa-caret-down{
    right: 20% !important;
  }
}

@media (min-width: 767px) {
  .mrlPc{
    margin-left:3px;
  }

  .noPcFilter {
    display: none;
  }
}

.borderCaja{
    border:2px solid black;
}

.pointer {
  cursor: pointer;
}

.dInlineBlock {
  display: inline-block;
}

.password-container{
  /* width: 400px; */
  /* max-width: 200px; */
    width: 100%;
    margin: 0 auto;
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"]{
  width: 96%;
  /* padding: 12px 36px 12px 12px; */
  box-sizing: border-box;
}
.fa-caret-down{
  position: absolute;
  top: 28%;
  right: 6%;
  cursor: pointer;
  color: lightgray;
}

.flex {
  display: flex;
}

.filterAbsolute{
  position: absolute;
  top: 50%;
  right:5%;
  max-width: 270px;
}

.overflowAuto {
  overflow-x: auto;
}

input {
  max-width: 100%;
}

.btn2 {
    background: #BB0000;
    border: none;
    color: white;
    padding: 0px 0px;
    width: 100px;
    margin-right: 10px;
    /* float: left; */
}

.btn5 {
    background: rgb(0, 187, 45);
    border: none;
    color: white;
    padding: 0px 0px;
    width: 115px;
    margin-left: 10px;
    /* float: right; */
}

.f80p{
    font-size:80%;
}

.W100{
    width: 100%;
}

.linea{
    border-bottom: 3px solid #000;
    margin-bottom: 10px;
    margin-top: 5px;
}

.mxW100x{
    max-width: 100px;
}

.mAuto{
    margin: 0 auto;
}

.W80{
    width: 80%;
}

.bgAdv {
    background: #9dc0ff;
}
</style>
