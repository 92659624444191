<template>
  <div>
    <div class="container pt-5 pb-5 mt-5 borderRa responsiveCuadro bgWhite">
        <div class="row m-0">
            <div class="col-12">
                <div class="row m-0 align-items-center">
                    <div class="col-md-6 col-12 mb-4 pr-0 borderRigh">
                        <CuadroInicio negrita="¿Ya estás registrado?" description="Accede a tu portal del Paciente desde aquí" btn="Inicio de sesión" url="/login" />
                    </div>
                    <div class="col-md-6 col-12 mb-4 borderTop borderLeft">
                        <CuadroInicio negrita="En caso contrario" description="Registrate para poder acceder al portal del paciente" btn="Registrate" url="/register" />
                    </div>

                    <div class="col-md-6 col-12 mb-4 mauto borderTop">
                        <CuadroAnalisis description="Accede aquí para descargar tus análisis clínicos" btn="Acceder a Análisis Clínicos Dr Goya " url="/analysis" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container pr-0 responsiveCuadro noMvl">
        <div class="row m-0">
            <div class="col-md-6 col-0"></div>
            <div class="col-12 col-md-6 mt-3 f13x pr-0 pl-0 text-right centroMvl"><b>Si tienes problemas <u class="pointer" @click="redirect"> ponte en contacto con nosotros</u></b></div>
        </div>
    </div>
  </div>
</template>

<script>
import CuadroInicio from './CuadroInicio.vue'
import CuadroAnalisis from './CuadroAnalisis.vue'

export default {
    components: {
        CuadroInicio,
        CuadroAnalisis
    },

    methods: {
        redirect () {
            this.$router.push('/contact')
        }
    }

    // async mounted () {
    //     try {
    //     const response = await fetch('https://storewhats.camarero10.team/test?phone=634208712')
    //     if (response.ok) {
    //         const userData = await response.json()
    //         this.user = userData
    //     } else {
    //         console.error('Error al obtener los datos del usuario')
    //     }
    //     } catch (error) {
    //     console.error(error)
    //     }
    // }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.borderRa{
    border:1px solid black;
    border-radius: 25px;
}

.mauto
{
    margin: 0 auto;
}
.f13x{
    font-size:13px;
}

@media (max-width: 601px) {
    .responsiveCuadro{
        max-width:80%;
        padding-top:10% !important;
        padding-bottom:10% !important;
    }

    .centroMvl{
        text-align: center !important;
    }

    .borderTop{
        border-top:3px solid black;
    }

    .noMvl{
        display:none;
    }
}

@media (min-width: 767px) { /* 601px */
    .borderLeft{
        border-left: 2px solid black;
    }
    .borderRigh{
        border-right: 2px solid black;
    }
}

.bgWhite {
    background: white !important;
}
</style>