<template>
  <div id="app">
	<alert-error v-if="modalError" />
	<alert-success v-if="modalSuccess" />
	<modal-generico />
    <div class="wrapper">
		<header class="header">
			<Cabecera />
		</header>
		<main v-if="auth" class="main" :class="ruta === 'Contacta' ? 'bgContacto' : ''">
			<router-view class="pageCenter"></router-view>
		</main>
		<main v-if="!auth" class="main" :class="ruta === 'Inicio' ? 'mainControl2' : ''">
			<router-view class="pageCenter"></router-view>
		</main>
		<footer class="footer">
			<Pie/> 
		</footer>
    </div>
  </div>
</template>

<script>
import Cabecera from './components/head/Cabecera.vue'
import Pie from './components/foot/Pie.vue'
import AlertError from './components/general/AlertError.vue'
import AlertSuccess from './components/general/AlertSuccess.vue'
import ModalGenerico from './components/general/ModalGenerico.vue'
import api from '@/services/api'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    Cabecera,
    Pie,
	AlertSuccess,
	ModalGenerico,
	AlertError
  },

  computed: {
    ...mapGetters({
        estado: 'estado',
        modalError: 'modalError',
        modalSuccess: 'modalSuccess',
		auth: 'auth',
		ruta: 'ruta'
    })
  },

  methods: {
    ...mapActions({
      setAuth: 'setAuth',
      setUser: 'setUser',
      setNombre: 'setNombre'
    })
  },

  async mounted () {
	this.setAuth(true)
	if (localStorage.getItem('q')) {
		this.setAuth(true)
		const data = {
			method: 'is_session_active',
			q: localStorage.getItem('q')
		}
		const res = await api.verificaHistorial(data)
		if (res.data) {
			this.setNombre(res.data.name)
			this.setUser(res.data)
			this.setAuth(true)
			// this.$router.push('/home')
		}
		if (res.status) {
			if (res.status == 'ERROR') {
				this.setAuth(false)
				this.$router.push('/')
				return
			}
		}

	} else {
		if (!this.dentro && this.hash === '#/low-user')
		{
			this.$router.push('/low-user');
		}
		else
		{
			this.setAuth(false)
			this.$router.push('/');
		}
	}
	
  }
}
</script>

<style>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
}

.mainControl2{
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  height:100%;
  flex-direction: column;
}

.wrapper {
	height: 100vh;
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto 1fr auto;
	grid-template-areas:
		"header 		header 	header 	header"
		"main 	main 		main 		main"
		"footer 		footer 	footer	footer";
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	@media (max-width: 36rem) {
		display: flex;
		flex-direction: column;
	}
}

.header {
	grid-area: header;
	@media (max-width: 36rem) {
		order: 1;
	}
}

.main {
	grid-area: main;
	/* padding: 1rem; */
	@media (max-width: 36rem) {
		order: 1;
	}
}

.footer {
	grid-area: footer;
	@media (max-width: 36rem) {
		order: 4;
	}
}

.header {
	width:100%;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 18px;
	font-family: Helvetica, Arial, sans-serif;
}

body {
	font-size: 1rem;
	margin: 0;
}

.bgContacto {
	background: url(assets/contacto-fondo.jpg) no-repeat center;
}
.mainControl2 {
	background: url(assets/fondo-inicio.jpg) no-repeat center;
}
/* @media (min-width: 769px) {
} */


/* input {
	width:250px;
} */
</style>
