<template>
  <div class="container mxW100">
    <div class="row">
        <div class="col-12">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4>Formulario de contacto</h4>
                    </div>
                    <!-- <div class="col-12 mt-3">
                        <select name="" id="" class="inputsLogin">
                            <option value="0">Motivo de la consulta</option>
                        </select>
                    </div> -->
                    <div class="col-12 mt-2 text-center">
                        <input v-model="subject" type="text" name="" id="" placeholder="Asunto" class="inputsLogin">
                    </div>
                    <div class="col-6 mt-2 text-right pr-0">
                        <input v-model="mail" type="text" name="" id="" placeholder="Email" class="inputsLogin">
                    </div>
                    <div class="col-6 mt-2 text-left pl-1">
                        <input v-model="phone" type="text" name="" id="" placeholder="Teléfono" class="inputsLogin">
                    </div>
                    <div class="col-12 mt-2 text-center">
                        <textarea v-model="body" name="" id="" placeholder="Motivo" cols="30" rows="10" class="inputsLogin"></textarea>
                    </div>
                    <div class="col-6 mt-3">
                        <!-- <router-link class="colorVirtus" to="/home">Adjuntar archivo</router-link> -->
                        <input type="file">
                    </div>
                    <div class="col-5 mt-3 text-right pr28x">
                        <button class="btn1 minW100x" @click="envioConsulta()">Enviar</button>
                    </div>
                    <div class="col-1 mt-3 text-left">
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/services/api'

export default {
  components: {
  },

  data: () => ({
    mail: '',
    body: '',
    phone: '',
    subject:'',
    file: '',
    attachment: false
  }),

  watch: {
    file () {
        this.getBase64(this.file)
    }
  },

  methods: {
    ...mapActions({
      setMsgSuccess: 'setMsgSuccess',
      setModalSuccess: 'setModalSuccess',
    }),



    getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const ambitoThis = this
        reader.onload = async function () {
            const resul = await api.envioMail(ambitoThis.mail, ambitoThis.body, ambitoThis.phone, reader.result, ambitoThis.subject)
            if (resul.status === 'OK') {
                ambitoThis.setMsgSuccess(resul.message)
                ambitoThis.setModalSuccess(true)
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    },


    async envioConsulta () {
        var file = document.querySelector('input[type="file"]').files[0];
        if (file == undefined) {
            const resul = await api.envioMail(this.mail, this.body, this.phone, this.attachment, this.subject)
            if (resul.status === 'OK') {
                this.setMsgSuccess(resul.message)
                this.setModalSuccess(true)
            }
        } else {
            this.getBase64(file); // prints the base64 string
        }
        // envioMail (email, body, phone, attachment)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.minW100x{
    min-width: 100px !important;
}
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    max-width: 100%;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 60%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.pr28x{
    padding-right: 28px;
}
</style>
