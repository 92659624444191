<template>
  <div class="head">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="controles">
      <div class="row m-0 h100">
        <div class="col-6 text-left"><img class="logoHead" alt="Email" src="../../assets/logo_virtus_cmc.png"></div>
        <div class="col-0 col-md-6 text-right p-0">
          <div class="row m-0 h100 bgHeaderMvl flexEnd minH30x" :class="auth ? 'cabeceraTop' : ''">
            <div class="text-right" :class="login ? 'col-6 nvMvlHead' : 'col-6 nvMvlHead'"></div>
            <div v-if="auth" class="col-2 text-center p-0 bgBtnHead">
              <div class="row align-items-center m-0 h100">
                <div class="col-12 text-center p-0 pointer" @click="redirect('/contact')">
                  <img class="btnHead" alt="Email" src="../../assets/mensaje.png">
                </div>
              </div>
            </div>
            <div  v-if="auth" class="col-2 text-center p-0 bgBtnHead borderL">
              <div class="row align-items-center m-0 h100">
                <div class="col-12 text-center p-0">
                  <a href="tel:918820189">
                    <img class="btnHead" alt="Email" src="../../assets/telefono.png">
                  </a>
                </div>
              </div>
            </div>
            <div v-if="auth" class="col-2 text-center p-0 bgBtnHead borderL nvMvlHead">
              <div class="row align-items-center m-0 h100">
                <div class="col-12 text-center p-0">
                  <router-link @click="salir()" to="/"><img class="btnHead" alt="Email" src="../../assets/salir.png"></router-link>
                </div>
              </div>
            </div>
            <div v-if="auth" class="toggle-menu noPc" @click="botonMvl">
              <div class="line line1"></div>
              <div class="line line2"></div>
              <div class="line line3"></div>
            </div>
            <div v-if="auth" class="noPc">
              <nav class="header__nav nav-bar">
                  <ul class="nav-list">
                    <li class="nav-list-item"><div href="#" class="nav-link" @click="redirect('/home')"><i class="fa-solid fa-house colorVirtus"></i> Inicio</div></li>
                    <li class="nav-list-item"><div href="#" class="nav-link" @click="ensenaMenu === 'citas' ? ensenaMenu = '' : ensenaMenu = 'citas'"><i class="fa-solid fa-calendar colorVirtus"></i> citas {{ ensenaMenu === 'citas' ? '▾' : '◂' }}</div></li>
                    <li v-if="ensenaMenu === 'citas'" class="nav-list-item"><div class="nav-link bgMenu2 gris" @click="redirect('/appoiments')"><i class="fa-solid fa-calendar-day gris"></i> Pedir cita</div></li>
                    <li v-if="ensenaMenu === 'citas'" class="nav-list-item"><div class="nav-link bgMenu2 gris" @click="redirect('/manage-appoiments')"><i class="fa-solid fa-calendar-days gris"></i> Gestión citas</div></li>
                    <li class="nav-list-item"><div class="nav-link" @click="ensenaMenu === 'historial' ? ensenaMenu = '' : ensenaMenu = 'historial'"><i class="fa-solid fa-inbox colorVirtus"></i>  Historial {{ ensenaMenu === 'historial' ? '▾' : '◂' }}</div></li>
                    <li v-if="ensenaMenu === 'historial'" class="nav-list-item bgMenu2"><div href="#" class="nav-link" @click="redirect('/inform-history')"><i class="fa-solid fa-clipboard colorVirtus"></i> Informes</div></li>
                    <li class="nav-list-item"><div class="nav-link" @click="redirect('/profile')"><i class="fa-solid fa-user colorVirtus"></i> Perfil</div></li>
                    <li class="nav-list-item"><div class="nav-link" @click="redirect('/contact')"><i class="fa-solid fa-envelope colorVirtus"></i> Contacta</div></li>
                    <li class="nav-list-item bgMenu2 "><div class="nav-link gris" @click="redirect('/')"><i class="fa-solid fa-power-off colorVirtus gris"></i> Cerrar sesión</div></li>
                  </ul>
                </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noMvl container W100">
      <div class="row">
        <div class="separa">
          <div class="row align-items-center text-left m-0 h100 nvMvlHead">
            <div class="col-6">
              <div v-if="showBack" @click="$router.go(-1)" class="bgBtnHead mxW150x text-right pr-3 pointer">
                <img class="btnHead" alt="Email" src="../../assets/atras.png">
                <b>ATRÁS</b>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center text-left m-0 h100 noPc">
          <div class="col-6">
            <div v-if="showBack" @click="$router.go(-1)" class="">ATRÁS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  data: () => ({
    showBack: false,
    ensenaMenu: '',
    login: false
  }),

  computed: {
    ...mapGetters({
      auth: 'auth',
    })
  },

  watch:{
    $route (to){
      if (to.path === '/login' || to.path === '/register' || to.path === '/low-user') {
        this.showBack = true
      } else if (to.path === '/contact' && !this.auth) {
        this.showBack = true
      } else {
        this.showBack = false
      }

      if (to.path === '/login' || to.path === '/register' || to.path === '/') {
        this.login = false
      } else {
        this.login = true
      }
    }
  },
  
  methods: {
    ...mapActions({
      setAuth: 'setAuth',
      setUser: 'setUser',
      setRuta: 'setRuta'
    }),

    salir () {
      this.setAuth(false)
      this.setUser(null)
      api.borraToken()
    },

    redirect (item) {
      this.ruta = item

      if (item !== '/') {
        this.$router.push(item)
      } else {
        this.$router.push('/')
        this.setAuth(false)
      }
    },

    botonMvl () {
      var navBar = document.querySelector('.nav-bar');
      navBar.classList.toggle('toggle');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.controles{
    height: 70%;
    width: 100%;
}

.separa{
    min-height:50px;
    /* height: 30%; */
    width: 100%;
    background: #2a4a5e;
}

.h100{
  height: 100%;
}

.alignCenter {
  align-self: center;
}

.bgBtnHead{
  background-color: #d3dbe1;
}

.W25{
  width: 25%;
}

@media (max-width: 767px) {
  .btnHead{
    width: 23%;
  }

  .bgHeaderMvl{
    background: #2a4a5e;
  }

  .logoHead{
    max-height: 50px;
  }

  .separa{
    /* height: 40%; */
    height:0px;
    width: 100%;
    background: #2a4a5e;
    margin-top: 3px;
  }

  .nvMvlHead{
    display: none;
  }

  .bgBtnHead{
    background-color: #fff;
    border: 1px solid #2a4a5e;
  }

  .nvPcHead{
    display: none;
  }
}

@media (min-width: 768px) {
  /* .btnHead{
    width: 20%;
  } */

  .logoHead{
    max-height: 75px;
  }

  .flexEnd {
    justify-content: end;
  }

  .noPc{
    display: none !important;
  }
}

@media (max-width: 768px) {
  .noMvl{
    display: none;
  }
}


/* @media (min-width: 769px) {
    .noPc{
        display: none;
    }
} */

.borderL{
  border-left:3px solid black;
}

.blanco{
  color: #fff;
}

.gris{
  color: #f1f1f1 !important;
}

.W100{
  max-width: 100%;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

.nav-bar {
	position: absolute;
	/* background-color: #122; */
  background-color: #f6fbf9;
  z-index: 99;
	top: 35px;
	right: 100%;
	/* height: 100vh; */
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: position 2.9s;
	-webkit-transition: position 2.9s;
	-moz-transition: position 2.9s;
	-ms-transition: position 2.9s;
	-o-transition: position 2.9s;
}

.toggle {
	right: 5px;
	box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.4);

}

.toggle-menu {
	/* background-color: rgba(0, 0, 0, 0.2); */
	/* background-color: #fff; */
  background-color: #2a4a5e;
	position: absolute;
	/* top: -50px; */
	right: 10px;
	width: 40px;
	height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    padding: 2px 2px 2px 3px;
	border-radius: 5px;
	cursor: pointer;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.line {
	width: 100%;
	height: 3px;
	border-radius: 5px;
	background-color: #fff;
	transition: transform 0.2s ease-out;
	-webkit-transition: transform 0.2s ease-out;
	-moz-transition: transform 0.2s ease-out;
	-ms-transition: transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
}

.toggle .line1 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(-45deg) translate(-6px, 4px);
}

.toggle .line2 {
	display: none;
}

.toggle .line3 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(45deg) translate(-6px, -4px);
}

.toggle .toggle-menu {
	background-color: white;
}

.nav-list {
  width: 100%;
	list-style: none;
}

.bgMenu2 {
  background: #adbbc3;
}

.nav-list-item {
	text-align: center;
	padding: 10px 0;
}

.nav-link {
	/* color: #fff; */
	color: #000;
	font-size: 22px;
	text-decoration: none;
	position: relative;
	padding-bottom: 4px;
}

.nav-link::before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: #fff;
	transform: scaleX(0);
	transition: transform 0.4s ease-in-out;
	transform-origin: left;
	-webkit-transition: transform 0.4s ease-in-out;
	-moz-transition: transform 0.4s ease-in-out;
	-ms-transition: transform 0.4s ease-in-out;
	-o-transition: transform 0.4s ease-in-out;
}

.nav-link:hover::before {
	transform: scaleX(1);
}

.mxW150x{
  max-width: 150px;
}

.pointer {
  cursor: pointer;
}

.cabeceraTop {
      position: absolute;
    width: 100%;
    top: 10px;
}

.minH30x{
  min-height: 30px;
}

.colorVirtus {
  color: #2a4a5e;
}
</style>
