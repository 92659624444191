<template>
    <div class="alert alert-success" id="alert-success" role="alert">
		{{ msgSuccess }}
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },

  computed: {
    ...mapGetters({
      msgSuccess: 'msgSuccess',
    })
  },

  mounted () {
    setTimeout(() => {
      this.setMsgSuccess('')
      this.setModalSuccess(false)
    }, "2000")
  },

  methods: {
    ...mapActions({
      setMsgSuccess: 'setMsgSuccess',
      setModalSuccess: 'setModalSuccess'
    }),
  }
}
</script>
<style scoped>
#alert-success{
   /* position: sticky;*/
   position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    opacity: 1;
    /*transition: opacity 1s; */
}

#alert-success.fade {
  opacity: 0;
}
</style>