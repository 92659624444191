<template>
  <div class="container mxW100">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="row align-items-center m-0">
        <div class="col-12 noPc mb-4">
            <div @click="$router.go(-1)" class="bgBtnHead mxW150x text-left pr-3 pointer">
                <img class="btnHead mxW40x" alt="Email" src="../../assets/atras.png">
                <span class="f17x">ATRÁS</span>
              </div>
        </div>
        <div class="col-12 col-md-6 text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div v-if="!recordar" class="tituloLogin">Accede a tu cuenta de <br> paciente en Grupo Virtus</div>
                        <div v-if="recordar" class="tituloLogin">Introduce el email para recordar la contraseña</div>
                    </div>
                    <!-- <div class="col-12 mt-3">
                        <input v-model="mail" type="text" name="" id="" placeholder="Email" class="inputsLogin" >
                    </div> -->
                    <div class="password-container mt-2">
                        <input v-model="mail" type="text" placeholder="Email" class="inputsLogin">
                    </div>
                    <!-- <div v-if="!recordar" class="col-12 mt-2 relative">
                        <input v-model="password" type="password" name="" id="" placeholder="Contraseña" class="inputsLogin showPass">
                    </div> -->
                    <div  v-if="!recordar" class="password-container mt-2">
                        <input v-model="password" :type="showPass ? 'text' : 'password'" placeholder="Contraseña" id="password" class="inputsLogin">
                        <i class="fa-solid fa-eye" id="eye" @click="muestraPass"></i>
                    </div>
                    <div class="col-12 mt-2 colorVirtus">
                        <div v-if="!recordar" class="colorVirtus mxW80 mAuto text-left f14xMvl textUnderline pointer" @click="recordar = true">¿Has olvidado tu contraseña?</div>
                        <div v-if="recordar" class="colorVirtus mxW80 mAuto text-left f14xMvl pointer" @click="recordar = false" type="submit">Iniciar sesión</div>
                    </div>
                    <div v-if="!recordar" class="col-12 mt-2">
                        <div class="mxW80 mAuto text-left f14xMvl"><input v-model="guardoDatos" type="checkbox"> <b class="ml-2">Recordar mis datos de usuario</b></div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="mxW80 mAuto text-left f14xMvl">Para solicitar su baja, <b class="ml-2"><a href="/#/low-user">pulse aquí</a></b></div>
                    </div>
                    <div class="col-12 mt-3">
                        <span v-if="showError" class="rojo mxW80 text-left">Usuario o contraseña incorrectos</span>
                        <button v-if="!recordar && !inProgress" class="btn1 pointer" id="inicioSesion" @click="logIn" type="submit">Iniciar sesión</button>
                        <button v-if="recordar" class="btn1 pointer" @click.prevent="solicitud">Enviar solicitud</button>
                        <div class="loading-container" v-if="inProgress">
                            <img class="loading_session mAuto" src="../../assets/loading.gif" />
                        </div>
                       
                    </div>
                    <div class="col-12 mt-2 colorVirtus">
                        <div class="colorVirtus mxW80 mAuto text-left f14xMvl textUnderline"><span class="pointer"  @click="registro">Registrarse</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-0 col-md-6">
            <img class="btnHead noMvlRespon" alt="Email" src="../../assets/imagenlogin.png">
        </div>
    </div>
  </div>
  <div id="popup_message" v-if="firstAccess">
    <div class="content_popup">
       <div>Al ser la primera vez que accedes a la aplicación, debes cambiar tu contraseña de acceso. Se ha enviado un correo electrónico a la dirección {{ mail }} para que puedas cambiar tu contraseña.</div>
       <button class="btn1 pointer"  @click="closePopup" type="submit">Aceptar</button>
   </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
// import { auth } from '../../utils/firebase'
// import { AES } from 'crypto-js';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
export default {
    
  data: () => ({
	showError: false,
    recordar: false,
    mail: '',
    password: '',
    msg: '',
    key: '1234567890123456',
    activo: true,
    guardoDatos: false,
    showPass: false,
    inProgress: false,
    firstAccess: false
  }),

  watch: {
    guardoDatos () {
        if (this.guardoDatos) {
            localStorage.setItem('guardoDatos', this.guardoDatos)
        } else if (!this.guardoDatos && localStorage.getItem('us') && localStorage.getItem('ps'))  {
            localStorage.setItem('guardoDatos', this.guardoDatos)
            localStorage.removeItem('us')
            localStorage.removeItem('ps')
        }

        if (!this.guardoDatos) {
            localStorage.setItem('guardoDatos', this.guardoDatos)
            localStorage.removeItem('us')
            localStorage.removeItem('ps')
        }
    }
  },

  computed: {
    ...mapGetters({
      uid: 'uid',
    })
  },

  mounted () {
    this.setRuta('Sesion')
    this.activo = true
    const ambitoThis = this
    // document.getElementsByTagName('body')[0].addEventListener("keypress", function(event) {
    //     if (event.key === "Enter") {
    //         event.preventDefault()
    //         ambitoThis.logIn()
    //     }
    // })

    // var body = document.getElementsByTagName('body')[0];

    // Definir la función del manejador del evento
    // function keyPressHandler(event) {
    //     if (event.key === "Enter") {
    //         event.preventDefault();
    //         ambitoThis.logIn();
    //     }
    // }


// Agregar el EventListener al cuerpo del documento
// body.addEventListener("keypress", keyPressHandler);

    this.keyPressHandler = function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        ambitoThis.logIn()
      }
    };

    document.getElementsByTagName('body')[0].addEventListener("keypress", this.keyPressHandler);

    if (localStorage.getItem('guardoDatos')) {
        this.guardoDatos = localStorage.getItem('guardoDatos')
        if (localStorage.getItem('us')) { this.mail = localStorage.getItem('us') }
        if (localStorage.getItem('ps')) {
            const decryptedBytes = AES.decrypt(localStorage.getItem('ps'), this.key);
            // console.log(decryptedBytes);
            const ps = decryptedBytes.toString(Utf8);
            this.password = ps
        }
    }
  },

  unmounted () {
    // var body = document.getElementsByTagName('body')[0];
    document.getElementsByTagName('body')[0].removeEventListener("keypress", this.keyPressHandler);
    // body.removeEventListener("keypress", keyPressHandler);
    // document.getElementsByTagName('body')[0].addEventListener("keypress", false)
  },

  methods: {
    ...mapActions({
      setMsgError: 'setMsgError',
      setModalError: 'setModalError',
      setUid: 'setUid',
      setAuth: 'setAuth',
      setUser: 'setUser',
      setNombre: 'setNombre',
      setRuta: 'setRuta',
      setMsgSuccess: 'setMsgSuccess',
      setModalSuccess: 'setModalSuccess'
    }),

    muestraPass () {
        this.showPass ? this.showPass = false : this.showPass = true
    },

    async logIn () {

        
        // const test = AES.encrypt(this.mail, this.key).toString();
        // console.log(test);
        // const decryptedBytes = AES.decrypt(test, this.key);
        // console.log(decryptedBytes);
        // const prueba = decryptedBytes.toString(Utf8);
        // console.log(prueba);
        this.inProgress = true

        if (!this.activo) {
            console.log(this.activo)
            this.inProgress = false
            return
        }
  
        if (this.email !== '' && this.password !== '') {
            if (this.password.length < 8) {
                this.setMsgError('La contraseña debe tener mas de 8 dígitos')
                this.setModalError(true)
                this.inProgress = false
                return
            }
            // eslint-disable-next-line
            const regMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (regMail.test(this.email)) {
                this.setMsgError('Debes introducir un correo válido')
                this.setModalError(true)
                this.inProgress = false
                return
            }
            const data = {
                method: 'login',
                email: this.mail,
                password: this.password
            }
            const res = await api.login(data)
            if (res.status) {
                if (res.status == 'ERROR') {
                    this.setMsgError(res.message)
                    this.setModalError(true)
                    this.inProgress = false
                    return
                }
            }
            if (res.first_access !== undefined && res.first_access !== null && res.first_access === 1) {
                this.firstAccess = true
		this.inProgress = false
                return
            }
            if (res.status === 'OK') {
                if (localStorage.getItem('guardoDatos')) {
                    localStorage.setItem('us', this.mail)
                    const modificaPass = AES.encrypt(this.password, this.key).toString();
                    localStorage.setItem('ps', modificaPass)
                }
                if (res.data) {
                    this.setNombre(res.data.name)
                    this.setUser(res.data)
                    this.setAuth(true)
                    this.$router.push('/home')
                }
                if (res.status) {
                    if (res.status == 'ERROR') {
                        this.setAuth(false)
                        this.$router.push('/')
                        this.inProgress = false
                        return
                    }
                }
            }
            // if (res.status === 'ERROR') { this.$router.push('/home') }
        } else {
            this.setMsgError('Debes rellenar los datos')
            this.setModalError(true)
            this.inProgress = false
        }
        // auth.signInWithEmailAndPassword(this.mail, this.password)
        // .then(async (userCredential) => {
        //     this.setAuth(true)
        //     const user = userCredential.user
        //     this.setUid(user.uid)
        //     const datos = await firestore.collection('users').doc(this.uid).get()
        //     console.log(user.uid)
        //     console.log(datos.data())
        //     this.setNombre(datos.data().name)
        //     this.setUser(datos.data())
        //     this.$router.push('/home')
        // })
        // .catch(async (err) => {
        //     çerr)
        //     this.setMsgError('Usuario o contraseña incorrectos')
        //     this.setModalError(true)
        // })

        // auth.onAuthStateChanged((user) => {
        // })

    },

    closePopup () {
       this.firstAccess = false
    },

    registro () {
        this.$router.push('/register')
    },

    async solicitud () {

        const data = {
            method: 'reset_pass',
            email: this.mail
        }
        await api.login(data)
        this.setMsgSuccess('Solicitud de cambio de contraseña enviada con éxito')
        this.setModalSuccess(true)
        // auth.sendPasswordResetEmail(this.mail)
        // .then(async () => {
        //     this.setMsgSuccess('Email enviado con exito!')
        //     this.setModalSuccess(true)
        // })
        // .catch(async (err) => {
        //     if (err.code === 'auth/user-not-found') {
        //         this.setMsgError('No tenemos registrado el email indicado')
        //         this.setModalError(true)
        //     } else if (err.code === 'auth/invalid-email') {
        //         this.setMsgError('El email es inválido')
        //         this.setModalError(true)
        //     }
        // })
    },

    setCookie(name,value) {
        localStorage.setItem(name ,value)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textUnderline {
    text-decoration: underline;
}
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    max-width: 100%;
}

.showPass {
    background: url(../../assets/ojo.png) no-repeat right center;
    cursor: pointer;
    /* background: url(../../assets/ojo.png) no-repeat no-repeat scroll 7px 7px; */
  background-size: 20px;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 80%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.mxW40x{
    max-width: 40px;
}
.f17x {
    font-size: 17px;
}

.loading_session
{
    width: 50px;
    height: 50px;
}
.loading-container
{
    width: 100%;
    display: block;
}

@media (max-width: 768px) {
    .noMvlRespon{
        display: none;
    }

    .f14xMvl{
        font-size: 14px;
    }

    .password-container input[type="password"],
    .password-container input[type="text"]{
        width: 90% !important;
        box-sizing: border-box;
    }

    .fa-eye{
        right: 8% !important;
    }
}

.rojo{
    color:red;
}

.pointer{
    cursor: pointer;
}

.relative{
    position: relative;
}

.password-container{
  /* width: 400px; */
  min-width: 80%;
    width: 80%;
    margin: 0 auto;
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"]{
  width: 96%;
  box-sizing: border-box;
}
.fa-eye{
  position: absolute;
  top: 28%;
  right: 4%;
  cursor: pointer;
  color: lightgray;
}

@media (min-width: 768px) {
  .noPc{
    display: none;
  }
}

#popup_message {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background: rgba(0,0,0,0.5);
}
#popup_message .content_popup {
    width: 600px;
    background: white;
    position: fixed;
    z-index: 99999;
    height: auto;
    top: 38%;
    left: 50%;
    margin-left: -300px;
    text-align: center;
    padding: 20px;
}
#popup_message .content_popup div {
    margin-bottom: 20px;
}
#popup_message .content_popup button {
    width: 200px;
}
</style>
