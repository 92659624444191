<template>
  <div class="container mxW100">
    <div class="row">
        <div class="col-12 noPc mb-4">
            <div @click="$router.go(-1)" class="bgBtnHead mxW150x text-left pr-3 pointer">
                <img class="btnHead mxW40x" alt="Email" src="../../assets/atras.png">
                <span class="f17x">ATRÁS</span>
              </div>
        </div>
        <div class="col-12 col-md-6">
         <p>Si desea darse de baja de nuestro servicio de cita online Virtus Salud puede hacerlo enviado un correo electrónico con sus datos de identificación a la dirección: <a href="mailto:citaonline@cmcomplutense.es">citaonline@cmcomplutense.es</a> indicando en el asunto: <b>"Solicitud de baja del servicio"</b>.</p>

         <p>Su acceso al servicio será interrumpido tan pronto sea validada su solicitud y la información aportada, comunicándosele puntualmente en el momento de realizarla.</p>

         <p>Sus datos se conservarán durante el tiempo estrictamente necesario para la finalidad para la que fueron recabados. Los datos personales proporcionados, así como aquellos derivados de la asistencia sanitaria prestada se conservarán durante el tiempo adecuado a cada caso (atendiendo a criterios médicos y legales), y como mínimo, cinco años contados desde la fecha de alta de cada proceso asistencial.</p>
        </div>
        
	<div class="col-0 col-md-6">
            <img class="btnHead noMvlRespon" alt="Email" src="../../assets/imagenlogin.png">
        </div>

    </div>
  </div>
</template>

<script>
// import api from '@/services/api'
// import Test from './Test.vue'

// import { firestore, auth } from '../../utils/firebase'
export default {
  components: { 
    // Test
  },

  methods: {
  }
}
</script>
<style scoped>
.inputsLogin{
    width: 80%;
    padding:5px;
}

.tituloLogin{
    text-align: left;
    max-width: 80%;
    font-size: 13px;
    margin: 0 auto;
    font-weight: bold;
}

.mxW100 {
    max-width: 100%;
}

.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 80%;
}

.colorVirtus {
    color:#2a4a5e;
}

.mxW80{
    max-width: 80%;
}

.mAuto{
    margin:0 auto;
}

.f16x{
    font-size:16px;
}

.W100{
    width:100%;
}

.H100{
    height: 100%;
}

@media (max-width: 768px) {
    .noMvlRespon{
        display: none;
    }

    .f14xMvl{
        font-size: 14px;
    }
}
</style>
