<template>
  <div class="container analisis">
    <div class="row m-0">
        <div class="col-12 p-0 mt-3Mvl mb-3Pc text-center"><img border="0" src="../../assets/logo-Goya-1.png"></div>
        <div class="col-12 mt-4 displayNone"><div class="cajaDescription"><span>{{ description }}</span></div></div>
        <div class="col-12 mt-2 text-center"><router-link :to="url"><button class="btn1" @click="redirect">{{ btn }}</button></router-link></div>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        description: String,
        btn: String,
        url: String,
        index: String
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn1{
    background: #2a4a5e;
    border: none;
    color: white;
    padding: 5px 0px;
    width: 85%;
    cursor: pointer;
}

.cajaDescription {
    max-width: 250px;
    margin: 0 auto;
}
img
{
    width: auto;
    max-width: 85%;
}
.analisis
{
    margin-top: 15px;
}
@media (max-width: 601px) {
    .mt-3Mvl {
        margin-top: 1rem !important;
    }
    .displayNone{
        display: none;
    }
}

@media (min-width: 602px) {
    .mb-3Pc {
        margin-bottom: 1rem !important;
    }
}
</style>