<template>
  <div class="">
    <Inicio />
    <DescargaApp class="mt-4" />
  </div>
</template>

<script>
import {  mapActions } from 'vuex'
import Inicio from '../components/home/Inicio.vue'
import DescargaApp from '../components/home/DescargaApp.vue'

export default {
  components: {
    Inicio,
    DescargaApp
  },

  mounted () {
    this.setRuta('Inicio')
  },

  methods: {
    ...mapActions({
      setRuta: 'setRuta'
    })
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl2 {
  background: url(../assets/fondo-inicio.jpg) no-repeat center;
}
</style>
