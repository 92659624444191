<template>
  <!-- <div class="mainControl">
    
  </div> -->
  <div class="container W90 mt-5 mb-5">
    <div class="row">
        <div class="col-12 mb-4 nvMvl">
          <secciones selectOpcion="Perfil" />
        </div>
        <div class="col-12 text-center p-0">
          <Perfil />
        </div>
    </div>
  </div>
</template>

<script>
import Perfil from '../components/usuario/Perfil.vue'
import Secciones from '../components/general/Secciones.vue'

export default {
  components: {
    Perfil,
    Secciones
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .nvMvl{
    display: none;
  }
}
</style>
