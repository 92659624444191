<template>
    <div class="row">
        <div class="col-12 col-md-6 cajaPerfil">
            <div class="row">
                <div class="col-10">{{ user.name + ' ' + user.lastName }}</div>
                <div class="col-2"><img class="mxW25x" alt="Email" src="../../assets/icono-perfil-blanco.png"></div>
            </div>
        </div>
        <div class="col-6 nvMvl" />
        <!-- <div class="col-12 col-md-6 cajaPerfil mt-2">
            <div class="row">
                <div class="col-10">Alex Rodriguez Caldito</div>
                <div class="col-2"><img class="mxW25x" alt="Email" src="../../assets/icono-perfil-blanco.png"></div>
            </div>
        </div>
        <div class="col-6 nvMvl" /> -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  // mounted () {
  //   console.log(this.user)
  // }
}
</script>

<style scoped>
.mxW25x{
    max-width: 25px;
}

.cajaPerfil{
    padding:10px;
    background-color: #2a4a5e;
    color:white;
}

@media (max-width: 767px) {
  .nvMvl{
    display: none;
  }
}
</style>