var hash = window.location.hash;
var dentro = false;
import { createApp } from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/bootstrap.min.css';
import vuex from './store/index'
import App from './App.vue'
import Main from '../src/views/Main.vue'
import InicioSesion from '../src/views/InicioSesion.vue'
import RegistroUsuario from '../src/views/RegistroUsuario.vue'
import BajaUsuario from '../src/views/BajaUsuario.vue'
import InicioUsuario from '../src/views/InicioUsuario.vue'
import Contacto from '../src/views/Contacto.vue'
import Analisis from '../src/views/Analisis.vue'
import PerfilUsuario from '../src/views/PerfilUsuario.vue'
import InformeHistorial from '../src/views/InformeHistorial.vue'
import RecetasHistorial from '../src/views/RecetasHistorial.vue'
import GestionCitas from '../src/views/GestionCitas.vue'
import PedirCita from '../src/views/PedirCita.vue'
import store from './store/index';
// import RecetasHistorial from '../src/views/RecetasHistorial.vue'
// const app = createApp(App).mount('#app')

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', component: Main },
  { path: '/login', component: InicioSesion },
  { path: '/register', component: RegistroUsuario },
  { path: '/low-user', component: BajaUsuario },
  { path: '/contact', component: Contacto },
  { path: '/home', component: InicioUsuario, meta: { requiresAuth: true } },
  { path: '/profile', component: PerfilUsuario, meta: { requiresAuth: true } },
  { path: '/inform-history', component: InformeHistorial, meta: { requiresAuth: true } },
  /*{ path: '/analysis', component: Analisis, meta: { requiresAuth: true } },*/
  { path: '/analysis', component: Analisis},
  { path: '/recipes-history', component: RecetasHistorial, meta: { requiresAuth: true } },
  { path: '/manage-appoiments', component: GestionCitas, meta: { requiresAuth: true } },
  { path: '/appoiments', component: PedirCita, meta: { requiresAuth: true } }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
  vuex // short for `routes: routes`
})

// control de las url privadas
router.beforeEach((to, from, next) => {
  dentro = true
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth) {
      next();
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }  
})

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.use(vuex)
app.config.globalProperties.hash = hash
app.config.globalProperties.dentro = dentro
app.mount('#app')
