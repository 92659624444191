<template>
  <div class="container W100 mb-5 mt-5">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">
    <div class="row">
      <div class="col-12 mt-4 mb-5">
        <secciones selectOpcion="citas" />
      </div>
      <div class="col-12 mt-2 text-center mt-1">
      <h4> <i class="fa-solid fa-clipboard" id="eye"></i> Pide tu cita</h4>
      </div>
      
      <div class="col-12 col-md-8 mAuto mt-2 text-center mt-4 pl-0">
        <b>Aseguradora</b>
        <select v-model="ase" name="" id="" class="d-block mAuto">
          <option v-for="(ase, index) in aseguradoras" :key="index" :value="ase.id">{{ ase.name }}</option>
        </select>
      </div>

      <div v-if="ase !== ''" class="col-12 mt-2 text-center mb-1">
        <!-- <input v-model="especialidad" type="text" readonly  data-toggle="modal" data-target="#exampleModal2" class="btn1 hoverBtn radiusBtn p-2" @click="listaSelect('especialidad')"> -->
        <div class="password-container mt-2 w221x">
          <input v-model="especialidad" type="text" data-toggle="modal" data-target="#exampleModal2" readonly class="pointer w221x colorVirtus"  @click="listaSelect('especialidad')">
          <i class="fa-solid fa-caret-down icono-right" id="eye"></i>
        </div>
      </div>
      <div v-if="especialidad !== 'Especialidad..'" class="col-12 mt-2 text-center mt-1">
        <h4>¿Cómo pedir tu cita?</h4>
      </div>
      <div v-if="especialidad !== 'Especialidad..'" class="col-12 text-center mb-3">
        <div class="cuadroTipoCita">
          <div v-for="(tipo, index) in tiposPideCita" :key="index" class="bgColor p-2 ml-2 blanco W107x pointer" :class="tipo.name === tipoPide ? '' : 'opa5'" @click="eligeCita(tipo.name)">
            <div class="text-center"><img class="mxW25x" alt="Email" :src="urlImg(tipo.img)" /></div>
            <span>{{ tipo.name }}</span>
          </div>
        </div>
        <div v-if="tipoPide === 'Profesional'" class="col-12 text-center mt-4">
          <b>Profesional</b>
        </div>
        <div v-if="tipoPide === 'Profesional'" class="col-12 mt-2 text-center mb-1">
          <div class="password-container mt-2 w221x">
          <input v-model="profesional" type="text" data-toggle="modal" data-target="#exampleModal2" readonly class="pointer w221x colorVirtus"  @click="listaSelect('profesionales')">
            <i class="fa-solid fa-caret-down icono-right" id="eye"></i>
        </div>
        </div>
        <div v-if="profesional !== 'Profesional..'" class="col-12 text-center mt-4">
          <b>Si desea una fecha diferente entre hoy y los próximos tres días indicala aquí</b>
        </div>
        <div v-if="profesional !== 'Profesional..'" class="col-12 mt-2 text-center mb-1">
          <input v-model="fechaProfesional" type="date">
        </div>
      </div>
      <div v-if="tipoPide === 'Fecha'" class="W100 cuadroTipoCita">
        <div v-for="(btn, index) in btnsFecha" :key="index" class="mt-2 text-center m-1">
          <button :class="turno === btn ? 'mW151x btn1 p-2' : 'mW151x btn3 p-2'" @click="marcaTurno(btn)">{{ btn }}</button>
        </div>
      </div>
      <div v-if="tipoPide === 'Fecha' && turno === 'Otra fecha'" class="col-12 mt-2 text-center mb-1">
        <span>Indica la fecha que desea</span>
      </div>
      <div v-if="tipoPide === 'Fecha' && turno === 'Otra fecha'" class="col-12 mt-2 text-center mb-1">
        <input v-model="fechaAlt" type='date' :min='new Date().toISOString().split("T")[0]' @change="buscaFecha">
      </div>
      <!-- <div v-if="tipoPide === 'Hora'" class="cuadroTipoCita W100">
        <div v-for="(btn, index) in btnsHora" :key="index" class="mt-2 text-center m-1">
          <button :class="turno === btn ? 'mW151x btn1 p-2' : 'mW151x btn3 p-2'" @click="marcaTurno(btn)">{{ btn }}</button>
        </div>
      </div> -->
      <div v-if="muestraTabla()" class="col-12 col-md-8 mAuto bgHorarios mt-4 mxH300x overflowScroll">
        <div v-if="diaHorario.length !== 0" class="row pb-3 pt-3">
          <div v-for="(dia, index) in diaHorario" :key="index" class="col-3">
            <div class="cuadroTipoCita">
              <div class="bgColor p-2 ml-2 blanco W90p text-center pointer">
                <span>{{ dia.diaL }}</span> <br>
                <b>{{ dia.diaN }}</b>
              </div>
            </div>
            <div v-for="(hora, index) in dia.horario" :key="index" :class="horaAnterior(dia, hora) ? 'cuadroTipoCita mt-2' : ''" @click="citaHora(dia.diaN, dia.diaL, hora)">
              <div v-if="horaAnterior(dia, hora)" class="bgColor p-2 ml-2 blanco W90p text-center pointer" :class="dia.diaN === cita.diaN && dia.diaL === cita.diaL && hora === cita.hora ? '' : 'opa5'">
                <div v-if="hora.nombreDoctor !== undefined" style="font-size:10px;">{{ hora.nombreDoctor }}</div>
                <span>{{ hora.hora }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-3 pb-3 text-center">
          <b>No hay citas para los próximos 3 días</b>
        </div>
      </div>
      <div  v-if="cita.diaN !== ''" class="col-12 col-md-8 mAuto mt-2 text-center mt-4 pl-0">
        <b>Tipo de consulta</b>
        <select v-model="tipoConsultaId" name="" id="" class="d-block mAuto">
          <option v-for="(consulta, index) in listadoTipoCita" :key="index" :value="consulta.id">{{ consulta.name }}</option>
        </select>
      </div>
      <div  v-if="cita.diaN !== ''" class="col-12 col-md-8 mAuto mt-2 text-center mt-4 pl-0">
        <b>Tipo cita</b>
        <select v-model="tipoCita" name="" id="" class="d-block mAuto">
          <option value="P">Presencial</option>
          <option value="V">Video conferencia</option>
          <option value="T">Teléfonica</option>
        </select>
      </div>
      <div  v-if="false && cita.diaN !== ''" class="col-12 col-md-8 mAuto mt-2 text-center mt-4 pl-0"> <!-- lo pedimos lo primero por cambio de NS mail 26/07/23 -->
        <b>Aseguradora</b>
        <select v-model="ase" name="" id="" class="d-block mAuto">
          <option v-for="(ase, index) in aseguradoras" :key="index" :value="ase.id">{{ ase.name }}</option>
        </select>
      </div>
      <div v-if="this.tipoConsultaId !== '' && this.tipoCita !== '' && ase !== ''" class="col-12 col-md-8 mAuto mt-2 text-left mt-4 pl-0">
        <h4>Resumen de tu cita</h4>
      </div>
      <div v-if="this.tipoConsultaId !== '' && this.tipoCita !== '' && ase !== ''" class="col-12 col-md-6 mAuto mt-2 text-left mt-1 pl-0">
        <div class="row bgCita p-3">
          <div class="col-5 pointer">
            <div class="d-flex flexColumn text-center">
              <b>{{ cita.diaL }}</b>
              <b>{{ cita.diaN }}</b>
              <b>{{ cita.hora.hora }}</b>
            </div>
          </div>
          <div class="col-7 borderL pl-5 pointer">
            <div class="d-flex flexColumn">
              <b>{{ especialidad }}</b>
              <b>{{ cita.hora.nombreDoctor !== undefined ? cita.hora.nombreDoctor : profesional }}</b>
              <!-- <b>Sala 3</b> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <br> -->
      <div class="W100" />
      <div v-if="this.tipoConsultaId !== '' && this.tipoCita !== '' && ase === '28'" class="col-12 col-md-6 mAuto mt-2 text-left mt-4 pl-0">
        <div class="row bgError p-3">
          <div class="col-12">
            <span>Le recordamos que está consulta no entra dentro de su seguro de salud y se reservará en carácter privado</span>
          </div>
        </div>
      </div>
      <div v-if="this.tipoConsultaId !== '' && this.tipoCita !== '' && ase !== ''" class="col-12 mt-4">
        <div class="col-12 col-md-6 mt-2 text-center mAuto">
            <button class="btn1 minW100x" @click="realizoCita()">Aceptar</button>
        </div>
      </div>
      <input v-model="profesional" type="text" data-toggle="modal" data-target="#modalCorrect" id="citaCorrecta" style="display:none;" readonly class="pointer w221x colorVirtus">
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ tipoPide === 'Profesional' ? 'Selecciona un profesional' : 'Selecciona la especialidad' }}</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-10 text-center mAuto">
                <input v-model="bsqEspecialidad" type="text" class="W100">
              </div>
              <div class="col-10 mAuto mt-3 mxH200x overflowScroll">
                <span v-for="(item, index) in especialidades" :key="index" class="d-block mt-3 pointer" data-dismiss="modal" @click="selectEspe(item)">{{item.name}}</span>
                <span v-if="especialidades.length === 0" class="d-block mt-3 red" data-dismiss="modal" >No hay profesionales asociados para esa especialidad</span>
              </div>
              <div id="cierraModal" data-dismiss="modal"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modalCorrect" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background: #99e4ab;font-weight: bold;">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Selecciona la especialidad</h5> -->
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-10 mAuto mt-3 mxH200x text-center">
                ✅
                <br>
                Tu cita ha sido confirmada con éxito
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import { firestore } from '../utils/firebase'
import api from '@/services/api'
import Secciones from '../components/general/Secciones.vue'

export default {
  components: {
    Secciones
  },

  data: () => ({
    recetaNueva: true,
    bsqEspecialidad: '',
    cit_hue: '',
    aseguradoras: [],
    idCita: '',
    tipoPide: '',
    turno: '',
    rangoDesde: '',
    rangoHasta: '',
    tipoCita: '',
    citaHecha: false,
    tipoConsultaId: '',
    especialidad: 'Especialidad..',
    especialidadItem: null,
    profesionalItem: null,
    fechaAlt: new Date().toISOString().split("T")[0],
    profesional: 'Profesional..',
    cita: {diaN: '', diaL: '', hora: ''},
    btnsFecha: ['Lo antes posible', 'Otra fecha'],
    // btnsHora: ['Lo antes posible', 'Mañanas', 'Tardes'],
    especialidades: [],
    especialidadesMain: [],
    profesionalMain: [],
    listadoProfesionales: [],
    diaHorario: [],
    ase: '',
    // ordena: false,
    citas: [],
    diaHoy: '',
    mesHoy: '',
    fechaProfesional: '',
    miliSecondsHoy: '',
    tiposPideCita: [ {name: 'Profesional', img: 'icono-perfil-blanco.png'}, {name: 'Fecha', img: 'calendario.png'}]
  }),

  computed: {
    ...mapGetters({
      citaItem: 'citaItem',
      uid: 'uid',
      user: 'user'
    })
  },

  watch: {
    bsqEspecialidad () {
      alert('jjj');
      this.bsqEspecialidad = this.bsqEspecialidad.toLowerCase()
      if (this.tipoPide !== 'Profesional') {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.especialidadesMain
          this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        } else {
          this.especialidades = []
          this.especialidadesMain.forEach((item) => {
            if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
          })
          this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
      } else {
        if (this.bsqEspecialidad === '') {
          this.especialidades = this.profesionalMain
          this.especialidades.forEach((profesional) => {
            this.listadoProfesionales.push(profesional.id)
          })
          this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        } else {
          this.especialidades = []
          this.profesionalMain.forEach((item) => {
            // if (item.includes(this.bsqEspecialidad)) { 
            //   if (item !== undefined) {
            //     if (item.name !== undefined) {
            //       if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.listadoProfesionales.push(item) }
            //     }
            //   }
            //   // this.listadoProfesionales.push(item.id)
            //   // return item
            // }
            if (item !== undefined) {
              if (item.name !== undefined) {
                if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
              }
            }
          })
          this.especialidades.sort( function( a, b ) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
      }
    },

    fechaProfesional () {
      // console.log(this.fechaProfesional)
      const dateDesde = new Date(this.fechaProfesional)
      this.rangoDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
      let dateHasta = new Date(this.fechaProfesional)
      dateHasta = this.sumarDias(dateHasta, + 3)  
      this.rangoHasta = dateHasta.getDate() + '%2F' + (dateHasta.getMonth() + 1) + '%2F' + dateHasta.getFullYear()
      this.horarioEmpleado()
    },

    especialidad () {
      if (this.especialidad !== 'Especialidad..')
      {
          this.tipoConsulta()
      }
    },

    tipoPide () {
      this.turno = ''
    },

    ase () {
      this.especialidad = 'Especialidad..'
      this.especialidadItem = null
      this.listaSelect('especialidad')
    },

    // ordena () {
    //   // console.log(this.diaHorario)
    //   console.log(this.ordena)
    //   if (this.ordena) {
    //     console.log(this.diaHorario)
    //     // this.diaHorario.sort( function( a, b ) {
    //     //   return a.diaN < b.diaN ? -1 : a.diaN > b.diaN ? 1 : 0;
    //     // })
    //     this.diaHorario.forEach((item) => {
    //     item.horario.sort(function (x, y) {
    //       if (x.hora < y.hora) {
    //           return -1
    //       }
      
    //       if (x.hora > y.hora) {
    //           return 1
    //       }
    //       return 0;
    //     })
    //   })
    //   this.diaHorario.sort(function (x, y) {
    //     if (x.diaN < y.diaN) {
    //         return -1
    //     }
    
    //     if (x.diaN > y.diaN) {
    //         return 1
    //     }
    //     return 0;
    //   })
    //   }
    // }
  },

  async mounted () {
    const ambitoThis = this
    // this.listaSelect('especialidad')
    this.keyPressHandler = function (event) {
      if (event.key === "Enter") {
          event.preventDefault()
          if (ambitoThis.tipoPide !== 'Profesional') {
            if (ambitoThis.especialidades.length === 1) {
              ambitoThis.selectEspe(ambitoThis.especialidades[0])
              const modal = document.getElementById('cierraModal');
              modal.click()
            }
          } else if (ambitoThis.tipoPide === 'Profesional') {
              ambitoThis.selectEspe(ambitoThis.especialidades[0])
              const modal = document.getElementById('cierraModal');
              modal.click()
            }
      }
    };

    document.getElementsByTagName('body')[0].addEventListener("keypress", this.keyPressHandler);
   
    // document.getElementsByTagName('body')[0].addEventListener("keypress", function(event) {
    //   if (event.key === "Enter") {
    //     console.log('llegoooo???')
    //       event.preventDefault()
    //       console.log(ambitoThis.tipoPide)
    //       if (ambitoThis.tipoPide !== 'Profesional') {
    //         if (ambitoThis.especialidades.length === 1) {
    //           ambitoThis.selectEspe(ambitoThis.especialidades[0])
    //           const modal = document.getElementById('cierraModal');
    //           modal.click()
    //         }
    //       } else if (ambitoThis.tipoPide === 'Profesional') {
    //           ambitoThis.selectEspe(ambitoThis.especialidades[0])
    //           const modal = document.getElementById('cierraModal');
    //           modal.click()
    //         }
    //   }
    // })
    const fecha = new Date()
    if (fecha.getDate() < 10) {
      this.diaHoy = '0' + fecha.getDate()
    } else {
      this.diaHoy = fecha.getDate()
    }
    this.mesHoy = this.nombreMes(fecha.getMonth() + 1)
    this.miliSecondsHoy = this.toMiliseconds(fecha.getHours(), fecha.getMinutes())
    // console.log(fecha.getMinutes())
    // console.log(fecha.getHours())

    await this.traeEspecialides()
    if ((this.citaItem.his?.toString())) this.asignoCita()
    this.traeAseguradorasCliente()

    if (this.bsqEspecialidad === '') {
      this.especialidades = this.especialidadesMain
      this.especialidades.sort( function( a, b ) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
    } else {
      this.especialidades = []
      this.especialidadesMain.forEach((item) => {
        if (item.name.toLowerCase().includes(this.bsqEspecialidad) || item.name.includes(this.bsqEspecialidad)) { this.especialidades.push(item) }
      })
      this.especialidades.sort( function( a, b ) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
    }
  },

  unmounted () {
    // var body = document.getElementsByTagName('body')[0];
    document.getElementsByTagName('body')[0].removeEventListener("keypress", this.keyPressHandler);
    // body.removeEventListener("keypress", keyPressHandler);
    // document.getElementsByTagName('body')[0].addEventListener("keypress", false)
  },


  methods: {
    ...mapActions({
      setCita: 'setCita',
      setAuth: 'setAuth',
      setUser: 'setUser'
    }),

    async traeAseguradorasCliente () {
      if (this.user.have_his === 1) {
        const result = await api.aseguradorasCliente2(this.user.dni)
        this.aseguradoras = result.ase
        if (result.status) {
          if (result.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
        }
      } else {
        this.aseguradoras = this.user.sociedades
      }
      
    },

    horaAnterior (dia, hora) {
      let retorno = true
      const fecha = this.diaHoy + ' ' + this.mesHoy
      const divideCita = hora.hora.split(':')
      const horaCita = this.toMiliseconds(parseInt(divideCita[0]), parseInt(divideCita[1]))
      if (fecha === dia.diaN && horaCita < this.miliSecondsHoy) { retorno = false }
      return retorno
    },

    toMiliseconds (hrs, min) {
      return (hrs*60*60+min)*1000
    },

    async asignoCita () {
     
      this.especialidadesMain.forEach((item) => {
        console.log('a',item);
        if (item.name === this.citaItem['cit_con.name']) {
          this.selectEspe(item)
        }
      })
      console.log('dd', this.citaItem);
      console.log('kkk',  this.especialidadItem);
      this.especialidadItem = {
        "cen_tra.id": this.citaItem['cen_tra'],
        ref:  this.citaItem['prf.ser_prd.id'],
        name: this.citaItem['prf.ser_prd.name'],
        "ser_prd.id":  this.citaItem['prf.ser_prd.id']
      }
      console.log('ggg',this.especialidadItem)
      this.tipoConsultaId = this.citaItem['prf.ser_prd.id']
      this.especialidad = this.citaItem['prf.ser_prd.name']
      this.tipoPide = 'Profesional'
      await this.traeProfesionales()
      this.listaSelect('profesionales')
      const result = this.profesionalMain.filter(profesional => profesional.id === this.citaItem['prf']);
      this.profesionalItem = result[0]
      this.profesional = result[0].name
      this.traeHorarioRango()
      this.tipoConsultaId = this.citaItem.cit_con
      this.tipoCita = this.citaItem.cit_tip
      this.idCita = this.citaItem.id
      this.setCita({})
    },

    selectEspe (item) {
      this.tipoPide !== 'Profesional' ? this.especialidad = item.name : this.profesional = item.name
      this.tipoPide !== 'Profesional' ? this.especialidadItem = item : this.profesionalItem = item
      if (this.tipoPide !== 'Profesional') { this.traeProfesionales() }
      if (this.tipoPide === 'Profesional') { this.traeHorarioRango() }
      this.bsqEspecialidad = ''
    },

    sumarDias (fecha, dias){
      fecha.setDate(fecha.getDate() + dias);
      return fecha;
    },

    async traeEspecialides () {
      // const resultados = await api.getEspecialidades()
      const resultados = await api.getEspecialidades2()

      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }

      this.especialidadesMain = resultados.esp_ser
    },

    marcaTurno (turnoMarcado) {
      this.turno = turnoMarcado
      if (turnoMarcado !== 'Otra fecha') {  
        this.fechaAlt = new Date().toISOString().split("T")[0]
        this.configuraRango()
        this.listHorariosEmpleados()
      } else {
        this.buscaFecha()
      }
      this.cita = {diaN: '', diaL: '', hora: ''}
      this.tipoCita = ''
      this.tipoConsultaId = ''
    },

    async traeEspecialidadesConcepto () {
      // const resultados = await api.getEspecialidadConcepto()
    },

    traeHorarioRango () {
      this.configuraRango()
      this.horarioEmpleado()
      this.cita = {diaN: '', diaL: '', hora: ''}
      this.tipoCita = ''
      this.tipoConsultaId = ''
    },

    async horarioEmpleado () {
      const resultado = await api.getCitaRango2(this.rangoDesde, this.rangoHasta, this.profesionalItem.id)

      if (resultado.status) {
        if (resultado.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      // const datos = []
      this.diaHorario = []
      await this.listadoHorario(resultado.cit_prg_tmp)
      // const res = await this.listadoHorario(resultado.cit_prg_tmp)
      // await Promise(res)
    },

    async listadoHorario (resultado) {
      const datos = []
      const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      const current_date = new Date(Date.now() - tzoffset);
			const parts = current_date.toISOString().slice(0, -1).slice(0, 19).split('T');
			const today = parts[0];
			const hour = parts[1].slice(0,5);
      console.log(today);
      console.log(hour);
      for (var i = 0; i < resultado.length; i++)
      {
        var dias = resultado[i];

        const diaNombre = dias.fch.split('T')
        const solofecha = diaNombre[0];
        const diaComposi = diaNombre[0].split('-')
        const fecha = dias.fch
        const resultadoHoras = await api.getCitaHora2(diaComposi[2] + '/' + diaComposi[1] + '/' + diaComposi[0], this.profesionalItem.id)

        if (today.localeCompare(solofecha) <= 0)
        {
          if (resultadoHoras.status) {
            if (resultadoHoras.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
          }
          // await Promise (resultadoHoras)
          let listadoHoras = []
          resultadoHoras.cit_prg_tmp.forEach((item) =>  {
           // console.log('vvv',item);
            let horaDesci = item.hor_dsd.split(':')
            if (today.localeCompare(solofecha) == 0)
            {
                const hora_cita =  horaDesci[0] + ':' + horaDesci[1];
                if (hora_cita.localeCompare(hour) > 0)
                {
                  listadoHoras.push({hora: horaDesci[0] + ':' + horaDesci[1], fecha: item.fch, id: item.id })
                }
                else
                {
                  console.log('no!!!');
                }
            }
            else
            {
              listadoHoras.push({hora: horaDesci[0] + ':' + horaDesci[1], fecha: item.fch, id: item.id })
            }
          })
          // console.log(resultadoHoras)
          // console.log(listadoHoras)
          if (listadoHoras.length > 0)
          {
              datos.push({diaL: this.nombreSemana(diaNombre[0]), diaN: diaComposi[2] + ' ' + this.nombreMes(diaComposi[1]), horario: listadoHoras, fecha: fecha })
          }
         
        } 

      }

      if (datos.length > 1)
      {
        datos.sort( function( a, b ) {

          //ConstantSourceNode.log('paso!!!');
              return a.fecha < b.fecha ? -1 : a.fecha > b.fecha ? 1 : 0;
            })
      }

      this.diaHorario = datos

/*
      resultado.forEach(async (dias, index) => {
        const diaNombre = dias.fch.split('T')
        const solofecha = diaNombre[0];
        const diaComposi = diaNombre[0].split('-')
        const fecha = dias.fch
        const resultadoHoras = await api.getCitaHora2(diaComposi[2] + '/' + diaComposi[1] + '/' + diaComposi[0], this.profesionalItem.id)

        if (today.localeCompare(solofecha) <= 0)
        {

          if (resultadoHoras.status) {
            if (resultadoHoras.status == 'ERROR') {
                this.setAuth(false)
                this.$router.push('/')
                return
            }
          }
          // await Promise (resultadoHoras)
          let listadoHoras = []
          resultadoHoras.cit_prg_tmp.forEach((item) =>  {
           // console.log('vvv',item);
            let horaDesci = item.hor_dsd.split(':')
            listadoHoras.push({hora: horaDesci[0] + ':' + horaDesci[1], fecha: item.fch, id: item.id })
          })
          // console.log(resultadoHoras)
          // console.log(listadoHoras)
          datos.push({diaL: this.nombreSemana(diaNombre[0]), diaN: diaComposi[2] + ' ' + this.nombreMes(diaComposi[1]), horario: listadoHoras, fecha: fecha })

        } 


        if (index === (resultado.length - 1)) {
            datos.sort( function( a, b ) {
              return a.fecha < b.fecha ? -1 : a.fecha > b.fecha ? 1 : 0;
            })

            this.diaHorario = datos
        }


      })
*/
      // console.log(res)
      // await Promise.all(res)
    },

    async listHorariosEmpleados () {
      this.diaHorario = []
      var datos = [];
      var completados = 0;
      // this.ordena = false
      await this.listadoProfesionales.forEach(async (item) => {
        const resultado = await api.getCitaRango2(this.rangoDesde, this.rangoHasta, item.id)
        if (resultado.status) {
          if (resultado.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
        }

        resultado.cit_prg_tmp.forEach((dias) => {
          console.log('dias',dias);
          const diaNombre = dias.fch.split('T')
          const diaComposi = diaNombre[0].split('-')
          let hora = dias.hor_dsd.split('.')
        
          hora = hora[0].split(':')
          // Compara para saber si en el mismo día hay horas disponibles aun que sea de distintos doctores
         /* if (!this.diaHorario.some((dia) => dia.diaL === this.nombreSemana(diaNombre[0]))) {

            this.diaHorario.push({diaL: this.nombreSemana(diaNombre[0]), diaN: diaComposi[2] + ' ' + this.nombreMes(diaComposi[1]), fechaSemana:  dias.fch, horario: [ { hora: hora[0] + ':' + hora[1], nombreDoctor: item.name, doctor: item, fecha: dias.fch }]})
          } else {
            this.diaHorario.forEach((diaRango) => {
              if (diaRango.diaL === this.nombreSemana(diaNombre[0])) { diaRango.horario.push({ hora: hora[0] + ':' + hora[1], nombreDoctor: item.name, doctor: item, fecha: dias.fch }) }
            })
          }
*/
          if (!datos.some((dia) => dia.diaL === this.nombreSemana(diaNombre[0]))) {

              datos.push({diaL: this.nombreSemana(diaNombre[0]), diaN: diaComposi[2] + ' ' + this.nombreMes(diaComposi[1]), fechaSemana:  dias.fch, horario: [ { hora: hora[0] + ':' + hora[1], nombreDoctor: item.name, doctor: item, fecha: dias.fch,  id: dias.id }]})
          } else {
              datos.forEach((diaRango) => {
                if (diaRango.diaL === this.nombreSemana(diaNombre[0])) { diaRango.horario.push({ hora: hora[0] + ':' + hora[1], nombreDoctor: item.name, doctor: item, fecha: dias.fch,  id: dias.id }) } 
              })
          }
          
          
        })

        completados++;

        console.log('fin');
        if (completados == this.listadoProfesionales.length )
        {
        
         /* var datosordenados = [];
          var dias_procesados = [];
        
          do {
            var indice = -1;
          var minimo = '';
          var dias_restantes = [];
            for (var i = 0; i < datos.length; i++)
            {
                if (i == 0)
                {
                    indice = 0;
                    minimo = datos[i].fechaSemana;
                    continue;
                }

                if (dias_procesados.indexOf(datos[i].fechaSemana) != -1)
                {
                    continue;
                }

                if (datos[i].fechaSemana.localeCompare(minimo) < 0)
                {
                    indice = i;
                    minimo = datos[i].fechaSemana;
                    dias_procesados.push(datos[i].fechaSemana)
                }

            }

            if (indice != -1)
            {
              for (i = 0; i < datos.length; i++)
              {
                  if (i == indice)
                  {
                    datosordenados.push(datos[i]);
                  }
                  else
                  {
                    dias_restantes.push(datos[i]);
                  }
              }

              console.log(datosordenados);
              console.log('hhh',dias_restantes);
              datos = dias_restantes;
            }

          }while (datos.length > 0)

        */

        //  datos = datosordenados
          /* datos.sort( function( a, b ) {
              console.log('a1', a.fechaSemana);
              console.log('b1', b.fechaSemana);
              console.log('c',a.fechaSemana .localeCompare(b.fechaSemana));
                return a.fechaSemana < b.fechaSemana ? -1 : a.fechaSemana > b.fechaSemana ? 1 : 0;
               // return a.fechaSemana .localeCompare(b.fechaSemana) < 0  ? -1 : a.fechaSemana.localeCompare(b.fechaSemana) > 0 ? 1 : 0;
                
            })*/
            this.diaHorario = datos;
        }
        
      })

      // Ordenar horas
      // this.diaHorario.forEach((item) => {
      //   item.horario.sort(function (x, y) {
      //     if (x.hora < y.hora) {
      //         return -1
      //     }
      
      //     if (x.hora > y.hora) {
      //         return 1
      //     }
      //     return 0;
      //   })
      // })
      // this.diaHorario.sort(function (x, y) {
      //   if (x.diaN < y.diaN) {
      //       return -1
      //   }
    
      //   if (x.diaN > y.diaN) {
      //       return 1
      //   }
      //   return 0;
      // })
     /* console.log('ggg', this.diaHorario);
      this.diaHorario.sort( function( a, b ) {
        return a.fechaSemana < b.fechaSemana ? -1 : a.fechaSemana > b.fechaSemana ? 1 : 0;
      })*/
      // this.ordena = true
    },

    configuraRango () {
      const dateDesde = new Date(this.fechaAlt)
      // (dateDesde.getMonth() + 1 < 10 ? '0' + (dateDesde.getMonth() + 1)
      // (dateHasta.getMonth() + 1 < 10 ? '0' + (dateHasta.getMonth() + 1)
      // (dateDesde.getMonth() + 1 < 10 ? '0' + (dateDesde.getMonth() + 1) : dateDesde.getMonth() + 1)
      this.rangoDesde = dateDesde.getDate() + '%2F' + (dateDesde.getMonth() + 1 < 10 ? '0' + (dateDesde.getMonth() + 1) : dateDesde.getMonth() + 1) + '%2F' + dateDesde.getFullYear()
      let dateHasta = new Date(this.fechaAlt)
      dateHasta = this.sumarDias(dateHasta, + 3)  
      this.rangoHasta = dateHasta.getDate() + '%2F' + (dateHasta.getMonth() + 1 < 10 ? '0' + (dateHasta.getMonth() + 1) : dateHasta.getMonth() + 1) + '%2F' + dateHasta.getFullYear()
    },

    async traeProfesionales () {
      console.log('aaa',this.ase)
      const resultados = await api.getListaProfesionales2(this.ase, 1, this.especialidadItem['ser_prd.id'])
      if (resultados.status) {
        if (resultados.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      resultados.count !== 0 ? this.profesionalMain = resultados.prf : this.profesionalMain = []
      this.listadoProfesionales = []
      resultados.prf.forEach((profesionales) => {
        this.listadoProfesionales.push({ id: profesionales.id, name: profesionales.name })
      })
    },

    eligeCita (cita) {
      this.profesional = 'Profesional..'
      this.tipoPide = cita
      this.cita = {diaN: '', diaL: '', hora: ''}
      this.tipoCita = ''
      this.tipoConsultaId = ''
      this.fechaProfesional = ''
    },

    listaSelect (tipo) {
      if (tipo === 'especialidad') {
        this.tipoPide = ''
        this.turno = ''
        this.profesional = 'Profesional..'
        this.fechaProfesional = ''
        this.especialidades = this.especialidadesMain
        this.cita = {diaN: '', diaL: '', hora: ''}
        this.tipoCita = ''
        this.tipoConsultaId = ''
        this.profesionalItem = null
        this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
      } else {
        this.especialidades = this.profesionalMain
        this.especialidades.sort( function( a, b ) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
      }
    },

    urlImg (img) {
      return require('../assets/' + img)
    },

    nombreSemana (fecha) {
      const weekday = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sábado"];
      const d = new Date(fecha);
      let day = weekday[d.getDay()];
      return day
    },

    nombreMes (mes) {
      const mesesNombre = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
      return mesesNombre[parseInt(mes - 1)]
    },

    citaHora (diaN, diaL, hora) {
      console.log('hora',hora);
      this.cit_hue = hora.id
      this.cita = { diaN: diaN, diaL: diaL, hora: hora }
      this.tipoCita = ''
      this.tipoConsultaId = ''
    },

    muestraTabla () {
      let retorna = false
      if (this.profesional !== 'Profesional..') {
        retorna = true
      }
      if (this.turno !== '') {
        this.ordenaDias()
        retorna = true
      }
      return retorna
    },

    ordenaDias () {
      console.log('this.diaHorario', this.diaHorario);
      this.diaHorario.sort(function (x, y) {
        x.horario.sort(function (x, y) {
          if (x.hora < y.hora) {
            return -1
          }
      
          if (x.hora > y.hora) {
            return 1
          }
          return 0
        })
       /* let diaUno = x.diaN.split(' ')
        diaUno = diaUno[0]
        let diaDos = y.diaN.split(' ')
        diaDos = diaDos[0]
        if (diaUno < diaDos) {
          return -1
        }
    
        if (diaUno > diaDos) {
          return 1
        }
        return 0
        */

        return x.fechaSemana.localeCompare(y.fechaSemana)
      })
    },

    buscaFecha () {
      this.configuraRango(this.fechaAlt)
      this.listHorariosEmpleados()
      this.cita = {diaN: '', diaL: '', hora: ''}
    },

    async tipoConsulta () {
      const resultado = await api.getEspecialidadConcepto2(this.especialidadItem['ser_prd.id'])
      if (resultado.status) {
        if (resultado.status == 'ERROR') {
            this.setAuth(false)
            this.$router.push('/')
            return
        }
      }
      this.listadoTipoCita = resultado.cit_con
    },

    async realizoCita () {
      let fecha
      if (this.cita.hora.fecha?.split('T')) {
        fecha = this.cita.hora.fecha.split('T')
        fecha = fecha[0].split('-')
        fecha = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
      }
      if (this.user.have_his !== 1) {
        const result = await api.generarHist2(this.user)
        if (result.status) {
          if (result.status == 'ERROR') {
              this.setAuth(false)
              this.$router.push('/')
              return
          }
        }
        // console.log(result)
        // let usuario = {...this.user}
        // usuario.his = result.his[0].id
        // console.log(usuario)
        this.setUser(result.data)
        // await firestore.collection('users').doc(this.uid).update(usuario)
        // Recordar a roly!!!!
      }

      // flujo entero
      if (this.cita.hora.doctor?.toString()) {
        this.profesionalItem = this.cita.hora.doctor
      }
      document.getElementById('citaCorrecta').click()

      if (this.idCita !== '') { this.anulaCita() }
      // if (this.idCita !== '') { await api.getAnulaCita(this.idCita) }
      // console.log(fecha, this.cita.hora.hora, this.profesionalItem.id, this.ase, this.tipoCita, this.tipoConsultaId, this.user.his)
      // await api.getIntroducecitas(fecha, this.cita.hora.hora, this.profesionalItem.id, this.ase, this.tipoCita, this.tipoConsultaId, this.user.his)
      this.meteCita(fecha)
      this.eligeCita('')
      this.especialidad = 'Especialidad..'
      this.ase = ''
    },

    async anulaCita () {
      if (this.citaItem.est !== 'T') { await api.getAnulaCita2(this.idCita) }

    },

    async meteCita (fecha) {
      console.log(this.cit_hue)
      await api.getIntroducecitas2(fecha, this.cita.hora.hora, this.profesionalItem.id, this.ase, this.tipoCita, this.tipoConsultaId, this.cit_hue)
    }
  }
}
</script>
<style>
.radiusBtn {
  border-radius: 5px;;
}

.hoverBtn:hover  {
  opacity: 0.7;
  cursor: pointer;
}

.bgCita {
  background-color: #fffff0;
}

input {
  max-width: 100%;
}

.bgError{
  background-color: #ff000050;
}

.mainControl {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
}

.bgHorarios {
  background-color:#d4dbdf;
}

.W90 {
  max-width: 90%;
}

.W90p {
  width: 90% !important;
}

.btn1{
    border: none;
    color: white;
    padding: 5px 0px;
    /* width: 60%; */
}

.colorSelect{
    background: #2a4a5e;
    color: #fff;
}

.colorNoSelect{
    background: #fff;
    border: 1px solid #2a4a5e;
    color: #2a4a5e;
}

.fRight{
    float:right;
}

.modal-header{
  border:none;
}

.mAuto{
  margin:0 auto;
}

.mxH200x{
  max-height: 200px;
}

.W100{
  width:100%;
}

.overflowScroll{
  overflow-x: hidden;
  overflow-y: scroll;
}
/* #exampleModal{
  max-height: 400px;
} */

.borderB{
  border-bottom: 3px solid #2a4a5e;
}

.colorSeccion{
  color:#2a4a5e;
}

.noDecoration{
  text-decoration: none;
}

.bgColor{
  background: #2a4a5e;
}

.blanco{
  color:white;
}

.W107x {
  width:107px;
}

.cuadroTipoCita{
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid;
  grid-template-columns: 30% 30% 30%; */
}

.opa5{
  opacity: 0.5;
}

.W100{
  width:100%;
}

.btn1{
  background: #2a4a5e;
  border: none;
  color: white;
  padding: 5px 0px;
  /* width: 60%; */
}

.btn3{
  background: #fff;
  border: 1px solid 2a4a5e;
  color: 2a4a5e;
  padding: 5px 0px;
  /* width: 60%; */
}

/* .mW151x{
  min-width: 151px;
} */

.flexColumn{
  flex-direction: column;
}

.borderL{
  border-left:4px solid black;
}

.red{
  color:red;
}

.mxH300x{
  max-height: 300px;
}

.W90x {
  min-width: 90px;
}

.minW100x{
  min-width: 100px;
}

.w221x{
  width: 221px !important;
}

.colorVirtus{
  color:#2a4a5e;
}

@media (max-width: 767px) {
  .icono-right{
    right: 5% !important;
  }
}
</style>
