<template>
    <div class="alert alert-danger" id="alert-error" role="alert">
		{{ msgError }}
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },

  computed: {
    ...mapGetters({
        msgError: 'msgError',
    })
  },

  mounted () {
    setTimeout(() => {
      this.setMsgError('')
      this.setModalError(false)
    }, "2000")
  },

  methods: {
    ...mapActions({
      setMsgError: 'setMsgError',
      setModalError: 'setModalError'
    }),
  }
}
</script>
<style scoped>
#alert-error{
  /*  position: sticky;*/
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    opacity: 1;
    transition: opacity 1s; 
}

#alert-error.fade {
  opacity: 0;
}
</style>