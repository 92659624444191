<template>
    <div>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id="botonlaunch" style="display:none;">
        Launch demo modal
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ jsonModal.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ jsonModal.body }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ jsonModal.cerrar }}</button>
                    <button type="button" class="btn btn-primary" @click="aceptoModal" data-dismiss="modal">{{ jsonModal.aceptar }}</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api'
import { mapGetters, mapActions } from 'vuex'
export default {

  computed: {
    ...mapGetters({
        jsonModal: 'jsonModal'
    })
  },

  methods: {
    ...mapActions({
      setJsonModal: 'setJsonModal'
    }),
    async aceptoModal () {
        if (this.jsonModal.type) {
            await api.getAnulaCita2(this.jsonModal.id)
            this.setJsonModal({})
        }
    }
  }
}
</script>
